<template>
  <div>
    <modal
        name="login-modal"
        classes="modal"
        :minWidth="200"
        :minHeight="400"
        :maxWidth="480"
        :scrollable="true"
        :reset="true"
        :adaptive="true"
        width="90%"
        height="auto">
      <h2 class="text-center my-4" style="font-size: 24px">GİRİŞ YAP <br> OYNAMAYA BAŞLA</h2>
      <div class="flex flex-col">
        <div class="mx-auto px-4 flex flex-col">
          <div id="fb-root"></div>
          <button href="#" class="btn button-google" v-if="!this.player.isGoogle" @click="onClickGoogle">
            <img class="img-svg" src="@/assets/google.svg" alt="">
            <div class="gText">
              <div class="big">GOOGLE</div>
              ile GİRİŞ YAP
            </div>
            <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div>
          </button>
          <button class="btn button-facebook" v-if="!this.player.isFacebook" @click="onClickFacebook">
            <img class="img-svg" src="@/assets/facebook.svg" alt="">
            <div class="fbText">
              <div class="big">FACEBOOK</div>
              ile GİRİŞ YAP
            </div>
            <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div>
          </button>
          <!--button class="btn button-apple">
              <img class="img-svg" src="@/assets/apple.svg" alt="Login with apple" />
              <div class="fbText">
                  <div class="big">APPLE</div> ile GİRİŞ YAP
              </div>
              <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div>
          </button-->
          <button class="btn btn--primary btn--sm button-login mb-5" v-if="!this.player.isEmail" @click="loginAndRegister">E-Posta ile Giriş Yap
            <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 200 *</div>
          </button>
        </div>
        <p class="text-center mb-4 mt-5 text-xs">* Ödül hesabınıza ilk girişinizde tanımlanacaktır. </p>
      </div>
    </modal>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {}
  },
  mounted() {
    this.clearGameData();
  },
  computed: {
    ...mapState(["player"]),
  },
  methods: {
    ...mapActions(["getPlayerInfo", "googleLogin", "facebookLogin", "clearGameData"]),
    loginWithGoogle() {
      this.$gtag.event('conversion', {
        'send_to': 'AW-568560635/98qUCIaQweEBEPuXjo8C',
        'event_callback': window.location
      });
      this.$gAuth
          .signIn()
          .then(GoogleUser => {
            this.googleLogin({
              "token": GoogleUser.getAuthResponse().id_token,
              "access": GoogleUser.getAuthResponse().access_token
            }).then((response) => {
              if (response.firstLogin) {
                this.$gtag.event('sign_up', {userId: response.id, source: "googleLogin"});
              } else {
                this.$gtag.event('login', {userId: response.id, source: "googleLogin"});
              }
              this.$OneSignal.setExternalUserId(this.player.id)
              this.$router.push({name: "game", params: {id: 'league'}, query: this.$route.query})
            });
          })
          .catch(error => {
            console.log('error', error)
          })
    },
    loginWithFacebook() {
      this.$gtag.event('conversion', {
        'send_to': 'AW-568560635/98qUCIaQweEBEPuXjo8C',
        'event_callback': window.location
      });
      this.$fAuth.logInWithFacebook().then(response => {
        if (response.authResponse.accessToken && response.authResponse.userID) {
          this.facebookLogin({
            token: response.authResponse.accessToken,
            userID: response.authResponse.userID
          }).then((response) => {
            if (response.firstLogin) {
              this.$gtag.event('sign_up', {userId: response.id, source: "facebookLogin"});
            } else {
              this.$gtag.event('login', {userId: response.id, source: "facebookLogin"});
            }
            this.$router.push({name: "game", params: {id: 'league'}, query: this.$route.query})
          });
        }
      });
    },

    onClickGoogle() {
      if (this.player.isGoogle) {
        this.$toast.warning('Hesabınız daha önce bağlandı');
        return false;
      }
      this.$gAuth
          .signIn()
          .then(GoogleUser => {
            this.googleLogin({
              "token": GoogleUser.getAuthResponse().id_token,
              "access": GoogleUser.getAuthResponse().access_token
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(error => {
            console.log('error', error)
          })
    },
    onClickFacebook() {
      if (this.player.isFacebook) {
        this.$toast.warning('Hesabınız daha önce bağlandı');
        return false;
      }
      this.$fAuth.logInWithFacebook().then(response => {
        if (response.authResponse.accessToken && response.authResponse.userID) {
          this.facebookLogin({
            token: response.authResponse.accessToken,
            userID: response.authResponse.userID
          }).then(() => {
            window.location.reload();
          });
        }
      });
    },
    loginAndRegister(){
      this.$modal.hide('login-modal');
      this.$modal.show('login-and-register-modal');
    },
    show() {
      this.$gtag.pageview('/login')
      this.$modal.show('login-modal')
    },
    hide() {
      this.$modal.hide('login-modal');
    },
  }
}
</script>