<template>
  <modal name="reward-modal"
        classes="modal"
        :minWidth="200"
        :minHeight="400"
        :maxWidth="480"
        :scrollable="true"
        :adaptive="true"
        :reset="true"
        min-width="50%"
        height="auto">
    <div class="flex flex-col items-center">
      <h1 class="my-4 font-semibold">ÖZEL TEKLİF !</h1>
      <img class="mb-4" src="@/assets/icon-win.svg" alt="">
      <div>
        <h1 class="my-4 font-semibold text-center">REKLAMI İZLE</h1>
        <template>
          
          <div class="flex flex-row reward-modal" v-for="(reward, i) in rewards" :key="`${reward.icon}-${i}`">
            <h1 class="mb-2 font-bold mr-2">{{ reward.text }}</h1>
            <div class="icon-circle text-center"><img :src="require(`@/assets/${reward.icon}.svg`)" :alt="reward.text"></div>
          </div>
          <h1 class="my-4 font-semibold text-center mb-4"> İLE OYUNA BAŞLA!</h1>
        </template>
      </div>
      <div class="flex items-center mb-4">
        <button class="btn btn--secondary btn--sm" @click="skipAd">ŞİMDİ DEĞİL
        </button>
        <button class="btn btn--primary -ml-2" @click="adsOpen">
          <img src="@/assets/play-icon.svg" class="mr-2" style="margin: auto" alt="">REKLAM
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState(["rewardType"]),
    rewards() {
      var rewards = []
      if(null != this.$store.state.adReward) {
        this._.forEach(this.$store.state.adReward, (v, i) => {
          var item = {
            icon: null,
            text : (v.type == 'v' ? `+${v.value}` : `x${v.multiplier}`)
          }

          switch(i) {
            case 'moves':
              item.icon = 'icon-live'
              break;

            case 'open':
              item.icon = 'icon-clue'
              break;

            case 'tickets':
              item.icon = 'icon-ticket'
              break;

            case 'rewind':
              item.icon = 'icon-relive'
              break;

            case 'score':
              item.icon = 'icon-score'
              break;

            case 'change':
              item.icon = 'icon-change'
              break;

            default:
                item.icon = 'icon-coin'
                break;
          }

          rewards.push(item)
        })
      }

      return rewards
    }
  },
  methods: {
    ...mapActions(["openLoader", "closeLoader", "sendRewardForAd"]),
    adsOpen() {
      this.openLoader()
    },
    showRewardModal() {
      this.$modal.show("reward-modal");
    },
    closeRewardModal() {
      this.$modal.hide("reward-modal");
    },
    skipAd() {
      this.closeRewardModal()
      if(!this.$store.state.inGame) {
        this.$router.push({name: "game", params: {id: 'league'}, query: this.$route.query})
      }
    },
    handleRewardCallback() {
      // TODO : reward callback eklenecek.
      var payload = {
        adPlacementCode: this.$store.state.rewardType,
        networkCode: 'ayt',
        data: {}
      }

      if(this.$store.state.gamePlay) {
        payload.data['gameplayId'] = this.$store.state.gamePlay.id
      }

      this.sendRewardForAd(payload).then(() => {
        if(!this.$store.state.inGame) {
          this.$router.push({name: "game", params: {id: 'league'}, query: this.$route.query})
        }
      }).catch(() => {
        if(!this.$store.state.inGame) {
          this.$router.push({name: "game", params: {id: 'league'}, query: this.$route.query})
        }
      })

      this.closeLoader()
      this.closeRewardModal()
    }
  }
}
</script>

<style>

</style>