<template>
  <div class="h-full flex flex-col">
    <h1 class="font-semibold text-center py-4 flex-shrink-0">YARDIM</h1>
    <div class="flex flex-col items-center px-4 mb-8">
      <div class="text-center" v-if="this.formStatus">
        Bize aşağıdaki bağlantıda ulaşabilirsin! Herhangi bir sorun yaşadığında bizimle iletişime geçmeni bekliyoruz!
      </div>
      <div v-if="this.formStatus" class="scrollable-form">
        <validation-observer ref="form">
          <form class="flex flex-col items-center" @submit.prevent="sendTicket">
            <div v-if="$store.state.authenticated" style="margin-bottom: 20px">Kullanıcı Id : {{ form.playerId }}
            </div>
            <h2 class="text-center">İsim Soyisim</h2>
            <validation-provider class="w-full" name="İsim Soyisim" rules="required" v-slot="{ errors }">
              <input type="text" class="form-special" v-model="form.name" />
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <h2 class="text-center mt-4">E-Posta</h2>
            <validation-provider class="w-full" name="E-Posta" rules="required|email" v-slot="{ errors }">
              <input type="email" class="form-special" v-model="form.email" />
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <h2 class="text-center mt-4">Konu</h2>
            <input type="text" class="form-special" v-model="form.subject" />
            <h2 class="text-center mt-4">Mesaj</h2>
            <validation-provider class="w-full" name="Mesaj" rules="required" v-slot="{ errors }">
              <textarea class="form-special" rows="8" v-model="form.message"></textarea>
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <div class="w-full mt-4 px-6">
              <template>
                <uploader v-model="fileList" :url="remoteUrl" title="Görsel" :headers="addHeader" :autoUpload="false"
                  @on-change="onChange" @on-success="onSuccess" @on-error="onError" @on-delete="onError" :limit="3">
                </uploader>
              </template>
            </div>
            <button class="btn btn--primary mt-5 w-4/5" :class="{ 'disabled': loading }" type="submit"
              :disabled="loading">Gönder</button>
            <div style="margin-top: 20px;">
              <center><a href="https://contact.raviosoft.com/?game=Adam&amp;subject=deletion" class="fw-bold"
                  style="font-weight: bold;" target="_blank" rel="noopener noreferrer">VERİLERİMİ SİL</a></center>
            </div>
          </form>
        </validation-observer>
      </div>
      <div class="flex flex-col" v-else>
        <h1 class="text-success text-center">Mesajınız alınmıştır. En kısa sürede size dönüş yapacağız</h1>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Uploader from "vux-uploader-component";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Feed from '@/mixins/Feed'
export default {
  components: {
    Uploader,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [Feed],
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
        playerId: localStorage.getItem('playerId')
      },
      loading: false,
      remoteUrl: process.env.VUE_APP_API_URL + '/ticket/upload',
      fileList: [],
      addHeader: { 'X-Api-Key': process.env.VUE_APP_API_KEY, 'X-Session-Token': localStorage.sessionToken },
      postData: {
        inboxId: 4545,
        customerEmail: "",
        subject: "",
        message: ""
      },
      formStatus: true,
      content: ""
    }
  },
  methods: {
    ...mapActions(["createNewTicket", "getPlayerInfo", "feedHourlyStat", "uploadTicketImage"]),
    sendTicket() {
      this.$refs.form.validate().then((isValid) => {
        if (!isValid) return;
        this.postData.customerEmail = this.form.email;
        this.postData.subject = "#AdamAsmaca-Web - " + this.form.subject;
        if (this.$store.state.authenticated) {
          this.postData.message += "Kullanıcı Id: " + this.form.playerId;
        }
        this.postData.message += "<br>Tarayıcı Detayları: " + navigator.userAgent;
        this.postData.message += "<br><br>Mesaj: " + this.form.message;
        if (this.fileList.length > 0) {
          var items = ''
          this.fileList.forEach(function (item) {
            items += item.url + '<br><br>'
          })
          this.postData.message += "<br><br>Ekran Görüntüsü: " + items;
        }
        this.createNewTicket(this.postData).then(() => {
          this.feedAllEvent('contact_support', null, 'engagement')
          this.formStatus = false
        });
      })
    },
    onChange(file) {
      let formData = new FormData();
      formData.append('file', file.blob);
      this.loading = true;
      this.uploadTicketImage(formData).then((response) => {
        this.loading = false;
        this.onSuccess(response, file);
      })
    },
    onSuccess(result, fileItem) {
      fileItem.url = result.data.result.set.ObjectURL
    },
    onError(xhr) {
      console.log("error")
      console.log(xhr)
    }
  },
  created() {
    this.feedAllEvent('view_support', null, 'engagement')
  }
}
</script>
<style>
.vux-uploader_input {
  cursor: pointer
}

.scrollable-form {
  max-height: 630px;
  overflow-y: auto;
}
</style>