<template>
  <footer class="flex items-center flex-col justify-between px-8 py-4 mt-4">
    <nav class="flex flex-col sm:flex-row items-center mt-2">
      <router-link class="nav-link" :to="{name: 'help', query: this.$route.query}">YARDIM</router-link>
      <router-link class="nav-link" :to="{name: 'tos', query: this.$route.query}">KULLANIM ŞARTLARI</router-link>
      <router-link class="nav-link" :to="{name: 'privacy', query: this.$route.query}">GİZLİLİK POLİTİKASI VE KVKK</router-link>
    </nav>
    <div class="flex items-center mt-4 z-10	">
      <a href="https://www.raviosoft.com/" target="_blank" class="ravio-link" rel="noopener"></a>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>