import Vue from 'vue'
import History from '@/guide/pages/History.vue'
import Benefits from '@/guide/pages/Benefits.vue'
import Rewards from '@/guide/pages/Rewards.vue'
import Rules from '@/guide/pages/Rules.vue'
import HowToPlay from '@/guide/pages/HowToPlay.vue'
import router from '@/guide/router'
import store from '@/store'
import VueGtag from 'vue-gtag'
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";


const UID = require('uniquebrowserid').default;
Vue.prototype.$uid =  new UID().completeID();

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading)
Vue.component('loading', Loading)

// Vue Modal
import VModal from 'vue-js-modal'

Vue.use(VModal)

// Tailwind
import '@/assets/css/main.css'
import '@/assets/scss/main.scss'

Vue.config.productionTip = false

import googleAuth from "@/config/googleAuth";
import facebookAuth from "@/config/facebookAuth";

const gauthOption = {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    scope: 'profile email',
    prompt: 'select_account'
}
const fAuthOption = {
    appId: process.env.VUE_APP_FACEBOOK_APP_ID, //You will need to change this
    cookie: true, // This is important, it's not enabled by default
    version: "v11.0"
}

Vue.use(googleAuth, gauthOption)
Vue.use(facebookAuth, fAuthOption);

if (window.FBInstant) {
    window.FBInstant.initializeAsync();
}

initVue()

const firebaseConfig = {

    apiKey: "AIzaSyCZ8Douk7AGKYyWB9NeHj3qwjylIrrB6-c",

    authDomain: "adam-asmaca-324011.firebaseapp.com",

    projectId: "adam-asmaca-324011",

    storageBucket: "adam-asmaca-324011.appspot.com",

    messagingSenderId: "858780313433",

    appId: "1:858780313433:web:f4fdb7c12317a5bac50e48",

    measurementId: "G-XMPPZ2H96E"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
Vue.$cookies.config('90d');

let configGtag = {
    config: {id: process.env.VUE_APP_GOOGLE_UA_ID},
    enabled : false,
    includes: [
        {
            id: 'G-VLLLVGWVMV'
        },
        {
            id: 'AW-568560635'
        }
    ],
}

let cookie = document.cookie
if((cookie.indexOf("CookieScriptConsent")>-1 && Vue.$cookies.get("CookieScriptConsent").categories.indexOf('performance')>0) || cookie.indexOf('CookieScriptConsent={"action":"reject"')<0){
    Vue.prototype.$analytics = getAnalytics(app);
    configGtag.enabled = true
}

Vue.use(VueGtag, configGtag, router);

const pathname = window.location.pathname;

let template = null;
switch (pathname) {
  case "/rehber/tarihce":
    template = History;
    break;
  case "/rehber/oyunun-faydalari-nelerdir":
    template = Benefits;
    break;
  case "/rehber/oduller":
    template = Rewards;
    break;  
  case "/rehber/oyun-kurallari":
    template = Rules;
    break;  
  case "/rehber/nasil-oynanir":
    template = HowToPlay;
    break;
  default:
    break;
}

if(!template) {
  window.location.href = "/";
}

function initVue() {
    store.dispatch('init').then(() => {
        new Vue({
            router,
            store,
            render: h => h(template)
        }).$mount('#app')
    })
}