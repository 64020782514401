<template>
  <div 
    ref="scoreBanner" 
    class="bg-green-300 bg-opacity-75 absolute shadow-lg border-2	bottom-8 border-green-500 rounded-full w-11/12 sm:w-3/4 slide-in-blurred-bottom">
    <router-link class="px-4 sm:px-8 py-2 w-full flex justify-center items-center" :to="{name: 'leaderboard'}">
      <template v-if="randomness < 7">
        <span class="text-base sm:text-xl">{{banner.username}}</span><span class="font-bold text-base sm:text-2xl mx-4">{{banner.point}} Puan</span><span class="text-base sm:text-xl">Kazandı!.</span>
      </template>
      <template v-else>
        <span class="text-base sm:text-xl">{{banner.username}} Lige Katıldı.</span>
      </template>
    </router-link>
  </div>
</template>
<script>
export default {
  props: ["banner"],
  data() {
    return {
      showTimeout: null,
      expireTimeout: null
    }
  },
  beforeDestroy() {
    if(this.showTimeout) clearTimeout(this.showTimeout);
    if(this.expireTimeout) clearTimeout(this.expireTimeout);
  },
  computed: {
    randomness() {
      const random = this._.random(0, 10);
      return random;
    }
  },
  mounted() {
    this.showTimeout = setTimeout(() => {
      this.$refs.scoreBanner.classList.add("puff-out-center");
      this.$refs.scoreBanner.classList.remove("slide-in-blurred-bottom");
      this.expireTimeout = setTimeout(() => {
        this.$emit("expired", this.banner);
      }, 1000);
    },2000);
  }
}
</script>