<template>
    <div ref="tooltipWrapper" class="cursor-pointer tooltip" :class="{ giftbox: isTooltip, giftlist: !isTooltip }"
        @click.prevent="showTooltip">
        <div ref="tooltip" :class="[{ 'tooltip__content': isTooltip }, 'isActive', position]"
            :style="[{ 'top': positionTop + 'px' }, { 'right': positionRight + 'px' }]" v-if="show || !isTooltip">
            <div class="flex" :class="{ 'flex-wrap': !isTooltip || isOutOfView }">
                <span class="flex flex-row items-center" :class="{ 'mb-2': !isTooltip }"
                    v-for="(rewardDetail, i) in rewardDetails" :key="`${rewardDetail}-${i}`">
                    <img :src="require(`@/assets/store/${rewardDetail.icon}`)" alt="" class="mx-1"
                        v-if="rewardDetail.icon" />
                    <!-- <img v-if="rewardDetail.hasWarning" src="@/assets/store/exclamation-mark.png" alt="!" class="mx-1"
                        :title="warningTitle" @click="handlePhoneVerification" />
                    <img v-else-if="rewardDetail.hasSkipped" src="@/assets/store/exclamation-mark.png" alt="!!!"
                        class="mx-1" title="Telefon numaranız ekli olmadığı için GB ödülünüz gönderilememiştir." /> -->
                    <span v-html="rewardDetail.label"></span>
                </span>
            </div>
        </div>

    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "reward-box",
    props: {
        instructions: {
            type: Object,
            default: () => { }
        },
        currentPlayer: {
            type: Object,
            default: () => { }
        },
        isTooltip: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: 'center'
        },
        scrollParent: {
            type: [Document, String],
            default: () => { return document }
        },
        displayInternet: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: false,
            isOutOfView: false,
            positionRight: 0,
            positionTop: 0,
        }
    },
    computed: {
        ...mapState(["player"]),
        scrollParentElement: function () {
            return typeof this.scrollParent == "string" ? document.getElementById(this.scrollParent) : this.scrollParent;
        },
        rewardDetails: function () {
            var details = []
            let flattenInstructions = this.flattenInstructions()
            this._.forEach(flattenInstructions, (v, k) => {
                var label = v
                var icon = null
                var hasWarning = false;
                var hasSkipped = false;
                switch (k) {
                    case 'coins':
                        icon = 'coin-001.svg'
                        break;

                    case 'rewind':
                        icon = 'rewind.svg'
                        break;

                    case 'open':
                        icon = 'open.svg'
                        break;

                    case 'change':
                        icon = 'skip.svg'
                        break;

                    case 'hint':
                        icon = 'hint.svg'
                        break;

                    case 'ticket':
                        icon = 'ticket-001.svg'
                        break;

                    case 'immortality':
                        icon = 'ticket-immortal.svg'
                        label = v.label
                        break;

                    case 'extraInternet':
                        console.log('displayInternet:', this.$props.displayInternet)
                        if(this.$props.displayInternet == true) {
                            icon = 'internet.png';
                            if (this.currentPlayer.id === this.player.id && (!this.player.msisdn || !this.player.isVerifiedMsisdn)) {
                                label = v;
                                hasWarning = true;
                            }
                            if (flattenInstructions.skipped === true) {
                                hasSkipped = true;
                            }
                        }
                        break;
                    default:
                        icon = null;
                        break;
                }
                if (icon) details.push({ icon, label, hasWarning, hasSkipped });
            })
            return details
        },
        warningTitle() {
            const isLigRoute = this.$route.path === '/lig';
            const guestMessage = "GB ödüllerin kazanılması için üyelik girişi yapılmalıdır.";
            const phoneMessage = "GB ödüllerin kazanılması için geçerli bir telefon numarası girilmesi gerekmektedir.";

            if (this.currentPlayer.isGuest) {
                return isLigRoute ? guestMessage : `Gb ödülü gönderilememiştir. ${guestMessage}`;
            } else if (this.currentPlayer && (!this.currentPlayer.msisdn || !this.currentPlayer.isVerifiedMsisdn)) {
                return isLigRoute ? phoneMessage : `Gb ödülü gönderilememiştir. ${phoneMessage}`;
            }
            return "";
        },
    },
    methods: {
        ...mapActions(["getPlayerInfo", "createConfirmationRequest"]),
        flattenInstructions() {
            const flattenInstructions = {};
            this._.map(this.instructions, (v, k) => {
                if (k === 'extraInternet') {
                    this._.forEach(v, (pv, pk) => {
                        if (pk === 'title') {
                            flattenInstructions[k] = pv;
                        }
                        if (pk === 'skipped') {
                            flattenInstructions[pk] = pv;
                        }
                    });
                } else if (k === 'powerups') {
                    this._.forEach(v, (pv, pk) => {
                        if(pk === 'immortality' && pv.isActive) {
                            const minReg = /^\+PT(\d+)M$/;
                            const hourReg = /^\+PT(\d+)H$/;
                            const dayReg = /^\+P(\d+)D$/;

                            if(minReg.test(pv.validUntil)) {
                                let duration = pv.validUntil.slice(3, -1)
                                pv.label = duration + ' dk'
                            } else if (hourReg.test(pv.validUntil)) {
                                let duration = pv.validUntil.slice(3, -1)
                                pv.label = duration + ' saat'
                            } else if (dayReg.test(pv.validUntil)) {
                                let duration = pv.validUntil.slice(2, -1)
                                pv.label = duration + ' gün'
                            }

                            flattenInstructions[pk] = pv;
                        } else if(pv > 0) {
                            flattenInstructions[pk] = pv;
                        }
                    });
                } else if (v > 0) {
                    flattenInstructions[k] = v;
                }
            });
            return flattenInstructions;
        },
        showTooltip() {
            if (!this.show) {
                this.show = true;
                this.$nextTick(() => {
                    let elementWidth = this.$refs.tooltip.offsetWidth;
                    const windowWidth = window.innerWidth;
                    var parentRect = this.$refs.tooltipWrapper.getBoundingClientRect();
                    this.positionRight = windowWidth - parentRect.right - 30;
                    this.$nextTick(() => {
                        var rect = this.$refs.tooltip.getBoundingClientRect();
                        this.isOutOfView = false;
                        if (rect.left < 0) {
                            elementWidth = elementWidth + rect.left;
                            this.isOutOfView = true;
                        }
                        if (rect.right > windowWidth) {
                            elementWidth = elementWidth - (rect.right - windowWidth)
                            this.isOutOfView = true;
                        }
                        this.$refs.tooltip.style.width = `${elementWidth}px`;
                        this.$nextTick(() => {
                            let elementHeight = this.$refs.tooltip.offsetHeight;
                            this.positionTop = parentRect.top - elementHeight - 6;
                        });
                        document.addEventListener("mousedown", this.hideTooltip)
                        this.scrollParentElement.addEventListener("scroll", this.hideTooltip)
                    })
                })
            }
        },
        hideTooltip(e) {
            if (e.type != "scroll" && this.$el == e.target.closest(".tooltip")) {
                return;
            }
            this.show = false;
            document.removeEventListener("mousedown", this.hideTooltip)
            this.scrollParentElement.removeEventListener("scroll", this.hideTooltip)
        },
        handlePhoneVerification() {
            this.$emit('phone-verification');
        }
    }
}
</script>