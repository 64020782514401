<template>
<div></div>
</template>

<script>
export default {
  name: "Test",
  created() {
    window.parent.postMessage({
      source: 'iframe',
      message: 'close'
    }, '*');
  }
}
</script>