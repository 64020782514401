<template>
    <div ref="tooltipWrapper" v-if="coupon.gift" class="leaderboard__item-coupon cursor-pointer p-2 mr-2 tooltip tooltip--coupon" :class="{'leaderboard__item-coupon--blue': playerRank > 3}" @click.stop="showTooltip($event)">
      <span class="font-bold">{{coupon.gift}}</span>₺
      <div ref="tooltip" :class="['tooltip__content', 'isActive', position]" :style="[{'top': positionTop + 'px'}, {'right': positionRight + 'px'}]"  v-if="show">
        <div class="coupon-item">
          <div class="flex justify-between items-center w-full">
            <div class="coupon-item__left flex flex-col justify-center items-center w-2/4 px-4">
                <img :src="coupon.image" style="max-width: 100px" alt="">
                <div class="text-base">{{coupon.brand}}</div>
            </div>
            <div class="coupon-item__right flex items-center justify-center relative flex-col w-2/4">
              <h3 class="text-3xl flex items-center mb-2">{{coupon.gift}}<div class="text-xl ml-1 font-normal">₺</div></h3>
            </div>
          </div>                
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "reward-box",
  props: {
    playerRank: {
      type: Number,
      default: null
    },
    coupon: Object,
    position: {
      type: String,
      default: 'right'
    },
    scrollParent: {
      type: [Document, String],
      default: () => { return document }
    }
  },
  data() {
      return {
          show: false,
          isOutOfView: false,
          positionRight: 0,
          positionTop: 0
      }
  },
  computed: {
      scrollParentElement: function() {
        return typeof this.scrollParent == "string" ? document.getElementById(this.scrollParent) : this.scrollParent;
      },
  },
  methods: {
      showTooltip() {
          if(!this.show) {
              this.show = true;
              this.$nextTick(() => {
                const windowWidth =  window.innerWidth;
                var parentRect = this.$refs.tooltipWrapper.getBoundingClientRect();
                this.positionRight = windowWidth - parentRect.right - 30;
                let elementHeight = this.$refs.tooltip.offsetHeight;
                console.log(elementHeight);
                this.positionTop = parentRect.top - elementHeight;

                document.addEventListener("mousedown", this.hideTooltip)
                this.scrollParentElement.addEventListener("scroll", this.hideTooltip)
              })
          }
      },
      hideTooltip(e) {
        if(e.type != "scroll" && this.$el == e.target.closest(".tooltip")) {
            return;
        }
        this.show = false;
        document.removeEventListener("mousedown", this.hideTooltip)
        this.scrollParentElement.removeEventListener("scroll", this.hideTooltip)
      }
  }
}
</script>

<style scoped>

</style>