<template>
    <div>
        <modal name="my-gbs-modal" classes="modal modal--white" :minWidth="200" :minHeight="400" :maxWidth="480"
            :scrollable="true" :adaptive="true" :reset="true" @before-open="onBeforeOpenRewardsModal" width="90%"
            height="550">
            <div class="flex justify-end relative">
                <button @click="$modal.hide('my-gbs-modal')" class="absolute right-0 mr-4 mt-4">
                    <img src="@/assets/times-black.svg" alt="">
                </button>
            </div>
            <div class="flex flex-col items-start px-4 mb-8 h-full">
                <h1 class="my-4 font-semibold">ÖDÜLLERİM</h1>
                <div class="modal-tab">
                    <div class="modal-tab__item" :class="{ 'active': rewardTab == 'internetRewards' }"
                        @click="onClickRewardTab('internetRewards')">
                        SATIN ALDIKLARIM
                    </div>
                    <div class="flex items-center">
                        <button @click="$modal.show('help-modal')" class="absolute right-5 ml-6">
                            <img src="@/assets/icon-help.svg" alt="">
                        </button>
                    </div>
                </div>
                <div class="modal-tab__content mt-6" v-if="rewardTab == 'internetRewards'">
                    <template v-if="rewardsLoading">
                        <loading :active.sync="this.rewardsLoading" :is-full-page="false" :color="`#FFB74F`" :width="32"
                            :height="32"></loading>
                        <div class="text-center">Yükleniyor...</div>
                    </template>
                    <template v-else>
                        <table class="table-auto player-rewards__table w-full">
                            <colgroup>
                                <col style="width: 25%">
                                <col style="width: 25%">
                                <col style="width: 25%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Ödül</th>
                                    <th>Tarih</th>
                                    <th>Durum</th>
                                </tr>
                            </thead>
                            <tbody v-if="internets.length" infinite-scroll-disabled="busy"
                                infinite-scroll-distance="10">
                                <tr v-for="(internet, key) in internets" :key="key">
                                    <td>
                                        {{ internet.internetReward.productName }}
                                    </td>
                                    <td>
                                        {{ moment(internet.internetReward.createdAt).format('DD.MM.YYYY HH:mm') }}
                                    </td>
                                    <td>
                                        <div class="space-y-2">
                                            <div v-if="internet.internetReward.status == 'p'"
                                                :class="[getStatusClass(internet.internetReward.status), 'cursor-pointer', 'hover:bg-gray-200', 'rounded-lg', 'p-2', { 'disabled': internet.internetReward.disabled }]"
                                                @click="onClickSendGB(internet.internetReward)">
                                                <p class="text-sm font-semibold text-center">{{
                                                    getStatusMessage(internet.internetReward.status) }}</p>
                                            </div>
                                            <div v-else
                                                :class="[getStatusClass(internet.internetReward.status), 'cursor-pointer', 'hover:bg-gray-200', 'rounded-lg', 'p-2', { 'disabled': internet.internetReward.disabled }]"
                                                @click="internet.internetReward.status !== 'c' && internet.internetReward.status !== 'e' && refreshStatus(internet.internetReward)">
                                                <p class="text-sm font-semibold text-center">{{
                                                    getStatusMessage(internet.internetReward.status) }}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3">Henüz kazandığınız bir ödül bulunmuyor</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>
            </div>
        </modal>
        <!-- <phone-verification-modal ref="phone-otp-verification-modal" name="phone-otp-verification-modal" type="verify"
            title="Telefon numaranızı doğrulayın" description="Cep telefonunuza gönderilen kodu girin" /> -->
        <!-- <store-login-and-register-modal ref="store-login-and-register-modal" name="store-login-and-register-modal"
            :rType="rType" /> -->
    </div>
</template>

<script>
import Feed from "@/mixins/Feed"
// import PhoneVerificationModal from '@/views/components/PhoneVerificationModal'
// import StoreLoginAndRegisterModal from '@/views/components/StoreLoginAndRegisterModal'
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        // PhoneVerificationModal,
        // StoreLoginAndRegisterModal
    },
    mixins: [Feed],
    data() {
        return {
            rType: 'login',
            rewardTab: "internetRewards",
            rewardsLoading: false,
            loading: false,
            rewards: [],
            coupons: [],
            couponss: [],
            internets: [],
            errorMsg: null,
            isNotificationEnabled: true,
            isSoundEffectsEnabled: true,
            openedCoupons: [],
            isValid: true,
            refreshCount: 0,
            maxRefreshCount: 5,
            refreshTimeout: null,
        }
    },
    computed: {
        ...mapState(["player"]),
    },
    methods: {
        ...mapActions(["getPlayerInternet", "sendPlayerInternet", "createConfirmationRequest", "getRewardPowerups", "getRewardCoupons", "getInternetRewards", "getPackageStatus", "login", "register", "changePassword", "createGame", "checkOpenGame", "getPlayerInfo"]),
        refreshStatus(gbPackage) {
            this.$set(gbPackage, 'disabled', true);
            setTimeout(() => {
                this.$set(gbPackage, 'disabled', false);
            }, 10000);
            this.getPackageStatus(gbPackage).then((response) => {
                const newStatus = response.data.result.set.status;
                const index = this.internets.findIndex(internet => internet.internetReward.id === gbPackage.id);
                if (index !== -1) {
                    this.$set(this.internets[index].internetReward, 'status', newStatus);
                }
            }).catch((error) => {
                this.$toast.error(error.message);
            });
        },
        onBeforeOpenRewardsModal() {
            this.rewardTab = "internetRewards";
            this.getRewards();
        },
        onClickShowCouponHelpModal() {
            this.$modal.show("coupon-help-modal");
        },
        onClickRewardTab(tab) {
            tab === 'internetRewards'
            this.rewardTab = tab;
        },
        getRewards() {
            this.rewardsLoading = true;
            Promise.all([this.getRewardPowerups(), this.getRewardCoupons(), this.getInternetRewards()]).then((dataArray) => {
                this.rewardsLoading = false;
                let newArray = []
                dataArray[0]?.set.forEach((element) => {
                    let date = element.powerups.rewardedAt
                    let rewardDate = new Date(date.substring(6) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2));
                    let startDate = new Date(rewardDate.getFullYear(), 0, 1);
                    let days = Math.floor((rewardDate - startDate) / (24 * 60 * 60 * 1000));
                    let weekNumber = Math.ceil(days / 7);
                    element.powerups.rewardedAt = weekNumber + '. Hafta'
                    newArray.push(element)
                })
                this.rewards = newArray;
                this.coupons = dataArray[1]?.set;
                dataArray[1]?.set.forEach((val) => {
                    if (val.giftCard.displayedAt) {
                        this.openedCoupons.push(val.giftCard.id)
                    }
                })
                this.internets = dataArray[2]?.set;
            });
        },
        getStatusClass(status) {
            const baseClass = "p-2 rounded-lg flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-105 shadow-md";
            const statusClasses = {
                c: "bg-green-100 dark:bg-green-900 border-l-4 border-green-500 dark:border-green-700 text-green-900 dark:text-green-100 hover:bg-green-200 dark:hover:bg-green-800",
                v: "bg-blue-100 dark:bg-blue-900 border-l-4 border-blue-500 dark:border-blue-700 text-blue-900 dark:text-blue-100 hover:bg-blue-200 dark:hover:bg-blue-800",
                w: "bg-blue-100 dark:bg-blue-900 border-l-4 border-blue-500 dark:border-blue-700 text-blue-900 dark:text-blue-100 hover:bg-blue-200 dark:hover:bg-blue-800",
                i: "bg-blue-100 dark:bg-blue-900 border-l-4 border-blue-500 dark:border-blue-700 text-blue-900 dark:text-blue-100 hover:bg-blue-200 dark:hover:bg-blue-800",
                f: "bg-red-100 dark:bg-red-900 border-l-4 border-red-500 dark:border-red-700 text-red-900 dark:text-red-100 hover:bg-red-200 dark:hover:bg-red-800",
                p: "bg-blue-100 dark:bg-blue-900 border-l-4 border-blue-500 dark:border-blue-700 text-blue-900 dark:text-blue-100 hover:bg-blue-200 dark:hover:bg-blue-800",
                e: "bg-gray-100 dark:bg-gray-900 border-l-4 border-gray-500 dark:border-gray-700 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800",
                default: "bg-yellow-100 dark:bg-yellow-900 border-l-4 border-yellow-500 dark:border-yellow-700 text-yellow-900 dark:text-yellow-100 hover:bg-yellow-200 dark:hover:bg-yellow-800 text-center"
            };
            return `${baseClass} ${statusClasses[status] || statusClasses.default}`;
        },
        getStatusMessage(status) {
            const statusMessages = {
                c: "Gönderildi!",
                v: "Gönderiliyor..",
                w: "Gönderiliyor..",
                i: "Gönderiliyor..",
                f: "Gönderim Başarısız!",
                p: "GB Gönder",
                default: "Gönderim Başarısız!"
            };
            return statusMessages[status] || statusMessages.default;
        },
        async onClickSendGB(internet) {
            try {
                await this.sendPlayerInternet({
                    player: this.player.id,
                    internetGB: internet.id
                });
                this.$toast.success('GB gönderiliyor');
                this.refreshStatus(internet);
            } catch (error) {
                this.$toast.error(error.message);
            }
        },
    },
}
</script>

<style scoped>
.msisdn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.msisdn-button {
    margin-left: auto;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}
</style>