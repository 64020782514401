<template>
  <button @click="this.toggleFullScreen" class="btn-circle mx-2 fs-button">
    <img src="@/assets/fullscreen.svg">
  </button>

</template>

<script>
import { mapActions } from 'vuex'
import Feed from '@/mixins/Feed'

export default {
  name: "FullScreenButton",
  mixins: [Feed],
  methods: {
    ...mapActions(["feedHourlyStat"]),
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.feedAllEvent('activate_fullscreen', null, 'interaction', this.$route.path)
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }else{
          this.feedAllEvent('activate_fullscreen', null, 'interaction', this.$route.path)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>