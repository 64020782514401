<template>
  <modal 
      name="error-modal" 
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      @before-open="onBeforeOpen"
      width="100%"
      height="auto">
      <div class="flex justify-end relative">
        <button @click="$modal.hide('error-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <div class="flex container px-8 mt-8 flex-col items-center justify-center">
        <img src="@/assets/icon-error.svg" alt="">
        <p class="text-center my-4">{{content}}</p>
        <div class="flex items-center justify-center mt-4 mb-8">
          <button class="btn btn--secondary btn--sm mx-1" style="min-width: 150px" @click="$modal.hide('error-modal')">KAPAT</button>
          <button v-if="issue == 'support'" class="btn btn--primary btn--sm mx-1" style="min-width: 150px" @click="onClickSupportBtn">DESTEK AL</button>
        </div>
      </div>
  </modal>
</template>

<script>
export default {
  data(){
    return {
      content: "",
      issue: null,
    }
  },
  methods: {
    onBeforeOpen(evt) {
      if(evt.params) {
        this.content = evt.params.content ? evt.params.content : "";
        this.issue = evt.params.issue ? evt.params.issue : null;
      }
    },
    onClickSupportBtn() {
      this.$modal.hide('error-modal');
      this.$router.push({name: "help"});
    }
  }
}
</script>

<style>

</style>