import Vue from 'vue'
import VueRouter from 'vue-router'

import LayoutMain from '@/views/layouts/Main'
import Dashboard from '@/views/pages/Dashboard.vue'
import PrivateArea from '@/views/pages/PrivateArea.vue'
import PaymentConfirm from '@/views/pages/PaymentConfirm';

Vue.use(VueRouter)

const routes = [
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
  {
    path: '/',
    component: LayoutMain,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard
      },
      // {
      //   path: '/online/oyna',
      //   name: 'dashboard-tr',
      //   component: Dashboard
      // },
      {
        path: '/lig',
        name: 'leaderboard',
        component: () => import('@/views/pages/Leaderboard.vue')
      },
      {
        path: '/magaza',
        name: 'store',
        component: () => import('@/views/pages/Store.vue')
      },
      {
        path: '/magaza/:order',
        name: 'store-order',
        component: () => import('@/views/pages/Store.vue'),
        props: true
      },
      {
        path: '/ayarlar',
        name: 'settings',
        component: () => import('@/views/pages/Settings.vue'),
      },
      {
        path: '/yardim',
        name: 'help',
        component: () => import('@/views/pages/Help.vue'),
      },
      {
        path: '/kullanim-sartlari',
        name: 'tos',
        component: () => import('@/views/pages/Privacy.vue'),
      },
      {
        path: '/gizlilik',
        name: 'privacy',
        component: () => import('@/views/pages/PrivacyKvkk.vue'),
      },
      {
        path: '/bakim',
        name: 'maintenance',
        component: () => import('@/views/pages/MaintenanceMode.vue'),
      },
      {
        path: '/payment/finalize/:id',
        name: 'PaymentFinalize',
        component: PaymentConfirm,
        props: route => ({ cuid: route.params.id })
      }
    ]
  },
  {
    path: '/rehber',
    redirect: '/rehber/nasil-oynanir',
    children: [
      {
        path: 'nasil-oynanir'
      }
    ]
  },
  {
    path: '/oyna/:id',
    name: 'game',
    component: () => import('@/views/pages/Game.vue'),
    props: { gameType: '1' }
  },
  {
    path: '/online/oyna',
    redirect: {name: 'game', params: {id: '1'}}
  },
  {
    path: '*',
    component: LayoutMain,
    redirect: '/',
    children: [
      {
        path: '/maintenance',
        name: 'NotFound',
        component: PrivateArea
      }
    ]
  }
]

const router = new VueRouter({
  mode: !process.env.VUE_APP_FB_ENV ? 'history' : '',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})


export default router
