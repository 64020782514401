<template>
  <div>
    <modal 
      name="game-rewards-modal" 
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      width="100%"
      height="auto">
      
      <div class="flex justify-end relative">
        <button @click="$modal.hide('game-rewards-modal')" class="absolute right-0 mr-4 mt-4 z-50">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <!--div class="flex container relative px-8 mt-8 flex-col items-center">
        <h2 class="mb-6 mt-4 absolute">Lig Sonu Hediye Çeki Ödülleri</h2>
        <img src="@/assets/league-banner.png" alt="">
      </div-->
    </modal>
  </div>
</template>

<script>
export default {
  methods: {
    show() {
      this.$modal.show("game-rewards-modal");
    },
    hide() {
      this.$modal.hide("game-rewards-modal");
    }
  }
}
</script>