<template>
  <div>
    <modal name="result-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480" :scrollable="true"
      :adaptive="true" :reset="true" width="90%" @before-open="onBeforeOpen" height="auto">

      <div class="flex flex-col items-center">
        <div class="flex justify-center items-center relative w-full">
          <button @click="$modal.show('help-modal')" class="absolute top-0 left-0 ml-1 mt-1">
            <img src="@/assets/icon-help.svg" alt="">
          </button>
          <h1 class="font-semibold text-center mt-8">{{ title }}</h1>
        </div>
        <div class="result-item flex flex-col items-center" v-if="this.success && this.product !== null">
          <div class="result-item__img" v-if="product.type == 'c'">
            <img :src="product.image" alt="title">
            <span class="text-stroke"><small>x</small>{{ product.rewardInfo.coins }}</span>
          </div>
          <div class="result-item__img" v-if="product.type == 'r'">
            <img :src="product.image" alt="title">
            <span class="text-stroke"><small>x</small>{{ product.rewardInfo.coins }}</span>
          </div>
          <div class="result-item__img" v-if="product.type == 't'">
            <img :src="product.image" alt="title">
            <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.ticket }}</span>
          </div>
          <div class="result-item__img" v-if="product.type == 'o'">
            <img :src="require(`@/assets/store/ticket-immortal.svg`)" alt="">
            <span class="text-stroke">
              <small>+</small>
              {{ getImmortalityLabel(product.rewardInfo.powerups.immortality.validUntil) }}
            </span>
          </div>
          <div class="result-item__img" v-if="product.type == 'p'">
            <template v-if="product.rewardInfo.powerups.rewind > 0">
              <img :src="product.image" :alt="product.title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
            </template>
            <template v-if="product.rewardInfo.powerups.open > 0">
              <img :src="product.image" :alt="product.title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
            </template>
            <template v-if="product.rewardInfo.powerups.change > 0">
              <img :src="product.image" :alt="product.title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.change }}</span>
            </template>
            <template v-if="product.rewardInfo.powerups.hint > 0">
              <img :src="product.image" :alt="product.title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
            </template>
          </div>
          <div class="result-item__img result-item__img--chest" v-if="product.type == 'm'">
            <template v-if="product.rewardInfo.powerups.immortality.isActive">
              <img class="mb-2" :src="product.image" :alt="product.title">
              <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
                <img :src="require(`@/assets/store/rewind.svg`)" class="mr-2" alt="">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
              </div>
              <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
                <img :src="require(`@/assets/store/open.svg`)" class="mr-2" alt="">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
              </div>
              <div class="store-item__chest-item flex items-center">
                <img :src="require(`@/assets/store/ticket-immortal.svg`)" class="mr-2" alt="">
                <span class="text-stroke">
                  <small>+</small>
                  {{ getImmortalityLabel(product.rewardInfo.powerups.immortality.validUntil) }}
                </span>
              </div>
            </template>
            <template v-else>
              <img class="mb-2" :src="product.image" :alt="product.title">
              <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
                <img :src="require(`@/assets/store/rewind.svg`)" class="mr-2" alt="">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
              </div>
              <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
                <img :src="require(`@/assets/store/open.svg`)" class="mr-2" alt="">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
              </div>
            </template>
          </div>
          <template v-if="product.rewardInfo.extraInternet">
            <div class="store-item__img__extra">
              <p class="text-stroke">+</p>
              <p class="heartbeat text-stroke mt-1 mb-1">{{ product.rewardInfo.extraInternet.title }}</p>
            </div>

          </template>
        </div>
        <div class="result-item-error mb-4 mt-4" v-if="this.success == false">
          <img src="@/assets/icon-sad.svg">
          <p>{{ message }}</p>
        </div>

        <div v-if="success && product !== null" class="flex justify-center w-full mb-5">
          <!-- <button v-if="product.type == 't' || (product.type == 'r' && $route.name == 'game')"
            class="btn btn--primary w-4/5" @click.prevent="onClickCreateGame">ŞİMDİ OYNA</button> -->
          <div v-if="product.type == 't' || (product.type == 'r' && $route.name == 'game')"
            class="flex justify-center w-full mb-5">
            <button class="btn btn--secondary mr-2" style="padding: 8px 25px;" @click.prevent="onClickCreateGame">ŞİMDİ OYNA</button>
            <button class="btn btn--primary mr-2" style="padding: 8px 25px;" @click.prevent="onClickSendGB">GB GÖNDER</button>
          </div>
          <div v-else-if="product.type == 'c'" class="flex justify-center w-full mb-5">
            <button class="btn btn--secondary mr-2" style="padding: 8px 25px;" @click.prevent="onClickCreateGame">ŞİMDİ OYNA</button>
            <button class="btn btn--primary mr-2" style="padding: 8px 25px;" @click.prevent="onClickSendGB">GB GÖNDER</button>
          </div>
          <button v-else :class="['btn self-center mb-5', 'btn--primary']" style="padding: 8px 25px;"
            @click="onCloseModal">{{ button }}</button>
        </div>
      </div>
    </modal>
    <my-gbs-modal ref="my-gbs-modal" name="my-gbs-modal"></my-gbs-modal>

    <store-login-and-register-modal ref="store-login-and-register-modal" name="store-login-and-register-modal" :rType="rType" @user-logged-in="showMyGbs" />

    <phone-verification-modal ref="phone-otp-verification-modal" name="phone-otp-verification-modal" type="verify"
      title="Telefon numaranızı doğrulayın" description="Cep telefonunuza gönderilen kodu girin"
      @phone-verified="showMyGbs" />

  </div>
</template>

<script>

import MyGbsModal from '@/views/components/MyGbsModal.vue'
import PhoneVerificationModal from '@/views/components/PhoneVerificationModal.vue'
import StoreLoginAndRegisterModal from '@/views/components/StoreLoginAndRegisterModal.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    MyGbsModal,
    PhoneVerificationModal,
    StoreLoginAndRegisterModal
  },
  data() {
    return {
      success: true,
      message: null,
      product: null,
      rType: 'purchase',
      transactionId: null,
      internetGB: null,
    }
  },
  computed: {
    ...mapState(["player"]),
    title() {
      if (!this.product) return '';
      if (!this.success) return 'BAŞARISIZ ÖDEME!';
      return this.product.type === 'c'
        ? 'SATIN ALINDI!'
        : 'SATIN ALINDI! ÖDÜLÜN GÖNDERİLİYOR!';
    },
    text() {
      if (this.message) return this._.clone(this.message);
      return this.success ? 'İşleminiz başarıyla gerçekleştirildi.' : 'İşlem gerçekleştirilirken hata alındı. Lütfen bir süre bekleyip tekrar deneyin.'
    },
    button() {
      return this.success ? 'TAMAM' : 'KAPAT'
    }
  },
  methods: {
    ...mapActions(["createGame", "updateAdTimer", "getPlayerInternet", "sendPlayerInternet", "createConfirmationRequest"]),
    show() {
      this.$modal.show("result-modal");
    },
    hide() {
      this.$modal.hide("result-modal");
    },
    onBeforeOpen(event) {
      const payload = event.params;
      if (payload) {
        if (payload.product.sku == 'promo.T.00001' || payload.product.sku == 'ticket.X.0010') {
          this.updateAdTimer()
        }
        this.success = payload.success
        this.message = payload.message
        this.product = payload.product
        this.transactionId = payload.transactionId
      }
    },
    onClickCreateGame() {
      if (this.$route.name == "game") {
        this.$modal.hide("result-modal");
        this.$modal.hide("store-modal");
        // window.location.reload();
      }
      else {
        this.createGame({ gameType: '1' }).then(() => {
          this.$modal.hide("result-modal");
          this.$modal.hide("store-modal");
          this.$router.push({ name: "game", params: { id: 'league' }, query: this.$route.query })
        })
      }
    },
    onCloseModal() {
      this.$emit('close')
      this.$modal.hide('result-modal')
    },
    showMyGbs() {
      this.sendGB();
      // this.$modal.hide("result-modal");
      // this.$modal.show('my-gbs-modal');
    },
    onClickSendGB() {
      if (this.player.isGuest && this.product.rewardInfo.extraInternet) {
        this.rType = 'purchase';
        this.$modal.hide('result-modal');
        this.$modal.show("store-login-and-register-modal");
      } else if (!this.player.isGuest && !this.player.msisdn && this.product.rewardInfo.extraInternet) {
        this.$modal.hide('result-modal');
        this.$modal.show('phone-addition-modal', 'add');
      } else if (!this.player.isGuest && this.player.msisdn && !this.player.isVerifiedMsisdn && this.product.rewardInfo.extraInternet) {
        this.sendMsisdnVerificationCode();
        this.$modal.hide('result-modal');
        this.$modal.show('phone-otp-verification-modal');
      } else {
        this.$modal.hide('result-modal');
        this.sendGB();
      }
    },
    sendGB() {
      this.getPlayerInternet({ transactionId: this.transactionId, player: this.player.id }).then((response) => {
        this.internetGbId = response.data.result.set.id;
        this.sendPlayerInternet({ player: this.player.id, internetGB: this.internetGbId }).then(() => {
          this.$toast.success('GB gönderiliyor.');
          this.$modal.hide('result-modal');
          this.$modal.show('my-gbs-modal');
        })
      })
    },
    sendMsisdnVerificationCode() {
      this.createConfirmationRequest({ id: this.player.id, type: 'verify' }).then((response) => {
        if (response.data.code === "200.0401") {
          this.loading = false;
          this.$toast.error(response.data.message);
          return;
        }
        this.loading = false;
        this.$toast.success('Kod Cep telefonunuza gönderildi');
      }).catch(() => {
        this.loading = false;
        this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
      })
    },
    getImmortalityLabel(validUntil) {
      const minReg = /^\+PT(\d+)M$/;
      const hourReg = /^\+PT(\d+)H$/;
      const dayReg = /^\+P(\d+)D$/;
      let label = ''

      if(minReg.test(validUntil)) {
          let duration = validUntil.slice(3, -1)
          label = duration + ' Dk.'
      } else if (hourReg.test(validUntil)) {
          let duration = validUntil.slice(3, -1)
          label = duration + ' Saat'
      } else if (dayReg.test(validUntil)) {
          let duration = validUntil.slice(2, -1)
          label = duration + ' Gün'
      }

      return label
    }
  }
}
</script>