<template>
  <div id="app">
    <div class="layout-main vld-parent">
      <the-header></the-header>
      <div class="main mx-auto">
        <div class="flex container px-8 mt-8 flex-col items-center justify-center">
          <h1 class="mt-2 mb-2">REHBER</h1>
          <h2 class="mb-6">Adam Asmaca Oyunu Tarihçesi</h2>
          <p class="mb-4">Oyunun bir benzerinin ilk olarak yazar Alice Gomme tarafından 1894 yılında yayınlanan "İngiltere, İskoçya ve İrlanda'nın Geleneksel oyunları" adlı kitabında "Kuşlar, Canavarlar ve Balıklar" adı ile bahse alındığı görülmüştür. Kökeninin Viktorya Dönemi'ne ait olduğu düşünülmektedir.</p>
          <p class="mb-4">Başka bir görüşe göre ise oyun 17. ve 18. yüzyılda Avrupa'da idam mahkumlarına "Rite of Words and Life" yani "kelimelerin ve hayatın ritüeli" adı altında sunulan ve oyun ile birebir benzeyen bir ritüelden doğmuştur. Bu ritüele göre mahkum, cellatın seçeceği bir kelimeyi doğru bilirse, cezası iptal olacak ve bir daha aynı suçtan yargılanmayacaktır. Mahkumun doğru cevabı bilmesi için 5 hakkı bulunur.</p>
          <p class="mb-4">"Darağacı", "asma oyunu", "cellat" vb. isimlerle de anılan oyununun, günümüzde bilinen klasik darağaçlı görsele ait versiyonunun 1902 yılında A.B.D.'nin "The Philadelphia Inquirer" adlı gazetesinde haber olduğu bilinmektedir. Oyunun bilinen ilk elektronik versiyonu ise yine A.B.D.'li "Texas Instruments" firmasının çocuklar için ürettiği elde taşınabilir bir bilgisayar olan "Speak & Spell" modelinde "Mystery Word" yani "Gizemli Kelime" adlı öğretici oyundur. Türkçe olarak en kapsamlı Adam Asmaca oyunu ise Raviosoft'un yarattığı ve 2022 yılı Ocak ayında piyasaya sürdüğü ücretsiz Adam Asmaca oyunudur.</p>
        </div>
      </div>
      <network-status-modal ref="networkStatusModal"></network-status-modal>
    </div>
  </div>
</template>
<script>

import NetworkStatusModal from "@/views/components/NetworkStatusModal";
import TheHeader from "@/guide/components/TheHeader";
import {mapActions} from "vuex";
import UID from "uniquebrowserid";
import Feed from "@/mixins/Feed"

export default {
  components: {NetworkStatusModal, TheHeader},
  mixins: [Feed],
  data(){
    return {
      networkStatus : navigator.onLine
    }
  },
  created() {
    let queries = this.$route.query;
    if(queries.gclid){
      this.$cookies.set('origin-campaign-id', queries.gclid);
    }
    if(queries.fbclid){
      this.$cookies.set('origin-campaign-id', queries.fbclid);
    }
    if(queries.ref_id){
      this.$cookies.set('fb-share-return-id', queries.ref_id);
    }

    if(this.$store.state.authenticated)
    {
      this.$gtag.config({userId: localStorage.getItem('playerId'), user_id: localStorage.getItem('playerId')});
      this.$cookies.set('aa_userId', localStorage.getItem('playerId'));
    }else{
      if(!localStorage.getItem('aa_deviceId')){
        let deviceIdTmp = new UID().completeID();
        localStorage.setItem('aa_deviceId',deviceIdTmp);
      }
      
      var deviceId = localStorage.getItem('aa_deviceId');

      let cookie = this.$cookies.get("origin-campaign-id")
      let payload = {deviceId: deviceId}
      if(cookie){ payload.originCampaignId = cookie }
      this.guestLogin(payload)
    }

    window.ononline = (event) => {
      console.log(event,"You are now connected to the network.");
    };

    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);

    if (typeof (window.FBInstant) != 'undefined') {
      window.FBInstant.setLoadingProgress(100);
      window.FBInstant.startGameAsync()
          .then(function () {
          })
    }
  },
  methods: {
    ...mapActions(["guestLogin", "getPlayerNotifications"]),
    updateConnectionStatus() {
     this.networkStatus = navigator.onLine
    }
  },
  watch:{
    networkStatus: function (val) {
      if(val === false) {
        this.$refs.networkStatusModal.show();
      }else if(val === true){
        this.$refs.networkStatusModal.hide()
      }
    }
  }
}
</script>
