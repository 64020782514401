<template>
  <div>
    <modal 
      name="coupon-help-modal" 
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      width="100%"
      height="auto">
      
      <div class="flex justify-end relative">
        <button @click="$modal.hide('coupon-help-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <div class="flex container px-8 mt-8 flex-col items-center justify-center">
        <h2 class="mb-6 mt-4">Hediye Internet Kullanım Kılavuzu</h2>
        <ul class="list-disc ml-6 mb-8">
          <li>It will expire after 7 days, so make sure to use it before then.</li>
        </ul>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  methods: {
    show() {
      this.$modal.show("coupon-help-modal");
    },
    hide() {
      this.$modal.hide("coupon-help-modal");
    }
  }
}
</script>