<template>
  <div>
    <modal 
      name="game-help-modal" 
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      width="100%"
      height="auto">
      
      <div class="flex justify-end relative">
        <button @click="$modal.hide('game-help-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <div class="flex container px-8 mt-8 flex-col items-center justify-center">
      <h2 class="mb-6 mt-4">Oyun İçerisindeki İkonlar Ve Anlamları</h2>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle icon-circle--sml text-center mr-4 flex-none"><img src="@/assets/icon-live.svg" alt=""></div>
        <p><b>Can:</b> Kelimeyi doğru tahmin etmek için kalan hamle sayısı</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle icon-circle--sml text-center mr-4 flex-none"><img src="@/assets/icon-coin.svg" alt=""></div>
        <p><b>Koin:</b> Oyun içi mağazada kullanılabilecek para birimi.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle icon-circle--sml text-center mr-4 flex-none"><img src="@/assets/icon-ticket.svg" alt=""></div>
        <p><b>Bilet:</b> Kullanıcının oyuna giriş bileti.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div style="width:42px" class="flex-none btn btn--xs joker-button btn--primary self-center flex-none mr-5">
          <img style="height: 100%" src="@/assets/icon-relive.svg" alt="">
        </div>
        <p><b>Geri Sarma:</b> En son seçilen yanlış harfi geri alır.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div style="width:42px" class="btn btn--xs btn--primary joker-button self-center flex-none mr-5">
          <img style="height: 100%" src="@/assets/block-button.svg" alt="">
        </div>
        <p><b>Harf Açma:</b> Kelime içerisinden bir harf açar.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div style="width:42px" class="btn btn--xs btn--primary joker-button self-center flex-none mr-5">
          <img style="height: 100%" src="@/assets/skip-button.svg" alt="">
        </div>
        <p><b>Pas:</b> Kelimeyi pas geçmenizi sağlar.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div style="width:42px" class="btn btn--xs btn--primary joker-button self-center flex-none mr-5">
          <img style="height: 100%" src="@/assets/clue-button.svg" alt="">
        </div>
        <p><b>İpucu:</b> Kelime ile ilgili ipucu gösterir.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="icon-circle icon-circle--sml flex-none text-center mr-4"><img src="@/assets/icon-score.svg" alt=""></div>
        <p><b>Skor:</b> Oyun içinde harf açtıkça toplanan puan.</p>
      </div>
      <div class="flex items-center justify-start w-full mb-4">
        <div class="btn-circle btn-circle--sml mr-4">
          <img src="@/assets/fullscreen.svg">
        </div>
        <p>Oyunu tam ekran modunda açar.</p>
      </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  methods: {
    show() {
      this.$modal.show("game-help-modal");
    },
    hide() {
      this.$modal.hide("game-help-modal");
    }
  }
}
</script>