<template>
  <div class="game vld-parent">
    <full-screen-button></full-screen-button>
    <v-dialog :width="600" />
    <loading :active.sync="this.$store.state.isLoading" :is-full-page="true" :backgroundColor="`#000000`"
      :color="`#FFB74F`" :blur="`7px`" :opacity="0.5" :width="64" :height="64"></loading>
    <div class="game-wrapper flex flex-col h-full">
      <div class="game-header container max-w-sm px-4 py-2 mx-auto" :class="{ 'game-header_show': loaded }">
        <div class="flex relative mb-2">
          <div class="flex flex-row align-center">
            <router-link class="mr-2 flex" :to="{ name: 'dashboard', query: this.$route.query }"><img
                src="@/assets/icon-back.svg" alt=""></router-link>
            <div class="flex align-center">
              <div class="game-meter mr-2">
                <div class="game-meter__icon" ref="scoreIcon">
                  <img src="@/assets/icon-score.svg" alt="">
                </div>
                <div class="game-meter__value">
                  {{ score }}
                </div>
              </div>
              <div class="game-meter">
                <div class="game-meter__icon" ref="liveIcon">
                  <img src="@/assets/icon-live.svg" alt="">
                </div>
                <div class="game-meter__value">
                  {{ lives }} <span v-if="this.extraLives > 0"> + {{ extraLives }}</span>
                </div>
              </div>
            </div>
          </div>
          <currencies class="ml-auto" @btnAction="feedForBtnAction"></currencies>
        </div>
        <!--div class="player-settings">
          <div class="player-settings__row mt-6 mb-0">
            <label>Ses :</label>
            <VueToggles
                :value="isSoundEffectsEnabled"
                checked-text="AÇIK"
                unchecked-text="KAPALI"
                height="36"
                width="100"
                checkedBg="#65D29F"
                uncheckedBg="#C4C4C4"
                checkedColor="#000000"
                uncheckedColor="#000000"
                @click="onChangeEffectSetting" />
          </div>
        </div-->
      </div>
      <div class="game-container flex flex-col align-center">
        <div class="flex w-full justify-center bg-black mb-1">
          <img src="@/assets/gb-banner-transparent.png" class="my-2" style="max-width: 340px;" alt="">
        </div>
        <div id="hangman" ref="hangman" class="w-full game-image relative">
          <div class="game-board w-full h-full">
            <div class="game-board__svg h-full flex-grow">
              <svg id="gameSVG" ref="gameSVG" width="100%" height="100%" viewBox="0 0 933 290" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 270C41.3727 270.642 79.4114 279.889 117.7 273.358C146.04 268.524 171.411 277.091 199.389 279.34C232.723 282.018 265.755 273.465 299.047 275.772C334.12 278.201 367.172 279.525 402.256 276.086C428.769 273.488 455.985 272.369 482.483 275.247C505.103 277.703 529.672 279.444 552.473 279.444C596.874 279.444 642.121 271.175 686.498 276.611C710.859 279.596 736.341 277.556 760.875 277.556C786.293 277.556 811.636 280.964 836.924 283.222C867.845 285.983 899.222 283.135 930 287"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M270.897 270.487C272.03 272.855 272.958 276.56 274.759 278.608C275.921 279.931 275.808 279.695 276.621 278.114C278.365 274.72 279.385 270.057 282.069 267.309"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M225.241 278.75C225.241 274.349 225.923 267.433 224 263.496" stroke="black" stroke-width="5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M590.414 270.487C591.14 271.499 594.141 277.944 595.069 275.854C595.904 273.973 596.33 271.975 597.276 270.099C599.042 266.594 601.763 263.768 603.448 260.318"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M594.138 275.572C593.461 272.524 592.084 269.75 591.655 266.674" stroke="black"
                  stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M596.621 273.665C595.97 270.407 594.916 267.319 594.138 264.131" stroke="black"
                  stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M300.036 279C301.547 273.117 302.433 266.112 302.433 259.91C302.433 255.961 303.562 251.949 303.631 247.931C303.736 241.769 303.997 236.041 303.664 229.899C303.049 218.563 301.105 207.288 300.635 195.952C300.434 191.082 299.437 186.383 299.437 181.476C299.437 175.381 299.437 169.286 299.437 163.19C299.437 151.728 300.036 140.422 300.036 128.947C300.036 122.859 300.586 116.855 300.635 110.788C300.686 104.599 300.036 98.4979 300.036 92.3757C300.036 81.125 298.695 69.9311 298.838 58.6402C298.923 51.9284 300.635 45.549 300.635 39"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M301.828 83C308.078 72.3917 313.459 59.6807 321.76 51.308C325.393 47.6434 329.238 43.897 331.828 39"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M277.828 36C288.983 36 299.812 38.6847 310.916 38.7787C317.599 38.8353 324.195 38.2413 330.81 38.084C340.535 37.8528 349.539 37.3816 359.27 38.6243C367.811 39.7149 376.49 38.084 385.036 38.084C397.417 38.084 409.707 37.3893 422.062 37.3893C426.797 37.3893 431.501 38.7787 436.223 38.7787C440.253 38.7787 444.282 38.7787 448.312 38.7787C453.447 38.7787 458.581 38.7787 463.716 38.7787C472.487 38.7787 481.173 38.084 489.828 38.084"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 7"
                  d="M443.104 41.8135C445.344 49.127 444.345 57.5428 444.345 65.1887C444.345 69.4546 444.966 73.656 444.966 78.0415"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 6"
                  d="M456.138 83.7617C451.61 81.9525 447.597 79.6004 442.828 78.7124C438.607 77.9266 432.453 80.1069 429.759 83.4792C426.619 87.4083 425.104 92.901 425.104 97.8857C425.104 102.257 425.024 108.922 428.207 112.363C436.714 121.557 453.489 125.674 461.414 113.952C464.688 109.11 464.365 102.135 464.207 96.4733C464.144 94.2289 463.281 90.8259 462.207 88.8463C460.51 85.7191 456.831 85.0884 454.276 83.1261"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 5"
                  d="M441.241 122.532C437.542 126.412 433.698 130.05 430.069 133.972C428.573 135.589 425.983 137.348 425.104 139.375C424.31 141.203 416.196 150.068 417.035 150.497"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 4"
                  d="M443.104 124.439C449.399 131.075 455.684 138.27 461.414 145.413C463.36 147.839 466.494 153.675 469.793 153.675"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 3"
                  d="M441.862 122.532C441.862 138.502 443.724 154.373 443.724 170.236C443.724 177.642 444.965 184.887 444.965 192.446"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 2"
                  d="M444.966 192.446C443.341 195.069 441.624 198.007 439.655 200.39C437.306 203.233 435.207 206.764 432.552 209.288C429.964 211.749 426.028 215.34 424.483 218.504"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path v-if="lives < 1"
                  d="M446.207 194.988C449.993 200.105 453.673 205.476 456.897 210.877C457.899 212.556 459.488 213.827 460.483 215.609C461.07 216.661 463.586 219.37 463.586 217.869"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M577.828 276C580.433 271.458 583.01 267.337 584.294 262.302C585.515 257.518 586.953 252.766 588.928 248.258C593.22 238.459 593.286 224.905 593.428 214.358C593.668 196.367 595.828 178.482 595.828 160.535C595.828 155.023 595.828 149.512 595.828 144"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M621.808 134.586C621.808 150.84 621.39 166.524 623.678 182.543C624.766 190.159 625.848 193.334 625.848 200.573C625.848 206.97 625.848 213.367 625.848 219.764C625.848 231.436 630.432 241.689 634.265 252.421C636.003 257.289 637.968 263.781 637.968 268.918C637.968 270.721 638.459 277.917 639.988 278.682"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M590.422 167.707C581.733 167.707 571.504 169.357 563.226 166.428C555.963 163.859 547.459 161.315 541.379 156.474C534.189 150.751 532.864 143.099 533.564 134.39C533.839 130.969 536.349 126.809 537.627 123.537C538.823 120.479 541.314 116.605 543.879 114.517C544.917 113.672 552.841 106.689 552.111 106.084C550.048 104.373 547.647 103.37 545.72 101.453C543.571 99.3142 541.607 96.9318 539.746 94.5406C535.527 89.1178 528.191 79.5448 531.792 72.1795C534.567 66.5033 539.003 60.2372 545.095 57.9057C545.598 57.713 547.774 57.6586 547.179 57.56C544.679 57.1454 542.909 54.9732 541.691 52.9288C537.087 45.1971 542.226 37.3443 548.568 32.987C554.865 28.6612 559.599 28.3558 566.942 28.3558C568.272 28.3558 570.482 28.7718 571.735 28.2175C573.488 27.4425 574.907 25.2159 576.181 23.8974C579.051 20.9279 581.923 17.9485 584.83 15.0151C589.781 10.0179 602.54 -2.25547 610.15 5.51078C613.111 8.53281 615.508 12.1025 617.791 15.6372C618.063 16.0575 620.516 19.9919 621.056 19.9919C624.504 19.9919 629.76 16.5373 633.074 15.4299C638.25 13.7003 643.547 12.1252 649.051 12.1811C658.735 12.2794 664.746 21.6863 667.182 30.0838C668.247 33.7548 668.571 37.342 668.571 41.1434C668.571 43.4235 669.907 41.505 671.35 40.7978C679.871 36.6199 690.728 37.5209 694.69 47.0189C698.422 55.9634 697.563 69.0788 691.842 76.8798C691.244 77.6955 689.143 79.0488 690.106 79.3682C692.577 80.188 694.784 79.8738 697.191 81.511C707.86 88.7678 707.662 107.54 701.915 117.593C700.176 120.635 697.973 122.539 695.454 124.782C694.929 125.249 693.579 125.272 693.579 126.026C693.579 135.248 696.144 143.302 691.703 152.223C687.516 160.636 679.383 159.619 671.35 159.619C668.871 159.619 666.155 159.315 663.708 159.758C659.292 160.557 656.207 154.391 653.288 151.532C649.848 148.161 651.022 151.732 651.691 154.159C652.49 157.058 652.941 159.728 652.941 162.73C652.941 167.367 653.528 171.683 649.815 175.172C644.335 180.322 636.474 173.2 631.685 170.817"
                  stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M547.229 69.9771C547.229 78.3614 547.202 85.4239 554.834 90.5121" stroke="black"
                  stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M588.298 31.9494C591.489 26.8441 595.669 23.5833 601.608 23.5833C606.13 23.5833 609.594 28.4447 609.594 32.71"
                  stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M552.552 134.624C552.552 142.218 556.481 145.173 562.82 148.694C565.699 150.294 568.993 150.804 571.947 152.117C573.564 152.836 578.516 153.087 579.172 154.399"
                  stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div class="container mx-auto max-w-sm relative">
          <!-- <div class="ml-1 flex flex-col left-0 absolute -top-8 items-center" v-if="gameType == '1'">
            <button class="btn btn--xs btn--primary self-center" @click="onClickPowerup(powerup.relive)"><img
                src="@/assets/icon-relive.svg" alt=""></button>
            <div class="flex items-center font-semibold text-lg">
              <span>{{ rewindCount > 0 ? `x ${rewindCount}` : powerup.relive.value }}</span> <img class="ml-1 w-4"
                                                                                                  src="@/assets/icon-coin.svg"
                                                                                                  alt=""
                                                                                                  v-if="rewindCount < 1">
            </div>
          </div> -->
          <div class="hangman-word__category flex justify-center items-center">
            <!--div class="absolute cursor-pointer" style="left: 10px" @click="onClickGameRewardsButton">
              <img src="@/assets/icon-giftbox-button.svg" alt="">
            </div-->
            <h4 class="m-0">{{ payload ? payload.category : "" }}</h4>
            <div class="game-help-icon cursor-pointer" @click="onClickGameHelpButton">
              <img src="@/assets/icon-game-help.svg" alt="">
            </div>
          </div>
          <!-- <div class="mr-1 flex right-0 absolute -top-8 flex-col items-center" v-if="gameType == '1'">
            <button class="btn btn--xs btn--primary self-center" @click="onClickPowerup(powerup.clue)"><img
                src="@/assets/icon-clue.svg" alt=""></button>
            <div class="flex items-center font-semibold text-lg">
              <span>{{ clueCount > 0 ? `x ${clueCount}` : powerup.clue.value }}</span> <img class="ml-1 w-4"
                                                                                            src="@/assets/icon-coin.svg"
                                                                                            alt=""
                                                                                            v-if="clueCount < 1">
            </div>
          </div> -->
        </div>
        <transition name="fade" mode="out-in">-
          <div class="game-canvas" v-if="word" :key="'game-container'">
            <div class="overflow-hidden relative flex flex-col items-center">
              <div class="hangman-word flex justify-center items-center" v-if="word">
                <template v-if="wordSpaceCount == 0 || (wordArray.length < 12 && wordSpaceCount > 0)">
                  <div class="mx-2" v-for="word, i in guess" :key="`word-${i}`">
                    <span class="hangman-word__char mt-3"
                      :class="{ 'is-space': character === ' ', 'is-free-char': character != '', 'small': wordArray.length > 12 }"
                      v-for="(character, index) in word" :key="`char-${index}`">
                      {{ character }}
                    </span>
                  </div>
                </template>
                <template v-if="wordArray.length >= 12 && wordSpaceCount > 0">
                  <div class="flex flex-col items-center">
                    <div v-for="word, i in guess" :key="`word-${i}`">
                      <span class="hangman-word__char mt-3"
                        :class="{ 'is-space': character === ' ', 'is-free-char': character != '', 'small': wordSpaceCount > 2 }"
                        v-for="(character, index) in word" :key="`char-${index}`">
                        {{ character }}
                      </span>
                    </div>
                  </div>
                </template>
              </div>
              <div class="flex justify-center alphabet mt-5">
                <div class="grid grid-cols-6 justify-center">
                  <button role="button" class="alphabet__char mx-1"
                    :class="[{ 'is-right': _.indexOf(rightCharacters, character) >= 0 }, { 'is-wrong': _.indexOf(wrongCharacters, character) >= 0 }]"
                    :disabled="_.indexOf(rightCharacters, character) >= 0 || _.indexOf(wrongCharacters, character) >= 0"
                    v-for="(character, index) in alphabet" :key="`alphabet-char-${index}`"
                    @click="onClickCharacter(character)">
                    {{ character }}
                    <span class="alphabet__char__wrong-img"><img src="@/assets/times.svg" alt=""></span>
                    <span class="alphabet__char__right-img"><img src="@/assets/circle.svg" alt=""></span>
                  </button>
                </div>
              </div>
              <div ref="hintsPanel" class="hints-panel" :class="{ 'hints-panel--active': showHints }">
                <div class="absolute right-0 mr-4 mt-4 z-50 cursor-pointer" style="height: 24px"
                  @click="showHints = false">
                  <img class="max-h-full" src="@/assets/times-black.svg" alt="">
                </div>
                <div class="hints-panel__tabs">
                  <div class="hints-panel__tabs-item cursor-pointer"
                    :class="{ active: index == activeHint && showHints }" :style="{ 'right': (index * 50) + 8 + 'px' }"
                    @click="onClickHintTabItem(index)" v-for="(item, index) in usedHints" :key="`hints-tab-${index}`">
                    <img src="@/assets/icon-clue-sml.svg" class="mr-1" alt="">
                    {{ index + 1 }}
                  </div>
                </div>
                <div class="hints-panel__content">
                  {{ hints[activeHint] }}
                </div>
              </div>
            </div>
            <div class="powerups">
              <div class="btn-game-store cursor-pointer z-50" @click="$modal.show('store-modal')">
                <img src="@/assets/icon-store.svg" alt="">
                MAĞAZA
              </div>
              <div class="power-button-group relative">
                <div class="powerup-button" ref="relivePowerup" @click="onClickPowerup(powerup.relive)">
                  <h6>GERİ AL</h6>
                  <button class="btn btn--xs joker-button">
                    <img src="@/assets/relive-button.svg" alt="">
                  </button>
                  <div class="powerup-button__price">
                    <span>{{ rewindCount > 0 ? `x ${rewindCount}` : powerup.relive.value }}</span>
                    <img src="@/assets/coin-small.svg" v-if="rewindCount < 1">
                  </div>
                </div>
                <div class="powerup-button" ref="openPowerup" @click="onClickPowerup(powerup.clue)">
                  <h6>HARF AÇ</h6>
                  <button class="btn btn--xs joker-button">
                    <img src="@/assets/block-button.svg" alt="">
                  </button>
                  <div class="powerup-button__price">
                    <span>{{ clueCount > 0 ? `x ${clueCount}` : powerup.clue.value }}</span>
                    <img src="@/assets/coin-small.svg" v-if="clueCount < 1">
                  </div>
                </div>
                <div class="powerup-button" @click="onClickPowerup(powerup.change)">
                  <h6>PAS</h6>
                  <button class="btn btn--xs joker-button">
                    <img src="@/assets/skip-button.svg" alt="">
                  </button>
                  <div class="powerup-button__price">
                    <span>{{ changeCount > 0 ? `x ${changeCount}` : powerup.change.value }}</span>
                    <img src="@/assets/coin-small.svg" v-if="changeCount < 1">
                  </div>
                </div>
                <div class="powerup-button" ref="hintPowerup"
                  :class="{ 'disabled': hints.length == 0 || usedHints.length == hints.length }"
                  @click="onClickPowerup(powerup.hint)">
                  <h6>İPUCU</h6>
                  <button class="btn btn--xs joker-button">
                    <img src="@/assets/clue-button.svg" alt="">
                  </button>
                  <div class="powerup-button__price">
                    <span>{{ hintCount > 0 ? `x ${hintCount}` : powerup.hint.value }}</span>
                    <img src="@/assets/coin-small.svg" v-if="hintCount < 1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="game-canvas" :key="'game-loading'">
            <div class="flex mt-12 justify-center items-center">
              <h1>Kelime Yükleniyor</h1>
              <div class="ml-8">
                <div class="dot-typing"></div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <modal name="game-reward-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480" :scrollable="true"
      :adaptive="true" :clickToClose="false" :reset="true" min-width="50%" height="auto">
      <div class="flex flex-col items-center">
        <h1 class="my-4 font-semibold">ÖZEL TEKLİF !</h1>
        <img class="mb-4" src="@/assets/icon-win.svg" alt="">
        <div>
          <h1 class="my-4 font-semibold">REKLAMI İZLE</h1>
          <div class="flex flex-row reward-modal">
            <h1 class="mb-2 font-bold mr-2">x2</h1>
            <div class="icon-circle text-center"><img src="@/assets/icon-score.svg" alt=""></div>
          </div>
          <h1 class="my-4 font-semibold text-center mb-4"> KAZAN!</h1>
        </div>
        <div class="flex items-center mb-4">
          <button class="btn btn--secondary btn--sm" @click="closeRewardModal">ŞİMDİ DEĞİL
          </button>
          <button class="btn btn--primary -ml-2" @click="onClickRewardAdButton">
            <img src="@/assets/play-icon.svg" class="mr-2" style="margin: auto" alt="">REKLAM
          </button>
        </div>
      </div>
    </modal>
    <modal name="game-result-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480" :scrollable="true"
      :adaptive="true" :clickToClose="false" :reset="true" width="90%" @before-open="onOpenGameResultModal"
      @closed="onCloseGameResultModal" height="auto">
      <router-link :to="{ name: 'dashboard' }" class="absolute right-0 mr-6 mt-6 z-50">
        <img src="@/assets/times-black.svg" alt="">
      </router-link>
      <div class="flex flex-col items-center h-full">
        <template v-if="gameStatus == 'w'">
          <div class="flex justify-center items-center relative w-full">
            <button
              v-if="this.player.isGuest && !this.player.isFacebook && !this.player.isGoogle && !this.player.isEmail"
              @click="$modal.show('login-modal')" class="absolute left-0 ml-6">
              <img src="@/assets/icon-login.svg" alt="">
            </button>
            <h1 class="my-4 font-semibold">TEBRİKLER !</h1>
          </div>
          <div class="relative text-center flex justify-center" v-if="coinsEarned && coinsEarned > 0">
            <div class="absolute floating flex items-center">
              <span class="flex items-center">
                <img src="@/assets/coin.svg" alt="">
                <div class="text-4xl font-semibold ml-1">{{ coinsEarned }}</div>
              </span>
              <span class="text-4xl font-semibold mx-2"> + </span>
              <span class="flex items-center">
                <img src="@/assets/score.svg" alt="">
                <div class="text-4xl font-semibold ml-1">{{ coinsEarned }}</div>
              </span>
            </div>

            <img class="mb-4" src="@/assets/icon-win-coin.svg" alt="">
          </div>
          <img v-else class="mb-4" src="@/assets/icon-win.svg" alt="">

        </template>
        <template v-else-if="gameStatus == 'l'">
          <div class="flex justify-center items-center relative w-full">
            <button @click="$modal.show('help-modal')" class="absolute left-0 ml-6">
              <img src="@/assets/icon-help.svg" alt="">
            </button>
            <h1 class="my-4 font-semibold">ÜZGÜNÜZ !</h1>
          </div>
          <img class="mb-4" src="@/assets/icon-sad.svg" alt="">
        </template>
        <div class="modal-container">
          <div class="hangman-word flex justify-center items-center mb-4" v-if="word">
            <template v-if="wordSpaceCount == 0 || (wordArray.length < 12 && wordSpaceCount > 0)">
              <div class="mx-2" v-for="word, i in wordSplit" :key="`word-${i}`">
                <span class="hangman-word__char text-success mt-3"
                  :class="{ 'is-space': character === ' ', 'text-success': gameStatus == 'w', 'text-danger': gameStatus == 'l', 'small': wordArray.length > 12 }"
                  v-for="(character, index) in word" :key="`char-${index}`">
                  {{ character }}
                </span>
              </div>
            </template>
            <template v-if="wordArray.length >= 12 && wordSpaceCount > 0">
              <div class="flex flex-col items-center">
                <div v-for="word, i in wordSplit" :key="`word-${i}`">
                  <span class="hangman-word__char text-success mt-3"
                    :class="{ 'is-space': character === ' ', 'text-success': gameStatus == 'w', 'text-danger': gameStatus == 'l', 'small': wordSpaceCount > 2 }"
                    v-for="(character, index) in word" :key="`char-${index}`">
                    {{ character }}
                  </span>
                </div>
              </div>
            </template>
          </div>
          <table class="mb-4" v-if="gameType == '1'" style="max-width: 170px">
            <tr>
              <td class="text-right leading-none p-0 pr-4">
                SKOR :
              </td>
              <td class="font-semibold p-0 text-lg text-center">
                {{ score }}
              </td>
            </tr>
            <tr>
              <td class="text-right leading-none p-0 pr-4">
                <img class="inline mr-2" src="@/assets/plus.png" alt=""> BONUS :
              </td>
              <td class="font-semibold p-0 text-lg text-center">
                {{ bonus }}
              </td>
            </tr>
            <tr v-if="this.rewardStatus">
              <td class="text-right leading-none p-0 pr-4">
                <img class="inline mr-2" src="@/assets/plus.png" alt="">REKLAM:
              </td>
              <td class="font-semibold p-0 text-lg text-center">
                {{ totalScore }}
              </td>
            </tr>
            <tr>
              <td colspan="2"><img src="@/assets/underline-gray.svg" alt=""></td>
            </tr>
            <tr>
              <td class="flex justify-end">
                <div class="icon-circle mr-6 text-center">
                  <img src="@/assets/icon-score.svg" alt="">
                </div>
              </td>
              <td class="text-center">
                <span class="text-4xl font-semibold"> {{ this.rewardStatus ? totalScore * 2 : totalScore }}</span>
              </td>
            </tr>
          </table>
          <button class="btn-facebook-share relative mb-1" v-if="gameStatus == 'w' && fbShare == 0"
            @click="onClickFacebookShare">
            <img class="img-svg" src="@/assets/facebook.svg" alt="">
            Paylaş
          </button>
          <div class="flex items-center mb-4 " v-if="gameStatus == 'w' && fbShare == 0 && fbRewardExceeded == 0"><b
              class="ml-2 flex items-center">50 <img class="ml-1 mr-2" src="@/assets/coin-small.svg"> KAZAN!</b></div>
          <div class="leaderboard leaderboard--single mx-auto px-4 mb-4" v-if="gameType == '1'">
            <div class="leaderboard__item" :class="{ 'active': standing.player.id == player.id }"
              v-for="(standing, i) in standingArray" :key="i">
              <div class="leaderboard__item-number">
                {{ standing.currentRank ? standing.currentRank : '-' }}.
              </div>
              <div class="leaderboard__item-info">
                <div class="leaderboard__item-avatar">
                  <img :src="standing.player.avatarUrl" alt="">
                </div>
                <div class="leaderboard__item-name">{{ standing.player.alias }}</div>
              </div>
              <div class="leaderboard__item-points">{{
                standing.score ? standing.score : '-'
              }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mb-2 mt-4 w-full">
          <router-link v-if="this.specialOfferToggle" :to="{ name: 'dashboard', query: this.$route.query }"
            @click.native="feedHomeEvent" class="btn btn--secondary btn--sm">ANA
            MENÜ
          </router-link>
          <button v-else class="w-2/5" @click="beginTransaction(specialOffer)">
            <div class="store-item store-item--bold justify-self-center"
              :class="[{ 'heartbeat': !this.specialOfferSold }]">
              <h2>SANA ÖZEL</h2>
              <div class="store-item__img">
                <img :src="specialOffer.image" alt="title">
                <span class="text-stroke" v-if="specialOffer.rewardInfo.coins > 0"><small>x</small>{{
                  specialOffer.rewardInfo.coins }}</span>
                <template v-if="specialOffer.rewardInfo.extraInternet">
                  <div class="store-item__img__extra">
                    <p class="text-stroke">+</p>
                    <p class="heartbeat text-stroke mt-1 mb-1">{{ specialOffer.rewardInfo.extraInternet.title }}</p>
                  </div>
                </template>
                <span class="text-stroke" v-else-if="specialOffer.rewardInfo.powerups.open > 0"><small>x</small>{{
                  specialOffer.rewardInfo.powerups.open }}</span>
                <span class="text-stroke" v-else-if="specialOffer.rewardInfo.powerups.rewind > 0"><small>x</small>{{
                  specialOffer.rewardInfo.powerups.rewind }}</span>
                <span class="text-stroke" v-else-if="specialOffer.rewardInfo.powerups.ticket > 0"><small>x</small>{{
                  specialOffer.rewardInfo.powerups.ticket }}</span>
                <span class="text-stroke" v-else-if="specialOffer.rewardInfo.powerups.hint > 0"><small>x</small>{{
                  specialOffer.rewardInfo.powerups.hint }}</span>
                <span class="text-stroke" v-else-if="specialOffer.rewardInfo.powerups.change > 0"><small>x</small>{{
                  specialOffer.rewardInfo.powerups.change }}</span>
                <span class="text-stroke" v-else-if="specialOffer.rewardInfo.powerups.immortality.isActive === true"
                  style="font-size: 16px"><small>+</small> {{ getImmortalityLabel(specialOffer.rewardInfo.powerups.immortality.validUntil) }}
                  Saat</span>
              </div>
              <div class="store-item__value">
                <span class="store-item__value-number">{{ specialOffer.price }}</span>
                <span class="store-item__value-currency">
                  <template v-if="specialOffer.currency == 'TRY'">TL</template>
                  <template v-else>
                    <img src="@/assets/coin-small.svg" alt="">
                  </template>
                </span>
              </div>
            </div>
          </button>
          <button class="btn btn--primary -ml-2" @click="onClickPlayAgain">TEKRAR <br> OYNA</button>
        </div>
        <div class="flex justify-center mt-0 mb-2 w-full flex-col">
          <div id='div-gpt-ad-1650625168685-0' class="flex justify-center"
            style='width: 100%; min-width: 300px; min-height: 50px;'></div>
        </div>
      </div>
    </modal>
    <game-help-modal ref="gameHelpModal"></game-help-modal>
    <game-rewards-modal ref="gameRewardsModal"></game-rewards-modal>
    <promo-modal ref="promoModal" @rewardGranted="onHandleRewardGranted"></promo-modal>
    <modal name="game-exit-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480" :scrollable="true"
      :adaptive="true" :reset="true" width="100%" height="auto">
      <div class="flex container px-8 mt-8 flex-col items-center justify-center">
        <div class="">
          <h2 class="mb-6 mt-4 text-center">Oyun zor geldiyse güçleri denemeyi unutma!</h2>
          <div class="power-button-group relative">
            <div class="powerup-button" ref="relivePowerup" @click="onClickGameExitPowerup(powerup.relive)">
              <h6>GERİ AL</h6>
              <button class="btn btn--xs joker-button">
                <img src="@/assets/relive-button.svg" alt="">
              </button>
              <div class="powerup-button__price">
                <span>{{ rewindCount > 0 ? `x ${rewindCount}` : powerup.relive.value }}</span>
                <img src="@/assets/coin-small.svg" v-if="rewindCount < 1">
              </div>
            </div>
            <div class="powerup-button" ref="openPowerup" @click="onClickGameExitPowerup(powerup.clue)">
              <h6>HARF AÇ</h6>
              <button class="btn btn--xs joker-button">
                <img src="@/assets/block-button.svg" alt="">
              </button>
              <div class="powerup-button__price">
                <span>{{ clueCount > 0 ? `x ${clueCount}` : powerup.clue.value }}</span>
                <img src="@/assets/coin-small.svg" v-if="clueCount < 1">
              </div>
            </div>
            <div class="powerup-button" @click="onClickGameExitPowerup(powerup.change)">
              <h6>PAS</h6>
              <button class="btn btn--xs joker-button">
                <img src="@/assets/skip-button.svg" alt="">
              </button>
              <div class="powerup-button__price">
                <span>{{ changeCount > 0 ? `x ${changeCount}` : powerup.change.value }}</span>
                <img src="@/assets/coin-small.svg" v-if="changeCount < 1">
              </div>
            </div>
            <div class="powerup-button" ref="hintPowerup"
              :class="{ 'disabled': hints.length == 0 || usedHints.length == hints.length }"
              @click="onClickGameExitPowerup(powerup.hint)">
              <h6>İPUCU</h6>
              <button class="btn btn--xs joker-button">
                <img src="@/assets/clue-button.svg" alt="">
              </button>
              <div class="powerup-button__price">
                <span>{{ hintCount > 0 ? `x ${hintCount}` : powerup.hint.value }}</span>
                <img src="@/assets/coin-small.svg" v-if="hintCount < 1">
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center mt-8">
          <h2 class="mb-4">Yine de çıkmak istediğine emin misin?</h2>
          <div class="row w-full flex justify-center mb-6">
            <div class="btn btn--primary btn--sm mx-2 w-1/2" @click="onClickGameExitButton(1)">
              Evet
            </div>
            <div class="btn btn--secondary btn--sm mx-2 w-1/2" @click="onClickGameExitButton(0)">
              Hayır
            </div>
          </div>

        </div>
      </div>
    </modal>

    <login-and-register-modal-two ref="login-and-register-modal-two" name="login-and-register-modal-two" :rType="rType"
      @user-logged-in="showPaymentMethodModal" />

    <phone-verification-two-modal ref="phone-verification-two-modal" name="phone-verification-two-modal" type="verify"
      title="Telefon numaranızı doğrulayın" description="Cep telefonunuza gönderilen kodu girin" @phone-verified="showPaymentMethodModal" />

  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState
} from "vuex";
import Currencies from "@/views/components/Currencies"
import FullScreenButton from "@/views/components/FullScreenButton"
import Feed from "@/mixins/Feed"
import GameHelpModal from '@/views/components/GameHelpModal'
import GameRewardsModal from '@/views/components/GameRewardsModal'
import PromoModal from '@/views/components/PromoModal'
import LoginAndRegisterModalTwo from '@/views/components/LoginAndRegisterModalTwo'
import PhoneVerificationTwoModal from "../components/PhoneVerificationTwoModal.vue";

export default {
  components: {
    Currencies,
    FullScreenButton,
    GameHelpModal,
    GameRewardsModal,
    PromoModal,
    LoginAndRegisterModalTwo,
    PhoneVerificationTwoModal,
  },
  mixins: [Feed],
  props: {
    category: {
      type: String,
      default: ""
    },
    gameType: String
  },
  computed: {
    ...mapState(["player", "gamePlay", "appSettings"]),
    word() {
      return this.payload ? this.payload.word : null;
    },
    wordArray() {
      return this.word ? this.word.split("") : [];
    },
    largeWordArray() {
      var arr = this._.cloneDeep(this.wordArray)
      this._.forEach(this.wordArray, (character) => {
        if (this.flattenSpecialChars.includes(character)) {
          let equalKey = this._.findKey(this.specialChars, (chrArray) => {
            return chrArray.includes(character)
          })

          if (equalKey) {
            arr.push(equalKey)
          }
        }
      })

      return arr
    },
    rewindCount() {
      return this.player && this.player.powerups ? this.player.powerups.rewind : 0;
    },
    clueCount() {
      return this.player && this.player.powerups ? this.player.powerups.open : 0;
    },
    changeCount() {
      return this.player && this.player.powerups ? this.player.powerups.change : 0;
    },
    hintCount() {
      return this.player && this.player.powerups ? this.player.powerups.hint : 0;
    },
    totalScore() {
      let winBonus = 0
      if (this.gameStatus == 'w') { winBonus = this.coinsEarned }
      return this.score + this.bonus + winBonus
    },
    bonus() {
      return this.lives
    },
    flattenSpecialChars() {
      return this._.flattenDeep(this._.values(this.specialChars))
    },
    totalLives() {
      return this.lives + this.extraLives
    },
    calculateHintsPanelHeight() {
      return this.$refs.hintsPanel ? this.$refs.hintsPanel.offsetHeight() : 0;
    },
  },
  watch: {
    "$store.state.gamePlay.extraMoves": {
      handler: function (val) {
        if (val) {
          this.lives = this.appSettings.maxMove
          this.extraLives = val
          this.decreaseLives(this.wrongCharacters.length)
        }
      },
      deep: true
    },
    totalLives(value) {
      if (value <= 0) {
        this.endGame()
      }
    },
    wordArray() {
      let alphabet = this._.concat(this.alphabet, this.flattenSpecialChars)

      this.guess = this._.map(this.wordArray, (character) => {
        if (this._.indexOf(alphabet, character) < 0) {
          return character;
        }
        return character != " " ? "" : character;
      });

      let count = this._.filter(this.wordArray, function (o) {
        if (o == ' ') return o
      });

      this.wordSpaceCount = count.length;

      let words = []
      let copyArray = this._.cloneDeep(this.wordArray);
      let i = 0;
      while (copyArray.length > 0) {
        if (copyArray[i] == ' ') {
          words.push(copyArray.slice(0, i));
          copyArray.splice(0, i + 1);
          i = -1;
        } else if (i == copyArray.length - 1) {
          words.push(copyArray.slice(0, i + 1));
          copyArray.splice(0, i + 1);
          i = 0;
        }
        i++;
      }
      this.wordSplit = [...words]

      this.guess = this._.map(this.wordSplit, (word) => {
        return this._.map(word, (character) => {
          if (this._.indexOf(alphabet, character) < 0) {
            return character;
          }

          if (this.flattenSpecialChars.includes(character)) {
            let equalChar = this._.findKey(this.specialChars, function (arr) {
              return arr.includes(character)
            })

            if (this.rightCharacters.includes(equalChar)) {
              return character
            }
          }

          return this.rightCharacters.includes(character) ? character : (character != " " ? "" : character);
        })
      })

    },
    showHints() {
      if (this.showHints) {
        document.addEventListener("mousedown", this.closeHintsPanel);
      }
    }
  },
  data() {
    return {
      correctWordSound: null,
      wrongWordSound: null,
      looseGameSound: null,
      winGameSound: null,
      powerupUsageSound: null,
      dominantSound: 0,
      adSlot: null,
      doubleScore: false,
      loaded: false,
      gameId: null,
      lockPage: true,
      payload: null,
      moveCount: 0,
      specialOfferToggle: true,
      specialOffer: [],
      specialOfferSold: false,
      finalizeSend: false,
      moves: [],
      coinsEarned: 0,
      usedPowerupsLetters: [],
      usedPowerups: [],
      lives: 7,
      extraLives: 0,
      score: 0,
      winPoint: 0,
      rightAnswerPoint: 1,
      guess: null,
      gameStatus: null,
      fbShare: 0,
      fbRewardExceeded: 1,
      gameBoardHeight: 0,
      hints: [],
      usedHints: [],
      activeHint: 0,
      showHints: false,
      rightCharacters: [],
      rewardGranted: false,
      wrongCharacters: [],
      wordSpaceCount: 0,
      wordSplit: [],
      windowHeight: 0,
      standingArray: [],
      openPowerupInterval: null,
      adsVisible: false,
      status: null,
      powerup: {
        clue: {
          id: "c",
          value: 120
        },
        relive: {
          id: "r",
          value: 75
        },
        change: {
          id: "p",
          value: 60
        },
        hint: {
          id: "h",
          value: 100
        }
      },
      alphabet: ["A", "B", "C", "Ç", "D", "E", "F", "G", "Ğ", "H", "I", "İ", "J", "K", "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "Ş", "T", "U", "Ü", "V", "W", "X", "Y", "Z"],
      specialChars: {
        "A": ["Â", "À", "Á", "Ã", "Ä", "Å"],
        "İ": ["Î", "Ì", "Í", "Ï"],
        "U": ["Û", "Ù", "Ú"],
        "E": ["È", "É", "Ê", "Ë"],
        "Y": ["Ý"]
      },
      rewardStatus: false,
      gameCount: 0,
      isSoundEffectsEnabled: true,
      gameExitRouteAction: null,
      rType: '',
    }
  },
  created() {
    this.correctWordSound = new Audio(require("@/assets/sound/correct.wav"))
    this.wrongWordSound = new Audio(require("@/assets/sound/wrong.wav"))
    this.looseGameSound = new Audio(require("@/assets/sound/lose.wav"))
    this.winGameSound = new Audio(require("@/assets/sound/win.wav"))
    this.powerupUsageSound = new Audio(require("@/assets/sound/powerupUse.wav"))

    this.setInGame(true)
    this.lives = this.appSettings.maxMove;
    this.checkOpenGame().then((result) => {
      if (!result.data.result.set.value) {
        this.reCreateGame()
      } else {
        this.startGame();
      }
    });

    this.correctWordSound.preload = "auto"
    this.wrongWordSound.preload = "auto"
    this.looseGameSound.preload = "auto"
    this.winGameSound.preload = "auto"
    this.powerupUsageSound.preload = "auto"

    this.$gtag.event('conversion', { 'send_to': 'AW-568560635/qJnkCN61roMDEPuXjo8C' });
    // window.addEventListener('focus', this.refreshSystem);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },
  mounted() {
    this.loaded = true;
    // eslint-disable-next-line no-unused-vars
    let gameBoardSvg = document.getElementsByClassName("game-board__svg");
    this.windowHeight = window.innerHeight;
    this.$nextTick(() => {
      this.gameBoardHeight = gameBoardSvg.offsetHeight;
    });

    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight;
      this.$nextTick(() => {
        this.gameBoardHeight = gameBoardSvg.offsetHeight;
      })
    });

    this.setInactivityInterval();
    this.showingMsisdnNotification();
  },
  beforeRouteLeave(to, from, next) {
    if (this.lockPage && this.gamePlay) {
      this.gameExitRouteAction = next;
      this.$modal.show("game-exit-modal");
      this.feedAllEvent('game_exit_shown', null, 'gameplay', null, null);
      // this.$modal.show('dialog', {
      //   class: "modal px-6 py-10",
      //   title: "Oyundan ayrılmak istediğinize emin misiniz ?",
      //   buttons: [{
      //       title: "Evet",
      //       class: "btn btn--primary btn--sm mr-4",
      //       handler: () => {
      //         var gamePlay = this.$store.state.gamePlay
      //         gamePlay.moveCount = this.moveCount
      //         gamePlay.extraMoves = this.extraLives
      //         gamePlay.move = this.moves
      //         gamePlay.status = 'a'
      //         next();
      //         return;
      //       }
      //     },
      //     {
      //       title: "Hayır",
      //       class: "btn btn--secondary btn--sm",
      //       handler: () => {
      //         this.$modal.hide('dialog')
      //       }
      //     }
      //   ]
      // });
    } else {
      next();
    }
  },
  beforeDestroy() {
    this.setInGame(false)
    this.$modal.hideAll();
    this.emptyLeaderboard();
    window.removeEventListener("beforeunload", this.preventNav);
    window.googletag.destroySlots([this.adSlot]);
    clearInterval(this.openPowerupInterval);
  },
  methods: {
    ...mapActions(["createGame", "getWord", "usePowerup", "finalizeGame", "updateGame", "getPlayerStandings", "createConfirmationRequest",
      "rewardCheck", "clearGameData", "feedHourlyStat", "checkOpenGame", "setRewardType", "retrieveAdPlacement", "setInGame", "sendRewardForAd",
      "closeLoader", "sendRewardForSocialMedia", "emptyLeaderboard", "purchaseStart", "enableSoundEffects", "disableSoundEffects", "getPlayerInfo",
      "updatePlayerLastPan"
    ]),
    ...mapGetters(["checkTicket", "hasGamePlay", "hasImmortality"]),
    onClickHintTabItem(index) {
      if (!this.showHints) this.showHints = true;
      this.activeHint = index;
    },
    showingMsisdnNotification() {
      if (this.player.isGuest) {
        this.handleGuestPlayer();
      } else {
        this.handleLoggedInPlayer();
      }
    },
    handleGuestPlayer() {
      // const showToast = () => {
      //   // this.$toast.info("Ligin ilk 10'unda yer alarak GB kazanmak için giriş yapın veya kayıt olun ve telefon numaranızı girin!", { position: 'top-center' });
      //   this.$toast.info("Ligde ilk 10'a girerek GB kazanmak için telefon numaranı gir!", { position: 'top-center' });
      // };
      // if (!this.player.lastPanAt) {
      //   this.updatePlayerLastPan({ id: this.player.id }).then(showToast());
      // } else {
      //   const lastPanAt = new Date(this.player.lastPanAt);
      //   lastPanAt.setHours(lastPanAt.getHours() - 3);
      //   const now = new Date();
      //   const diffDays = (now - lastPanAt) / (1000 * 60 * 60 * 24);
      //   if (diffDays > 1) {
      //     this.updatePlayerLastPan({ id: this.player.id }).then(showToast());
      //   }
      // }
    },
    handleLoggedInPlayer() {
      // const showToast = (message) => {
      //   this.$toast.info(message, { position: 'top-center' });
      // };
      const checkMsisdnStatus = () => {
        // if (!this.player.msisdn) {
        //   // showToast("Ligde ilk 10'a girerek GB kazanmak için telefon numaranı gir!");
        //   showToast("Ligde ilk 10'a girerek GB kazanmak için telefon numaranı gir!");
        // } else if (!this.player.isVerifiedMsisdn) {
        //   // showToast("Ligde ilk 10'da yer alarak GB'yi kazanmak için telefon numaranızı doğrulayın!");
        //   showToast("Ligde ilk 10'a girerek GB kazanmak için telefon numaranı gir!");
        // }
      };
      if (!this.player.lastPanAt) {
        this.updatePlayerLastPan({ id: this.player.id }).then(checkMsisdnStatus());
      } else {
        const lastPanAt = new Date(this.player.lastPanAt);
        lastPanAt.setHours(lastPanAt.getHours() - 3);
        const now = new Date();
        const diffDays = (now - lastPanAt) / (1000 * 60 * 60 * 24);
        if (diffDays > 1) {
          this.updatePlayerLastPan({ id: this.player.id }).then(checkMsisdnStatus());
        }
      }
    },
    onClickFacebookShare() {
      window.FB.ui({
        method: 'feed',
        link: 'https://www.adam-asmaca.com/?ref_id=${this.gameId}',
        picture: 'https://www.adam-asmaca.com/fb-banner.png'
      }, (response) => {
        if (!response.error_code) {
          this.sendRewardForSocialMedia({ action: "share-score", network: "facebook", refId: this.gameId }).then(() => {
            this.getPlayerInfo({ id: this.player.id })
            this.fbShare = 1
            this.feedAllEvent('share_social', null, 'facebook', 'facebook', this.gameId)
          })
        }
      });
    },
    animateOpenPowerup() {
      this.$refs.openPowerup?.classList.add("shake-top");
      setTimeout(() => {
        this.$refs.openPowerup?.classList.remove("shake-top");
      }, 1500)
    },
    animateHintPowerup() {
      this.$refs.hintPowerup?.classList.add("shake-top");
      setTimeout(() => {
        this.$refs.hintPowerup?.classList.remove("shake-top");
      }, 1500)
    },
    animateRelivePowerup() {
      this.$refs.relivePowerup?.classList.add("shake-top");
      setTimeout(() => {
        this.$refs.relivePowerup?.classList.remove("shake-top");
      }, 1500)
    },
    animateLive() {
      this.$refs.liveIcon?.classList.add("jello-horizontal");
      setTimeout(() => {
        this.$refs.liveIcon?.classList.remove("jello-horizontal");
      }, 1500)
    },
    setInactivityInterval() {
      if (this.openPowerupInterval) {
        clearInterval(this.openPowerupInterval);
        this.openPowerupInterval = null;
      }
      this.openPowerupInterval = setInterval(this.animateOpenPowerup, 5000);
    },
    refreshSystem() {
      this.$modal.hide("game-result-modal")
      if (this.$store.state.gamePlay && this.$store.state.gamePlay.status !== "f") {
        this.$store.state.gamePlay = null;
        this.startGame();
      }
    },
    preventNav(event) {
      if (!this.lockPage) return;
      event.preventDefault()
      event.returnValue = ""
    },
    onClickCharacter(character, powerup = null) {
      this.feedStat('click_letter', null, character)
      this.setInactivityInterval();

      if (this.moves.length == 0) {
        this.feedAllEvent('click_first_letter', null, 'gameplay', character, character)
      }

      this.moveCount++
      this.moves.push(powerup ? powerup : character)

      let isHit = this._.indexOf(this.wordArray, character) >= 0 || this._.intersection(this.wordArray, this.specialChars[character]).length > 0;

      if (isHit) {
        if (this.isSoundEffectsEnabled && this.dominantSound == 0) {
          this.correctWordSound.pause();
          this.correctWordSound.currentTime = 0;
          this.correctWordSound.play()
        }
        this.dominantSound = 0
        this.rightCharacters.push(character);

        for (let i = 0; i < this.wordSplit.length; i++) {
          for (let x = 0; x < this.wordSplit[i].length; x++) {
            if (this.wordSplit[i][x] == character) this.guess[i].splice(x, 1, character);
            if (this.specialChars[character]) {
              for (let a = 0; a < this.specialChars[character].length; a++) {
                if ((this.wordSplit[i][x] == this.specialChars[character][a])) this.guess[i].splice(x, 1, this.specialChars[character][a]);
              }
            }
          }
        }

        this.score += this.rightAnswerPoint;
        if (this._.isEqual(this.wordSplit, this.guess)) {
          this.gameStatus = 'w';
          this.endGame();
        } else {
          this.feedGame()
        }
      } else {
        this.animateLive();
        this.wrongCharacters.push(character);
        if (this.isSoundEffectsEnabled) {
          this.wrongWordSound.pause();
          this.wrongWordSound.currentTime = 0;
          this.wrongWordSound.play()
        }

        if (this.totalLives == 4) {
          this.animateHintPowerup();
        }

        if (this.totalLives == 2) {
          this.animateRelivePowerup();
        }

        if (this.totalLives == 1) {
          this.gameStatus = 'l';
          this.endGame();
        } else {
          this.feedGame()
        }

        this.decreaseALive()
      }
    },
    decreaseLives(count) {
      if (count == 1) {
        this.decreaseALive()
      } else {
        if (this.extraLives > 0) {
          let decreaseCountForExtraLives = this.extraLives > count ? count : this.extraLives

          count -= decreaseCountForExtraLives
          this.extraLives -= decreaseCountForExtraLives
        }

        if (count > 0) {
          this.lives -= count
        }
      }
    },
    decreaseALive() {
      if (this.extraLives > 0) {
        this.extraLives--
      } else {
        this.lives--
      }
    },
    increaseALive() {
      if (this.appSettings.maxMove > this.lives) {
        this.lives++
      } else {
        this.extraMoves++
      }
    },
    onClickPlayAgain() {
      this.rewardStatus = false;
      this.$modal.hide("game-result-modal");
      this.checkOpenGame().then(() => {
        this.reCreateGame();
      });
    },
    onClickGameExitPowerup(powerup) {
      let powerupKey;
      switch (powerup.id) {
        case "c":
          powerupKey = "open";
          break;
        case "r":
          powerupKey = "rewind";
          break;
        case "p":
          powerupKey = "change";
          break;
        case "h":
          powerupKey = "hint";
          break;
      }
      this.feedAllEvent('game_exit_powerup_usage', null, 'gameplay', powerupKey, powerupKey);
      this.$modal.hide("game-exit-modal");
      this.onClickPowerup(powerup);
    },
    onClickGameExitButton(action) {
      if (action == 1) {
        var gamePlay = this.$store.state.gamePlay
        gamePlay.moveCount = this.moveCount
        gamePlay.extraMoves = this.extraLives
        gamePlay.move = this.moves
        gamePlay.status = 'a'
        this.feedAllEvent('game_exit_click', null, 'gameplay', "yes", "yes");
        this.$modal.hide("game-exit-modal");
        this.gameExitRouteAction();
      }
      else if (action == 0) {
        this.$modal.hide("game-exit-modal");
        this.feedAllEvent('game_exit_click', null, 'gameplay', "no", "no");
      }
    },
    onClickPowerup(powerup) {
      if (powerup.id == 'c') {
        if (this.clueCount > 0 || this.player.coins >= powerup.value) {
          this.usePowerup({
            id: this.$store.state.player.id,
            key: "open",
            params: { gameplay: this.$store.state.gamePlay.id }
          }).then(() => {
            let wordCharacters = this._.map(this.wordArray, character => {
              if (this.flattenSpecialChars.includes(character)) {
                let equalChar = this._.findKey(this.specialChars, charList => {
                  return charList.includes(character)
                })

                if (equalChar) {
                  return equalChar
                }
              }

              return character
            })

            let leftCharacters = this._.difference(wordCharacters, this.rightCharacters);
            this._.pull(leftCharacters, " ");
            let char = leftCharacters[this._.random(0, leftCharacters.length - 1)]
            this.usedPowerupsLetters.push(char)
            this.feedAllEvent('powerup_usage', null, 'gameplay', 'open', 'open')
            this.onClickCharacter(char, 'open')
          })
          this.feedGame()
        } else {
          this.$modal.show("promo-modal", { promoType: "open" });
        }
      }

      if (powerup.id == 'r') {
        if (this.rewindCount > 0 || this.player.coins >= powerup.value) {
          if (this.wrongCharacters.length > 0) {
            this.usePowerup({
              id: this.$store.state.player.id,
              key: "rewind",
              params: { gameplay: this.$store.state.gamePlay.id }
            }).then(() => {
              this.increaseALive()
              this.wrongCharacters.splice(this.wrongCharacters.length - 1, 1)
              this.moves.push('rewind')
              this.feedAllEvent('powerup_usage', null, 'gameplay', 'rewind', 'rewind')
              this.feedGame()
            })

          }
        } else {
          this.$modal.show("promo-modal", { promoType: "rewind" });
        }
      }

      if (powerup.id == 'p') {
        if (this.changeCount > 0 || this.player.coins >= powerup.value) {
          this.usePowerup({
            id: this.$store.state.player.id,
            key: "change",
            params: { gameplay: this.$store.state.gamePlay.id }
          }).then(() => {
            this.feedAllEvent('powerup_usage', null, 'gameplay', 'change', 'change')
            this.startGame()
          })
        } else {
          this.$modal.show("promo-modal", { promoType: "change" });
        }
      }

      if (powerup.id == "h") {
        if ((this.hintCount > 0 || this.player.coins >= powerup.value)) {
          if (this.hints.length > 0 && this.usedHints.length < this.hints.length) {
            this.usePowerup({
              id: this.$store.state.player.id,
              key: "hint",
              params: { gameplay: this.$store.state.gamePlay.id }
            }).then(() => {
              if (this.usedHints.length < this.hints.length) {
                this.usedHints.push(this.usedHints.length);
                this.activeHint = this.usedHints.length - 1;
                this.showHints = true;
              }
              this.feedGame()
            })
          }
        } else {
          this.$modal.show("promo-modal", { promoType: "hint" });
        }
      }
      if (this.isSoundEffectsEnabled) {
        this.dominantSound = 1
        this.powerupUsageSound.play()
      }
    },
    reCreateGame() {
      this.feedAllEvent('click_button', null, 'interaction', 're-play', 're-play')
      this.finalizeSend = false
      this.$store.state.gamePlay = null;
      if (this.checkTicket()) {
        this.createGame({
          gameType: this.gameType
        }).then(() => {
          this.startGame()
          if (!this.hasImmortality()) {
            this.feedAllEvent('tickets_spent', null, 'gameplay')
          }
        })
      } else {
        this.startGame()
      }
    },
    startGame() {
      this.fbRewardExceeded = 1
      if (this.player.fbShareLimit > 0) {
        this.fbRewardExceeded = 0
      }
      this.fbShare = 0

      this.isSoundEffectsEnabled = this.player.appSettings.soundEffectsEnabled
      this.specialOfferSold = false
      this.lives = this.appSettings.maxMove;
      this.payload = null
      this.lockPage = true
      if (this.gameType == '1' && !this.checkTicket() && !this.hasGamePlay()) {
        this.$nextTick(() => {
          this.lockPage = false;
          this.$modal.show('promo-modal', { promoType: "ticket" })
        })
      } else {
        this.getWord({
          gameType: this.gameType
        }).then((wordToGuess) => {
          let hash = new Buffer(wordToGuess.word, 'base64')
          let word = Object.assign(wordToGuess, {
            word: hash.toString().toLocaleUpperCase('TR'),
            category: wordToGuess.category.toString().toLocaleUpperCase('TR')
          });
          this.payload = word
          this.$modal.hideAll()
          if (this.gamePlay && this.gamePlay.hint) {
            try {
              let hintHash = new Buffer(this.gamePlay.hint, 'base64');
              let hintObj = JSON.parse(hintHash.toString());
              this.hints = this._.values(hintObj);
              this.usedHints = this.gamePlay.usedHints || [];
            }
            catch (error) {
              this.hints = [];
            }
          }
          this.score = 0
          this.wrongCharacters = []
          this.rightCharacters = []
          this.usedPowerupsLetters = [];

          this.feedAllEvent('play_game_start', null, 'gameplay', this.payload.word, this.payload.word);

          if (null != this.gamePlay) {

            this.extraLives = this.gamePlay.extraMoves
            this.moveCount = this.gamePlay.moveCount
            this.moves = this.gamePlay.move
            this.usedPowerupsLetters = this.gamePlay.lettersPurchased;

            this._.forEach(this.usedPowerupsLetters, (character) => {
              this.rightCharacters.push(character)
            });

            let powerupKeys = ['open', 'rewind']
            let letterDesk = this.gamePlay.move.concat(this.usedPowerupsLetters)
            this._.forEach(letterDesk, (character) => {
              if (!powerupKeys.includes(character)) {
                let index = this._.indexOf(this.largeWordArray, character)
                if (index >= 0) {
                  this.rightCharacters.push(character)
                } else {
                  this.wrongCharacters.push(character)
                }
              }
            })

            const rewindPowerupCount = this._.filter(this.gamePlay.move, i => i == "rewind").length;
            if (rewindPowerupCount > 0) this.wrongCharacters = this._.dropRight(this.wrongCharacters, rewindPowerupCount);

            this.decreaseLives(this.wrongCharacters.length)
            this.score += this.rightCharacters.length + this.usedPowerupsLetters.length;
            if (this.gamePlay != null && this.gamePlay.status == "f") {
              this.gameStatus = this.score >= 1 ? "w" : "l";
              this.$modal.show("game-result-modal")
              this.clearGameData();
            }
          }
        }).catch(() => {
          this.$modal.show("game-result-modal")
        })
      }
    },
    endGame() {
      this.lockPage = false
      this.doubleScore = false
      this.gameId = this.$store.state.gamePlay.id;
      clearInterval(this.openPowerupInterval);

      if (!this.finalizeSend) {
        this.finalizeSend = true
        let gamePlay = this.getGamePlaySnapshot()
        this.finalizeGame(gamePlay).then((response) => {
          let gameplayObj = response.data.result.set
          this.feedAllEvent('play_game_completed', null, 'gameplay', null, this.gameStatus == 'w' ? 'success' : 'fail')

          let _this = this
          this.$OneSignal.getTags().then((tags) => {
            tags.score = !tags.score ? gameplayObj.scoreEarned : (parseInt(tags.score) + parseInt(gameplayObj.scoreEarned))

            if (_this.gameStatus == 'w') {
              tags.total_win = !tags.total_win ? 1 : (parseInt(tags.total_win) + 1)
            } else if (this.gameStatus == "l") {
              tags.total_lose = !tags.total_lose ? 1 : (parseInt(tags.total_lose) + 1)
            }

            _this.$OneSignal.sendTags(tags)
          })

          this.coinsEarned = gameplayObj.coinsEarned;

          if (this.specialOfferToggle) {
            this.specialOfferToggle = false
          } else {
            this.specialOfferToggle = true
          }
          this.specialOffer = response.data.result.set.specialOffer

          this.renewLeaderBoard();
          this.$gtag.pageview('game/finalize');
          if (this.gameStatus == "w") {
            this.gameCount++
            sessionStorage.setItem('gameCount', sessionStorage.getItem('gameCount') + 1)
            if (this.isSoundEffectsEnabled) {
              this.winGameSound.play()
            }
            this.$gtag.pageview(`${this.$route.path}/win`);
          } else if (this.gameStatus == "l") {
            if (this.isSoundEffectsEnabled) {
              this.looseGameSound.play()
            }
            this.$gtag.pageview(`${this.$route.path}/lose`);
          }

          // Hide hints panel
          this.showHints = false;

          // Show game result and reward modals
          if (Math.floor(Math.random() * 100) + 1 <= 90) {
            if (this.score > 0 && this.gameStatus == "w") {
              this.$modal.show('game-reward-modal');
            }
            else {
              this.$modal.show("game-result-modal")
            }
          }
          else {
            this.$modal.show("game-result-modal")
          }
        })
      }
    },
    onClickRewardAdButton() {
      this.doubleScore = true
      this.showGoogleRewardAd();
    },
    showGoogleRewardAd() {
      window.adBreak({
        type: 'reward',  // ad shows at start of next level
        name: 'google-reward',
        beforeAd: () => { },
        afterAd: () => {
          this.closeRewardModal(1);
        },
        beforeReward: (showAdFn) => {
          showAdFn();
        },
        adDismissed: () => {
          this.rewardGranted = false
          if (this.player.powerups.ticket == 1 && !this.doubleScore) {
            this.reCreateGame()
          }
        },
        adViewed: () => {
          this.rewardGranted = true;
          this.handleRewardCallback("adi");
        }
      });
    },
    feedGame() {
      let gamePlay = this.getGamePlaySnapshot()
      this.updateGame({
        id: this.$store.state.gamePlay.id,
        data: gamePlay
      }).then((response) => {
        let result = response.data.result.set
        if (result.move.length > this.moves.length) {
          let difference = result.move.filter(x => !this.moves.includes(x))
          difference.forEach((element) => {
            this.onClickCharacter(element)
            if (element == 'open') {
              let purchasedDifference = result.lettersPurchased.filter(x => !this.usedPowerupsLetters.includes(x))
              purchasedDifference.forEach((purchesed) => {
                this.onClickCharacter(purchesed, 'open')
              })
            }
          })
        }
      }
      )
    },
    getGamePlaySnapshot() {
      var gamePlay = this._.cloneDeep(this.$store.state.gamePlay)
      gamePlay.moveCount = this.moveCount
      gamePlay.move = this.moves
      gamePlay.lettersPurchased = this.usedPowerupsLetters
      gamePlay.usedHints = this.usedHints;

      if (this.gameType == '1') {
        gamePlay.scoreEarned = this.totalScore
      }
      if (this.gameStatus == 'w') {
        // gamePlay.coinsEarned = this.coinsEarned
      }
      return gamePlay
    },
    async renewLeaderBoard() {
      this.standingArray = [];
      const currentDate = new Date();
      return new Promise((resolve, reject) => {
        this.getPlayerStandings({
          player: this.player.id,
          date: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
        }).then((response) => {
          const userIndex = response.findIndex(element => element.player.id == this.player.id);
          let startNumber = (response.length - 1) === userIndex ? userIndex - 2 : userIndex - 1;
          let endNumber = userIndex > 0 ? userIndex + 2 : 3;
          for (let i = startNumber; i < endNumber; i++) {
            if (response[i] !== undefined) {
              this.standingArray.push(response[i]);
            }
          }
          resolve()
        }).catch(() => {
          reject();
        })
      })
    },
    onOpenGameResultModal() {
      const { googletag } = window;
      const _this = this;
      googletag.cmd.push(function () {
        _this.adSlot = googletag.defineSlot('/22674373488/AAEndGame', [[300, 50], [320, 50], [468, 60]], 'div-gpt-ad-1650625168685-0')
          .setTargeting("refresh", true)
          .addService(googletag.pubads());

        var mapping =
          googletag.sizeMapping()
            .addSize([500, 0], [468, 60])
            .addSize([0, 0], [[320, 50], [300, 50]])
            .build();

        _this.adSlot.defineSizeMapping(mapping);
        googletag.enableServices();

        // googletag.display(_this.adSlot)
      });
      googletag.cmd.push(function () {
        googletag.pubads().refresh([_this.adSlot])
      })
    },
    onCloseGameResultModal() {
      window.googletag.destroySlots([this.adSlot]);
    },
    closeRewardModal(ticketCheck = 0) {
      if (!this.doubleScore && ticketCheck == 1 && this.player.powerups.ticket == 1) {
        this.reCreateGame()
      } else {
        this.$modal.show('game-result-modal')
      }
      this.$modal.hide('game-reward-modal');
    },
    feedHomeEvent() {
      this.feedAllEvent('click_button', null, 'home', this.$route.path)
    },
    feedPromoEvent() {
      this.feedAllEvent('click_promo_purchase', null, 'ecommerce', this.$route.path)
    },
    feedForBtnAction(btn) {
      this.feedAllEvent('view_store', null, 'engagement', null, 'modal')

      if (btn == 'ticket') {
        this.feedAllEvent('click_in_game_ticket', null, 'ecommerce', this.$route.path)
      }

      if (btn == 'coin') {
        this.feedAllEvent('click_in_game_coin', null, 'ecommerce', this.$route.path)
      }
    },
    onClickGameHelpButton() {
      this.$refs.gameHelpModal.show();
    },
    onClickGameRewardsButton() {
      this.$refs.gameRewardsModal.show();
    },
    handleRewardCallback(networkCode) {
      this.doubleScore = true
      var payload = {
        adPlacementCode: 'double_score',
        networkCode: networkCode,
        data: {
          gameplayId: this.gameId
        }
      }
      this.sendRewardForAd(payload).then(() => {
        this.rewardStatus = true;
        this.renewLeaderBoard().then(() => {
          this.closeLoader()
          this.closeRewardModal()
        });
      });
    },
    onHandleRewardGranted(promoType) {
      if (promoType == "ticket") {
        this.checkOpenGame().then(() => {
          this.reCreateGame();
        });
      }
    },
    closeHintsPanel(e) {
      if (!e.target.closest(".hints-panel")) {
        this.showHints = false;
        document.removeEventListener("mouseDown", this.closeHintsPanel);
      }
    },
    beginTransaction(product) {
      this.purchaseSpecialOffer(product);
    },
    purchaseSpecialOffer() {
      this.purchaseStart({
        qty: 1,
        productId: this.specialOffer.id
      }).then((response) => {
        this.$modal.hide('game-result-modal');
        if (this.checkTicket()) {
          this.reCreateGame()
        } else {
          this.$router.push({ name: "dashboard" })
        }

        this.$gtag.event('begin_checkout', {
          'currency': 'TRY',
          'checkout_step': 1,
          'value': response.data.result.set.totalAmount,
          'items': [
            {
              'id': response.data.result.set.product.sku,
              'name': response.data.result.set.product.title,
              'price': response.data.result.set.product.price,
              'quantity': response.data.result.set.quantity
            }
          ]

        })
        window.fbq('track', 'InitiateCheckout', {
          content_name: response.data.result.set.product.title,
          content_ids: [response.data.result.set.product.id],
          content_type: 'product',
          value: response.data.result.set.product.price,
          currency: response.data.result.set.product.currency
        });

        this.feedPromoEvent()

        let transactionId = response.data.result.set.transactionId;
        let paymentPayload = {
          transactionId: transactionId,
          product: response.data.result.set.product,
          productTL: response.data.result.set.totalAmount,
          player: this.player,
          paymentMethods: response.data.result.set.availablePaymentMethods
        }
        this.$modal.show("payment-method-modal", paymentPayload)
      });
    },
    showPaymentMethodModal() {
      this.purchaseSpecialOffer(this.selectedProduct);
    },
    sendMsisdnVerificationCode() {
      this.createConfirmationRequest({ id: this.player.id, type: 'verify' }).then((response) => {
        if (response.data.code === "200.0401") {
          this.loading = false;
          this.$toast.error(response.data.message);
          return;
        }
        this.loading = false;
        this.$toast.success('Kod Cep telefonunuza gönderildi');
      }).catch(() => {
        this.loading = false;
        this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
      })
    },
    onCloseResultModal() {
      if (null == this.gamePlay) {
        this.$modal.show('game-result-modal')
      }
    },
    onChangeEffectSetting(value) {
      let permission = !value

      if (permission) {
        this.enableSoundEffects().then(() => {
          this.$toast.success('Ses efektleri açıldı.');
          this.isSoundEffectsEnabled = true
        })
      } else {
        this.disableSoundEffects().then(() => {
          this.$toast.success('Ses efektleri tercihiniz üzerine kapatıldı.');
          this.isSoundEffectsEnabled = false
        })
      }
    }
  }
}
</script>