<template>
  <div>
    <modal name="reset-password-modal" classes="modal py-2" :minWidth="200" :minHeight="400" :maxWidth="480"
      :scrollable="true" :reset="true" :adaptive="true" width="90%" height="auto" @closed="onCloseModal">

      <div class="flex justify-end relative">
        <button @click="$modal.hide('reset-password-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>

      <h2 class="text-center my-4" style="font-size: 24px">PAROLANI SIFIRLA <br> OYNAMAYA BAŞLA</h2>

      <div class="text-center my-4" style="font-size: 24px">
        <button type="button" @click="useEmail = true" :class="{ 'active': useEmail }">
          <img src="@/assets/icon-email.svg" alt="E-Mail" />
        </button>
        <button type="button" @click="useEmail = false" :class="{ 'active': !useEmail }">
          <img src="@/assets/icon-phone.svg" alt="Cep Telefonu" />
        </button>
      </div>

      <div class="mx-auto px-4">
        <p class="mb-4 text-center text-danger">{{ errorMsg }}</p>
        <form class="flex flex-col items-center" @submit.prevent="resetPassword">
          <validation-observer class="flex flex-col mb-4" style="width:80%" ref="form">
            <validation-provider v-if="useEmail" tag="div" name="E-Mail Adresi" rules="required|email"
              v-slot="{ errors }">
              <input type="text" :disabled="loading" v-model="currentUsername" required name="username"
                class="form-special" placeholder="E-Mail Adresi">
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider v-else class="mb-4" tag="div" name="Cep Telefonu"
              rules="required|validPhone|validatePhoneNumberLength" v-slot="{ errors }">
              <vue-tel-input type="tel" v-model="currentUsername" mode="international" :invalidMsg="errors[0]"
                :preferredCountries="['TR']" :validCharactersOnly="true" :disabled="loading" :autoFormat="true"
                :dropdownOptions="{
                  showDialCodeInList: true,
                  showFlags: true,
                  showSearchBox: true,
                }" :inputOptions="{
                  type: 'tel',
                  required: true,
                  name: 'username',
                  maxlength: 17,
                  placeholder: 'Cep Telefonu',
                }">
              </vue-tel-input>
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
          </validation-observer>

          <div class="mb-5">
            <RecaptchaComponent :sitekey="siteKey" :callback="onCaptchaSuccess" />
          </div>

          <button class="btn btn--primary relative mb-5"
            v-bind:class="{ 'ld-over-inverse running': this.loading == true }"
            :disabled="!isCaptchaValidated && loading">
            Parola Sıfırla
            <div class="ld ld-ring ld-spin" v-bind:class="{ 'running': this.loading == true }" v-if="loading"></div>
          </button>
        </form>
      </div>

    </modal>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { isValidPhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import RecaptchaComponent from './RecaptchaComponent.vue';
import axios from 'axios';

extend('validPhone', {
  validate: (value) => isValidPhoneNumber(value, 'TR'),
  message: 'Lütfen geçerli bir telefon numarası giriniz.',
});
extend('validatePhoneNumberLength', {
  validate: (value) => {
    const phoneNumber = parsePhoneNumberFromString(value, 'TR');
    return phoneNumber && phoneNumber.nationalNumber.length === 10;
  },
  message: 'Telefon numarası 10 haneli olmalıdır.',
});

export default {
  components: { ValidationProvider, ValidationObserver, RecaptchaComponent },
  name: "reset-password-modal",
  props: {
    username: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      currentUsername: null,
      errorMsg: "",
      loading: false,
      useEmail: true,
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      secretKey: process.env.VUE_APP_RECAPTCHA_SECRET_KEY,
      isCaptchaValidated: false
    }
  },
  computed: {
    ...mapState(["player"]),
  },
  created() {
    this.currentUsername = this.username
  },
  methods: {
    ...mapActions(['login', 'register', 'createPasswordReset']),
    onCloseModal() {
      this.currentUsername = null;
      this.isCaptchaValidated = false;
    },
    async onCaptchaSuccess(token) {
      try {
        const response = await axios.post('https://www.google.com/recaptcha/api/siteverify', null, {
          params: {
            secret: this.secretKey,
            response: token,
          }
        });

        if (response.data.success) {
          this.isCaptchaValidated = true;
        } else {
          console.error('Token verification failed:', response);
        }
      } catch (error) {
        console.error('Error verifying reCAPTCHA token:', error);
      }
      this.isCaptchaValidated = true;
    },
    resetPassword() {
      this.$refs.form.validate().then((isValid) => {
        if (!isValid) {
          return;
        } else if (!this.isCaptchaValidated) {
          this.$toast.error('Lütfen reCAPTCHA doğrulamasını tamamlayın.');
          return;
        }
        this.loading = true;

        this.createPasswordReset({ username: this.currentUsername }).then((response) => {
          if (response.data.code === "200.0401") {
            this.loading = false;
            this.$toast.error(response.data.message);
            return;
          }
          this.loading = false;

          if (this.useEmail) {
            if (response.data.code === "200.0404") {
              this.errorMsg = "Bu e-mail adresi ile bir kullanıcı bulunamadı.";
            }
          } else {
            if (response.data.code === "200.0404") {
              this.errorMsg = "Bu telefon numarası ile bir kullanıcı bulunamadı.";
            }
          }
          if (!this.errorMsg) {
            this.$emit('handleReset', {
              username: this.currentUsername,
            });
          }

        }).catch(() => {
          this.loading = false;
          this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
        });
      });
    }
  }
}
</script>

<style scoped>
.active {
  font-weight: bold;
}

button.active img {
  border: 2px solid #ffb74f;
  border-radius: 50%;
}

.vue-tel-input {
  border-radius: 8px;
  display: flex;
  border: 4px solid black;
  text-align: left;
}
</style>