<template>
  <div>
    <modal name="league-game-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480" :scrollable="true"
      :adaptive="true" :reset="true" width="90%" @closed="onCloseModal" height="auto">
      <div class="flex flex-col items-center h-full">
        <div id="league-game-modal-container" class="modal-container">
          <template v-if="player.playerStanding && player.playerStanding.currentRank <= 50">
            <h2 class="text-center my-4">OYNA, EĞLEN, KAZAN!</h2>
            <div class="winner-stand flex flex-col items-center">
              <img :src="require('@/assets/winner-stand.svg')" alt="">
              <div class="ribbons">
                <div class="ribbons__item ribbons__item--silver">
                </div>
                <div class="ribbons__item ribbons__item--gold">
                </div>
                <div class="ribbons__item ribbons__item--bronze">
                </div>
              </div>
            </div>
            <div class="leaderboard leaderboard--single mx-auto px-4 mb-4 w-full">
              <div class="leaderboard__item" v-for="standingPlayer, i in standingPlayers"
                :key="`${standingPlayer.nickname}-${i}`"
                :class="{ 'active': standingPlayer.player.alias == player.alias }">
                <div class="leaderboard__item-number">
                  {{ standingPlayer.currentRank ? `${standingPlayer.currentRank}.` : '-' }}
                </div>
                <div class="leaderboard__item-info flex-grow">
                  <div class="leaderboard__item-avatar">
                    <img :src="standingPlayer.player.avatarUrl" alt="">
                  </div>
                  <div class="leaderboard__item-name flex-grow">{{ standingPlayer.player.alias }}</div>
                </div>
                <!-- <div class="leaderboard__item-points">{{ standingPlayer.score ? standingPlayer.score : 0 }}</div> -->
                <div class="ml-1 flex items-center">
                  <template v-if="standingPlayer.rewardInformation.giftCard">
                    <coupon-box :coupon="standingPlayer.rewardInformation.giftCard"
                      :playerRank="standingPlayer.currentRank" scrollParent="league-game-modal-container">
                    </coupon-box>
                    <span class="font-bold mr-2">+</span>
                  </template>
                  <div class="leaderboard__item-giftbox mr-2">
                    <reward-box :instructions="standingPlayer.rewardInformation" :currentPlayer="standingPlayer.player"
                      scrollParent="league-game-modal-container" v-if="standingPlayer.rewardInformation"></reward-box>

                    <!-- <reward-box :instructions="standingPlayer.rewardInformation" scrollParent="league-game-modal-container" :currentPlayer="standingPlayer.player"
                      v-if="standingPlayer.rewardInformation" @phone-verification="openLoginModal"></reward-box> -->
                  </div>
                  <div class="leaderboard__item-points">{{ standingPlayer.score }}</div>
                </div>
              </div>
              <div class="leaderboard__item active"
                v-if="player.playerStanding && player.playerStanding.currentRank && player.playerStanding.currentRank > 5">
                <div class="leaderboard__item-number">{{ player.playerStanding.currentRank }}</div>
                <div class="leaderboard__item-info">
                  <div class="leaderboard__item-avatar">
                    <img :src="player.avatarUrl" alt="">
                  </div>
                  <div class="leaderboard__item-name">{{ player.alias }}</div>
                </div>
                <div class="leaderboard__item-points">
                  {{ player.playerStanding && player.playerStanding.score ? player.playerStanding.score : 0 }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="promoProduct != null" class="relative flex flex-col items-center" style="width:100%">
              <h2 class="text-center absolute my-4">OYNA, EĞLEN, KAZAN!</h2>
              <!--img src="@/assets/league-banner.png" alt=""-->
              <div class="w-3/5 mb-4 flex justify-center" style="margin-top:90px">
                <div class="store-item store-item--bold justify-self-center heartbeat"
                  @click="onClickPurchaseSpecialOffer(promoProduct)">
                  <h2>OYUNA AVANTAJLI BAŞLA</h2>
                  <div class="store-item__img">
                    <img :src="promoProduct.image" alt="title">
                    <span class="text-stroke" v-if="promoProduct.rewardInfo.coins > 0"><small>x</small>{{
                      promoProduct.rewardInfo.coins }}</span>
                    <template v-if="promoProduct.rewardInfo.extraInternet">
                      <div class="store-item__img__extra">
                        <p class="text-stroke">+</p>
                        <p class="heartbeat text-stroke mt-1 mb-1">{{ promoProduct.rewardInfo.extraInternet.title }}</p>
                      </div>
                    </template>
                    <span class="text-stroke" v-else-if="promoProduct.rewardInfo.powerups.open > 0"><small>x</small>{{
                      promoProduct.rewardInfo.powerups.open }}</span>
                    <span class="text-stroke" v-else-if="promoProduct.rewardInfo.powerups.rewind > 0"><small>x</small>{{
                      promoProduct.rewardInfo.powerups.rewind }}</span>
                    <span class="text-stroke" v-else-if="promoProduct.rewardInfo.powerups.ticket > 0"><small>x</small>{{
                      promoProduct.rewardInfo.powerups.ticket }}</span>
                    <span class="text-stroke" v-else-if="promoProduct.rewardInfo.powerups.hint > 0"><small>x</small>{{
                      promoProduct.rewardInfo.powerups.hint }}</span>
                    <span class="text-stroke" v-else-if="promoProduct.rewardInfo.powerups.change > 0"><small>x</small>{{
                      promoProduct.rewardInfo.powerups.change }}</span>
                  </div>
                  <div class="store-item__value">
                    <span class="store-item__value-number">{{ promoProduct.price }}</span>
                    <span class="store-item__value-currency">
                      <template v-if="promoProduct.currency == 'TRY'">TL</template>
                      <template v-else>
                        <img src="@/assets/coin-small.svg" alt="">
                      </template>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!--div class="border-dashed border-4 border-gray-400 text-center text-xl rounded-2xl pb-12 pt-4 px-4 w-11/12">
        <template v-if="player && player.playerStanding.currentRank && player.playerStanding.currentRank <= 5">
          <div class="flex flex-wrap items-center justify-center">
            Ligi <span class="font-bold text-2xl mx-2">{{player.playerStanding.currentRank}}.</span> sırada bitirirsen
            <div class="relative flex items-center flex-col mx-2">
              <img src="@/assets/icon-coupon-orange.svg" style="height: 28px" alt="">
              <span class="font-bold absolute text-sm"
                style="top:5px">{{coupons[player.playerStanding.currentRank - 1]}}₺</span>
            </div>
            <span class="font-bold mx-2">Hediye Çeki</span> kazanıyorsun!
          </div>

        </template>
        <template
          v-else-if="player && player.playerStanding.currentRank && player.playerStanding.currentRank > 5 && player.playerStanding.currentRank <= 20">
          Hediye çeki kazanmana <span class="font-bold text-2xl">{{player.playerStanding.currentRank - 5}}</span> sıra
          kaldı.
        </template>
        <template v-else>
          Ligde ilk 5'e gir, <br> <span class="font-bold text-2xl">Hediye Çeki</span> kazan!
        </template>
      </div-->
        <div class="flex justify-center my-4 w-full flex-col relative">
          <button class="btn btn--primary mr-10 ml-10" @click.prevent="onClickCreateGame">OYNA</button>
          <a href="#" class="mr-8 float-right text-right mt-5"
            v-if="this.player.isGuest && !this.player.isFacebook && !this.player.isGoogle && !this.player.isEmail"
            @click.prevent="onClickLogin">Giriş Yap</a>
        </div>
      </div>
    </modal>

    <login-and-register-modal-two ref="login-and-register-modal-two" name="login-and-register-modal-two" :rType="rType"
      @user-logged-in="showPaymentMethodModal" />

    <phone-verification-modal ref="phone-otp-verification-modal" name="phone-otp-verification-modal" type="verify"
      title="Telefon numaranızı doğrulayın" description="Cep telefonunuza gönderilen kodu girin"
      @phone-verified="showPaymentMethodModal" />
  </div>
</template>
<script>
import {
  mapActions,
  mapState
} from 'vuex'
import coupons from "@/data/coupons"
import RewardBox from "@/views/components/RewardBox";
import CouponBox from "@/views/components/CouponBox";
import Feed from "@/mixins/Feed";
import LoginAndRegisterModalTwo from '@/views/components/LoginAndRegisterModalTwo'
import PhoneVerificationModal from '@/views/components/PhoneVerificationModal.vue'

export default {
  mixins: [Feed],
  components: {
    RewardBox,
    CouponBox,
    LoginAndRegisterModalTwo,
    PhoneVerificationModal,
  },
  data() {
    return {
      coupons: coupons,
      rType: '',
    }
  },
  computed: {
    ...mapState(['player', 'leaderboard', 'productsOfPromotions', 'environment']),
    standingPlayers() {
      return this.leaderboard.slice(0, 5);
    },
    promoProduct() {
      let prodId = 62
      if (this.environment == 'dev') { prodId = 59 }
      return this.productsOfPromotions.find(p => p.id == prodId) || null;
    }
  },
  methods: {
    ...mapActions(["googleLogin", "facebookLogin", "createGame", "getGlobalStanding", "emptyLeaderboard", "purchaseStart", "createConfirmationRequest"]),
    prepareGlobalStanding() {
      this.standingPlayers = this.leaderboard.slice(0, 5);
    },
    show() {
      this.$modal.show('league-game-modal')
    },
    hide() {
      this.$modal.hide('league-game-modal');
    },
    onCloseModal() {
      this.emptyLeaderboard();
    },
    onClickCreateGame() {
      this.createGame({
        gameType: '1'
      }).then(() => {
        this.$emit('onCreate')
        this.$router.push({
          name: "game",
          params: {
            id: 'lig-oyunu'
          },
          query: this.$route.query
        })
      })
    },
    onClickLogin() {
      this.$modal.show("dashboard-login-modal");
      this.hide();
    },
    onClickPurchaseSpecialOffer(product) {
      this.beginTransaction(product);
    },
    beginTransaction() {
      this.purchaseStart({
        qty: 1,
        productId: this.promoProduct.id
      }).then((response) => {
        this.$modal.hide('game-result-modal');
        this.$gtag.event('begin_checkout', {
          'currency': 'TRY',
          'checkout_step': 1,
          'value': response.data.result.set.totalAmount,
          'items': [
            {
              'id': response.data.result.set.product.sku,
              'name': response.data.result.set.product.title,
              'price': response.data.result.set.product.price,
              'quantity': response.data.result.set.quantity
            }
          ]

        })
        window.fbq('track', 'InitiateCheckout', {
          content_name: response.data.result.set.product.title,
          content_ids: [response.data.result.set.product.id],
          content_type: 'product',
          value: response.data.result.set.product.price,
          currency: response.data.result.set.product.currency
        });

        this.feedPromoEvent()

        let transactionId = response.data.result.set.transactionId;
        let paymentPayload = {
          transactionId: transactionId,
          product: response.data.result.set.product,
          productTL: response.data.result.set.totalAmount,
          player: this.player,
          paymentMethods: response.data.result.set.availablePaymentMethods
        }
        this.$modal.hide('promo-modal');
        this.$modal.show("payment-method-modal", paymentPayload)
      });
    },
    showPaymentMethodModal() {
      this.beginTransaction(this.selectedProduct);
    },
    feedPromoEvent() {
      this.feedAllEvent('click_promo_purchase', null, 'ecommerce', this.$route.path)
    },
    sendMsisdnVerificationCode() {
      this.createConfirmationRequest({ id: this.player.id, type: 'verify' }).then((response) => {
        if (response.data.code === "200.0401") {
          this.loading = false;
          this.$toast.error(response.data.message);
          return;
        }
        this.loading = false;
        this.$toast.success('Kod Cep telefonunuza gönderildi');
      }).catch(() => {
        this.loading = false;
        this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
      })
    }
  }
}
</script>