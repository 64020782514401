import Vue from "vue"
import Vuex from "vuex"
import _ from "lodash"
import authApi from "@/api/auth"
import appApi from "@/api/app"
import moment from 'moment'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        adsVisible: false,
        isLoading: false,
        authenticated: false,
        gamePlay: null,
        productGroups: [],
        productsOfPromotions: [],
        avatars: [],
        adReward: null,
        rewardType: null,
        notifications: [],
        appSettings: {
            locale: "tr-tr",
            gameEffects: true,
            gameMusic: true,
            cdnUrl: "https://cdn.adam-asmacam.com",
            maxMove: 7,
            leagueInfo: null,
            notificationSettings: {
                leaderboardNotifications: "push",
                gameResultNotifications: "push",
                marketingNotifications: "push",
                paymentNotifications: "email",
                systemNotifications: "email",
                legalNotifications: "email",
            },
            theme: "default",
        },
        player: {
            id: null,
            alias: null,
            msisdn: null,
            avatarUrl: null,
            ticketRenewTime: null,
            adRenewTime: null,
            adWatchLimit: null,
            fbShareTime: null,
            fbShareLimit: null,
            powerups: {
                rewind: 0,
                open: 0,
                ticket: 0,
                immortality: {
                    isActive: false,
                    validUntil: null,
                },
            },
            email: null,
            coins: 0,
            playerStanding: {
                currentRank: null,
                score: null
            },
            appSettings: {
                locale: "tr-tr",
                gameEffects: true,
                gameMusic: true,
                cdnUrl: "https://cdn.adam-asmaca.com",
                maxMove: 7,
                leagueInfo: null,
                notificationEnabled: true,
                soundEffectsEnabled: true,
                notificationSettings: {
                    leaderboardNotifications: "push",
                    gameResultNotifications: "push",
                    marketingNotifications: "push",
                    paymentNotifications: "email",
                    systemNotifications: "email",
                    legalNotifications: "email",
                },
                theme: "default",
            },
            isImmortal: false,
            isGoogle: false,
            isFacebook: false,
            isGuest: false,
            isEmail: false,
            isVerification: false,
            isMsisdn: false,
            isVerifiedMsisdn: false
        },
        leaderboard: [],
        leaderboardPagination: null,
        osUserId: null,
        openGame: false,
        inGame: false,
        adRewards: {},
        powerupPrices: {
            open: 200,
            rewind: 50,
            change: 75
          },
    },
    getters: {
        isAuthenticated(state) {
            return state.authenticated
        },
        isNotificationEnabled(state) {
            return state.player.appSettings.notificationEnabled
        },
        isSoundEffectsEnabled(state) {
            return state.player.appSettings.soundEffectsEnabled
        },
        checkTicket(state) {
          if(state.openGame === true){
              return true;
          }
          if (state.player.powerups.immortality.validUntil) {
              var date = new Date();
              var dateTwo = new Date(state.player.powerups.immortality.validUntil);
              if(date < dateTwo)
              {
                  return true
              }
          }
          return state.player.powerups.ticket > 0
        },
        hasImmortality(state) {
            if (state.player.powerups.immortality.validUntil) {
                var date = new Date();
                var dateTwo = new Date(state.player.powerups.immortality.validUntil);
                if(date < dateTwo)
                {
                    return true
                }
            }
            
            return false
        },
        hasGamePlay(state) {
            return null != state.gamePlay
        },
        getRandomWord(state) {
            let index = _.random(0, state.words.length - 1)
            return state.words[index]
        }
    },
    mutations: {
        SET_SESSION_TOKEN(state, payload) {
            state.sessionToken = payload
        },
        SET_IS_LOADING(state, payload) {
            state.isLoading = payload
        },
        SET_AUTHENTICATED(state, payload) {
            state.authenticated = payload
        },
        SET_APP_SETTINGS(state, payload) {
            state.appSettings = payload
        },
        SET_ENVIRONMENT(state, payload) {
            state.environment = payload
        },
        SET_PLAYER_INFO(state, payload) {
            state.player = payload
        },
        SET_PLAYER_ID(state) {
            state.player.id = localStorage.getItem('playerId');
        },
        RESET_PLAYER_INFO(state) {
            state.player = {
                id: null,
                alias: null,
                avatarUrl: null,
                ticketRenewTime: null,
                adRenewTime: null,
                adWatchLimit: null,
                fbShareTime: null,
                fbShareLimit: null,
                powerups: {
                    rewind: 0,
                    open: 0,
                    ticket: 0,
                    immortality: {
                        isActive: false,
                        validUntil: null,
                    },
                },
                email: null,
                coins: 0,
                playerStanding: {
                    currentRank: null,
                    score: null
                },
                appSettings: {
                    locale: "tr-tr",
                    gameEffects: true,
                    gameMusic: true,
                    cdnUrl: "https://cdn.adam-asmacam.com",
                    maxMove: 7,
                    leagueInfo: null,
                    notificationEnabled: true,
                    soundEffectsEnabled: true,
                    notificationSettings: {
                        leaderboardNotifications: "push",
                        gameResultNotifications: "push",
                        marketingNotifications: "push",
                        paymentNotifications: "email",
                        systemNotifications: "email",
                        legalNotifications: "email",
                    },
                    theme: "default",
                },
                isImmortal: false,
                isGoogle: false,
                isFacebook: false,
                isGuest: false,
                isEmail: false,
                isVerification: false,
                isMsisdn: false,
                isVerifiedMsisdn: false,
            }
        },
        UPDATE_PLAYER_INFO(state, payload) {
            var player = state.player

            player.id = payload.id
            player.alias = payload.alias
            player.avatarUrl = payload.avatarUrl
            player.ticketRenewTime =  payload.ticketRenewTime,
            player.adRenewTime =  payload.adRenewTime,
            player.adWatchLimit =  payload.adWatchLimit,
            player.fbShareTime =  payload.fbRenewTime,
            player.fbShareLimit =  payload.fbLimit,
            player.shareHash =  payload.shareHash,
            player.powerups = payload.powerups
            player.email = payload.email
            player.coins = payload.coins
            player.isImmortal = payload.isImmortal
            player.playerStanding = payload.playerStanding
            player.isGoogle = payload.isGoogle
            player.isFacebook = payload.isFacebook
            player.isGuest = payload.isGuest
            player.isEmail = payload.isEmail
            player.isVerification = payload.isVerification
            player.appSettings = payload.appSettings
            player.msisdn = payload.msisdn
            player.isMsisdn = payload.isMsisdn
            player.isVerifiedMsisdn = payload.isVerifiedMsisdn
            player.lastPanAt = payload.lastPan

            state.player = player
        },
        UPDATE_PLAYER_ALIAS(state, payload) {
            state.player.alias = payload;
        },
        UPDATE_PLAYER_AVATAR(state, payload) {
            state.player.avatarUrl = payload;
        },
        UPDATE_PLAYER_POWERUP(state, payload) {
            state.player.powerups = payload
            if(state.gamePlay) {
                state.gamePlay.player.powerups = payload
            }
        },
        ADD_COINS(state, coins) {
            state.player.coins += coins
        },
        REMOVE_COINS(state, coins) {
            state.player.coins -= coins
        },
        ADD_TICKETS(state, tickets) {
            state.player.powerups.ticket += tickets
        },
        REMOVE_TICKET(state) {
            state.player.powerups.ticket--
        },
        SET_GAMEPLAY(state, payload) {
            state.gamePlay = payload
        },
        SET_PRODUCT_GROUPS(state, payload) {
            state.productGroups = payload
        },
        SET_PRODUCTS_OF_PROMOTIONS(state, payload) {
          state.productsOfPromotions = payload;
        },
        EMPTY_LEADERBOARD(state) {
          state.leaderboard = [];
        },
        SET_LEADERBOARD(state, payload) {
            state.leaderboard = state.leaderboard.concat(payload);
        },
        SET_LEADERBOARD_PAGINATION(state, payload) {
          state.leaderboardPagination = payload;
        },
        SET_AVATARS(state, payload) {
            state.avatars = payload
        },
        SET_IS_GAME(state, payload) {
            state.openGame = payload;
        },
        SET_OS_USER_ID(state, payload) {
            state.osUserId = payload;
        },
        SET_REWARD_TYPE(state, payload) {
          state.rewardType = payload;
        },
        SET_AD_REWARD(state, payload) {
            state.adReward = payload
        },
        SET_IN_GAME(state, payload) {
            state.inGame = payload
        },
        UPLOAD_TICKET_IMAGE() {},
        UPDATE_PLAYER_IS_GOOGLE(state, payload) {
            state.player.isGoogle = payload;
        },
        UPDATE_PLAYER_IS_FACEBOOK(state, payload) {
            state.player.isFacebook = payload;
        },
        SET_NOTIFICATIONS(state, payload) {
          state.notifications = payload;
        },
        READ_NOTIFICATION(state, payload) {
          const index = state.notifications.indexOf(payload);
          state.notifications.splice(index, 1);
        },
        UPDATE_PLAYER_NOTIFICATION_STATUS(state, payload) {
           state.player.appSettings.notificationEnabled = payload 
        },
        UPDATE_PLAYER_SOUNDEFFECT_STATUS(state, payload) {
            state.player.appSettings.soundEffectsEnabled = payload
        },
        SET_AD_REWARDS(state, payload) {
            state.adRewards.store = payload.value
        }
    },
    actions: {
        openLoader({commit}) {
            commit("SET_IS_LOADING", true)
        },
        closeLoader({commit}) {
            commit("SET_IS_LOADING", false)
        },
        toggleLoader({commit}) {
            commit("SET_IS_LOADING", !this.state.isLoading)
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('SET_SESSION_TOKEN', null);

                return new authApi.logout().then(() => {
                    localStorage.setItem('sessionToken', null);
                    localStorage.setItem('playerId', null);
                    commit('SET_AUTHENTICATED', false)
                    commit('SET_SESSION_TOKEN', null)
                    commit('RESET_PLAYER_INFO')
                    
                    resolve(true)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        disconnectPlatform({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_SESSION_TOKEN', null);

                return new authApi.disconnectPlatform(payload.platform).then(() => {
                    commit('SET_SESSION_TOKEN', null)
                    
                    if('google' == payload.platform) {
                        commit('UPDATE_PLAYER_IS_GOOGLE', false)
                    } 

                    if('facebook' == payload.platform) {
                        commit('UPDATE_PLAYER_IS_FACEBOOK', false)
                    }

                    resolve(true)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        login({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_SESSION_TOKEN', null);
                let cookie = Vue.$cookies.get("origin-campaign-id")
                let existGuestId = localStorage.getItem('playerId')
                if(cookie && !payload.originCampaignId){ payload.originCampaignId = cookie }
                if(existGuestId) { payload.existGuestId = existGuestId }
                return new authApi.login(payload).then((response) => {
                    let responseObj = response.data.result.set

                    localStorage.setItem('sessionToken', response.data.sessionToken);
                    localStorage.setItem('playerId', responseObj.id);
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_SESSION_TOKEN', response.data.sessionToken)
                    commit('UPDATE_PLAYER_INFO', responseObj)
                    dispatch('feedHourlyStat', {
                        recordedAt: moment().format('YYYY-MM-DD'),
                        hour: moment().format('H'),
                        event: 'count_login',
                        value: 1,
                        param: 'google'
                    })

                    this._vm.$OneSignal.setExternalUserId(responseObj.id)

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        register({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_SESSION_TOKEN', null);
                let cookie = Vue.$cookies.get("origin-campaign-id")
                if(cookie && !payload.originCampaignId){ payload.originCampaignId = cookie }
                return new authApi.register(payload).then((response) => {
                    let responseObj = response.data.result.set

                    localStorage.setItem('playerId', responseObj.id);
                    localStorage.setItem('sessionToken', response.data.sessionToken);

                    commit('SET_AUTHENTICATED', true)
                    commit('SET_SESSION_TOKEN', response.data.sessionToken)
                    commit('UPDATE_PLAYER_INFO', responseObj)

                    this._vm.$OneSignal.setExternalUserId(responseObj.id)

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        changePassword({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new authApi.passwordChange(payload).then((response) => {
                    let responseObj = response.data.result.set
                    commit('UPDATE_PLAYER_INFO', responseObj)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        guestLogin({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_SESSION_TOKEN', null);
                let cookie = Vue.$cookies.get("origin-campaign-id")
                let invite = Vue.$cookies.get("invited-user")
                if(cookie && !payload.originCampaignId){
                    payload.originCampaignId = cookie
                    document.cookie = 'origin-campaign-id=; Max-Age=-1;';
                    let url = document.location.href;
                    if(url.indexOf("?")>0){
                        history.pushState({}, null, url.split('?')[0])
                    }
                }
                if(invite && !payload.inviteUserCode){
                    payload.inviteUserCode = invite
                    document.cookie = 'invited-user=; Max-Age=-1;';
                    let url = document.location.href;
                    if(url.indexOf("?")>0){
                        history.pushState({}, null, url.split('?')[0])
                    }
                }

                return new authApi.guestLogin(payload).then((response) => {
                    let responseObj = response.data.result.set

                    localStorage.setItem('playerId', responseObj.id);
                    localStorage.setItem('sessionToken', response.data.sessionToken);

                    commit('SET_AUTHENTICATED', true)
                    commit('SET_SESSION_TOKEN', response.data.sessionToken)

                    commit('UPDATE_PLAYER_INFO', responseObj)
                    dispatch('feedHourlyStat', {
                        recordedAt: moment().format('YYYY-MM-DD'),
                        hour: moment().format('H'),
                        event: 'count_login',
                        value: 1,
                        param: 'direct'
                    })

                    this._vm.$OneSignal.setExternalUserId(responseObj.id)

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        googleLogin({commit, dispatch}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                commit('SET_SESSION_TOKEN', null);
                let existGuestId = localStorage.getItem('playerId')
                if(existGuestId) { payload.existGuestId = existGuestId }
                return new authApi.googleLogin(payload).then((response) => {
                    let responseObj = response.data.result.set
                    localStorage.setItem('sessionToken', response.data.sessionToken);
                    localStorage.setItem('playerId', responseObj.id);
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_SESSION_TOKEN', response.data.sessionToken)
                    commit('UPDATE_PLAYER_INFO', responseObj)
                    dispatch('feedHourlyStat', {
                        recordedAt: moment().format('YYYY-MM-DD'),
                        hour: moment().format('H'),
                        event: 'count_login',
                        value: 1,
                        param: 'google'
                    })

                    this._vm.$OneSignal.setExternalUserId(responseObj.id)

                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        facebookLogin({commit, dispatch}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                commit('SET_SESSION_TOKEN', null);
                let existGuestId = localStorage.getItem('playerId')
                if(existGuestId) { payload.existGuestId = existGuestId }
                return new authApi.facebookLogin(payload).then((response) => {
                    let responseObj = response.data.result.set

                    localStorage.setItem('sessionToken', response.data.sessionToken);
                    localStorage.setItem('playerId', responseObj.id);
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_SESSION_TOKEN', response.data.sessionToken)
                    commit('UPDATE_PLAYER_INFO', responseObj)
                    
                    dispatch('feedHourlyStat', {
                        recordedAt: moment().format('YYYY-MM-DD'),
                        hour: moment().format('H'),
                        event: 'count_login',
                        value: 1,
                        param: 'facebook'
                    })

                    this._vm.$OneSignal.setExternalUserId(responseObj.id)

                    commit("SET_IS_LOADING", false)

                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },

        init({commit, dispatch}) {
            let environment = process.env.NODE_ENV == 'development' ? 'dev' : 'prod'
            commit('SET_ENVIRONMENT', environment)

            const init = new Promise((resolve) => {
              return new authApi.init(environment).then((response) => {
                response.data.result.set.appInfo.defaultSettings["leagueInfo"]=response.data.result.set.leagueInfo
                commit('SET_APP_SETTINGS', response.data.result.set.appInfo.defaultSettings)
                  dispatch("initOnesignal").then((response) => {
                    console.log(response)
                  }).catch((err) => {
                      console.log(err)
                      resolve()
                     })
                if (this.state.authenticated) {
                    new appApi.getPlayer(localStorage.getItem('playerId')).then((playerResponse) => {
                        this._vm.$gtag.config({userId: localStorage.getItem('playerId'), user_id: localStorage.getItem('playerId')});
                        commit("UPDATE_PLAYER_INFO", playerResponse.data.result.set);
                    });
                }

                resolve(response)
              })
            })

            return Promise.all([init]).then(() => {
              if(this.state.authenticated) {
                  dispatch('feedHourlyStat', {
                      recordedAt: moment().format('YYYY-MM-DD'),
                      hour: moment().format('H'),
                      event: 'count_open',
                      value: 1,
                      param: 'null'
                  })
                  if(Vue.$cookies.get("fb-share-return-id") != null){
                      let id = Vue.$cookies.get("fb-share-return-id")
                      document.cookie = 'fb-share-return-id=; Max-Age=-1;';
                      let url = document.location.href;
                      if(url.indexOf("?")>0){
                          history.pushState({}, null, url.split('?')[0])
                      }

                      dispatch('feedHourlyStat', {
                          recordedAt: moment().format('YYYY-MM-DD'),
                          hour: moment().format('H'),
                          event: 'social_share_return',
                          value: 1,
                          param: 'fb-' + id
                      })
                  }
              }
            });

        },
        initOnesignal({dispatch}) {
            return new Promise((resolve) => {
                if(location.hostname == "localhost") {
                resolve();
                }

                this._vm.$OneSignal.init({
                    appId: process.env.VUE_APP_ONESIGNAL_APP
                }).then(() => {
                const oneSignalUserID = this._vm.$OneSignal.getUserId().then((osUserId) => {
                    if(osUserId) {
                        dispatch('updateOSUserId', {userId: osUserId});
                    }
                })

                    // Set external id if it is not exist
                    const oneSignalExtUserID = this._vm.$OneSignal.getExternalUserId().then((externalId) => {
                        if(!externalId && localStorage.getItem('playerId')) {
                        this._vm.$OneSignal.setExternalUserId(localStorage.getItem('playerId'))
                    }
                    })

                        Promise.all([oneSignalUserID, oneSignalExtUserID]).then(() => {
                    resolve();
                })

                }).catch(e => {
                    console.error("OneSignal initialization error:", e);
                resolve();
                })
            })
        },
        getPlayerInfo({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getPlayer(payload.id).then((response) => {
                    commit("UPDATE_PLAYER_INFO", response.data.result.set)
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getPlayerInternet({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getPlayerInternet(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        sendPlayerInternet({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.sendPlayerInternet(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        updatePlayerAlias({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.patchPlayerAlias(payload).then((response) => {
                    commit("UPDATE_PLAYER_ALIAS", payload.alias);
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        updatePlayerLastPan({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.patchPlayerLastPan(payload).then((response) => {
                    commit("UPDATE_PLAYER_INFO", response.data.result.set)
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        updatePlayerAvatar({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.patchPlayerAvatar(payload).then((response) => {
                    commit("UPDATE_PLAYER_AVATAR", payload.avatar.path);
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getFreeGame({commit}) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getFreeGame().then((response) => {
                    commit("SET_GAMEPLAY", response.data.result.set)
                    commit("SET_IS_LOADING", false)

                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        createGame({commit, state}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.createGame(payload.gameType).then((response) => {
                    commit("SET_GAMEPLAY", response.data.result.set)
                    commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
                    commit("SET_IS_LOADING", false)

                    localStorage.setItem('atGame', true)
                    if (state.gamePlay.difficulty == 0) {
                        localStorage.setItem('trainingGameId', state.gamePlay.id)
                    }

                    if (state.gamePlay.difficulty == 1) {
                        localStorage.setItem('leagueGameId', state.gamePlay.id)
                    }

                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        async updateGame({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.updateGame(payload).then((response) => {
                    commit("SET_GAMEPLAY", response.data.result.set)
                    commit("UPDATE_PLAYER_INFO", response.data.result.set.player)

                    localStorage.setItem('atGame', true)
                    if (state.gamePlay.difficulty == 0) {
                        localStorage.setItem('trainingGameId', state.gamePlay.id)
                    }

                    if (state.gamePlay.difficulty == 1) {
                        localStorage.setItem('leagueGameId', state.gamePlay.id)
                    }

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        finalizeGame({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.finalizeGame(payload).then((response) => {
                    commit("SET_GAMEPLAY", null)
                    commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
                    commit("SET_IS_LOADING", false)
                    commit('SET_IS_GAME',false);

                    localStorage.setItem('atGame', false)
                    localStorage.setItem('trainingGameId', null)
                    localStorage.setItem('leagueGameId', null)

                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        clearGameData({commit}){
            commit("SET_GAMEPLAY", null)
            localStorage.setItem('atGame', false)
            localStorage.setItem('trainingGameId', null)
            localStorage.setItem('leagueGameId', null)
        },
        usePowerup({commit, state}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.usePowerup(payload).then((response) => {
                    if(payload.key == 'change') {
                        commit("SET_GAMEPLAY", response.data.result.set)
                        commit("SET_PLAYER_INFO", response.data.result.set.player)
                    }else{
                        if(state.player.powerups[payload.key] < 1) {
                            commit("REMOVE_COINS", state.powerupPrices[payload.key])
                        }
                        
                        commit("UPDATE_PLAYER_POWERUP", response.data.result.set)
                    }

                    localStorage.setItem('atGame', true)
                    if (state.gamePlay.difficulty == 0) {
                        localStorage.setItem('trainingGameId', state.gamePlay.id)
                    }

                    if (state.gamePlay.difficulty == 1) {
                        localStorage.setItem('leagueGameId', state.gamePlay.id)
                    }

                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        async retrieveProductGroups({commit}) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getProductsByGroup().then((response) => {
                    commit("SET_PRODUCT_GROUPS", response.data.result.set)
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getProductsOfPromotions({commit}) {
          return new Promise((resolve, reject) => {
            return new appApi.getProducts({params: {type:["r"]}}).then((response) => {
              commit("SET_PRODUCTS_OF_PROMOTIONS", response.data.result.set);
              resolve(response)
            }).catch(error => {
              reject(error);
            })
          })
        },
        purchaseStart({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.startPurchase(payload).then((response) => {
                  commit("SET_IS_LOADING", false)
                  resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        purchaseProduct({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.buyProduct(payload.id).then((response) => {
                    commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        setSessionToken({commit}, payload) {
            commit("SET_SESSION_TOKEN", payload)
        },
        getWord({state, commit, dispatch}, payload) {
            if (null == state.gamePlay) {
                localStorage.getItem("atGame");
                if (localStorage.getItem("atGame")) {
                    let gameType = payload.gameType == 1 ? 'leagueGameId' : 'trainingGameId'

                    if (localStorage.getItem(gameType) && localStorage.getItem(gameType) !== "null") {
                        return new Promise((resolve, reject) => {
                            return new appApi.retrieveGame(localStorage.getItem(gameType)).then((response) => {
                                if(response.data.code == "200.0302"){
                                    reject(response.data)
                                }

                                commit("SET_GAMEPLAY", response.data.result.set)
                                commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
                                resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
                            }).catch((err) => {
                                reject(err)
                            })
                        })
                    } else {
                        return new Promise((resolve, reject) => {
                            dispatch('createGame', payload).then(() => {
                                resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
                            }).catch((err) => {
                                reject(err)
                            })
                        })
                    }
                } else {
                    return new Promise((resolve, reject) => {
                        dispatch('createGame', payload).then(() => {
                            resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
                        }).catch((err) => {
                            reject(err)
                        })
                    })
                }
            }

            return Promise.resolve({word: state.gamePlay.wordToGuess, category: state.gamePlay.dictionary})
        },
        getTopTen({commit}) {
            commit("SET_IS_LOADING", true)

            var today = new Date();
            var day = String(today.getDate()).padStart(2, '0');
            var month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var year = today.getFullYear();

            let date = `${year}-${month}-${day}`

            return new Promise((resolve, reject) => {
                return new appApi.getTopTen(date).then((response) => {
                    commit("SET_LEADERBOARD", response.data.result.set)
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getLeaderboard({commit}, payload) {
          let period = moment().startOf("week").format("YYYYMM");
          let week = moment().isoWeek();

          return new Promise((resolve, reject) => {
              return new appApi.getLeaderboard({period, week, params: payload.params}).then((response) => {
                  commit("SET_LEADERBOARD", response.data.result.set)
                  commit("SET_LEADERBOARD_PAGINATION", response.data.result.pagination);
                  commit("SET_IS_LOADING", false)
                  resolve(response)
              }).catch(error => {
                  commit("SET_IS_LOADING", false)
                  reject(error)
              })
          })
        },
        refreshLeaderboard({dispatch}, payload) {
          return dispatch("emptyLeaderboard").then(() => {
            return dispatch("getTopTen").then(() => {
              if(payload.params.limit > 0) {
                return dispatch("getLeaderboard", payload)
              }
            });
          });
        },
        emptyLeaderboard({commit}) {
          commit("EMPTY_LEADERBOARD");
        },
        getGlobalStanding({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getGlobalStanding(payload.date).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        buyCoins({commit}, coins) {
            commit("ADD_COINS", coins)
        },
        buyTickets({commit, state}, product) {
            if (state.player.coins >= product.value) {
                commit("ADD_TICKETS", product.quantity)
                commit("REMOVE_COINS", product.value)
            }
        },
        spendTicket({commit}) {
            commit("REMOVE_TICKET")
        },
        getAvatars({commit}) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getAvatars().then((response) => {
                    commit("SET_IS_LOADING", false)
                    commit("SET_AVATARS", response.data.result.set)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getContent({commit}, contentId) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getContent(contentId).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getTransaction({commit}, transactionId) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.checkTransaction(transactionId).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getPlayerStandings({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getPlayerStanding(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        updateAdTimer({commit}){
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.changeAdTimer().then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getAdRewardsForLocation({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getAdRewardsForLocation(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    const rewards = response.data.result.set
                    commit("SET_AD_REWARDS", {location: payload, value: rewards})
                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getLeaderboardDetails({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getLeaderboardDetail(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result.set)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getPlayerNotifications({commit}) {
          return new Promise((resolve, reject) => {
            return new appApi.getNotifications().then((response) => {
              commit("SET_NOTIFICATIONS", response.data.result.set);
              resolve(response);
            }).catch(error => {
              reject(error);
            })
          })
        },
        readPlayerNotification({commit}, payload) {
          return new Promise((resolve, reject) => {
            return new appApi.patchNotification(payload).then((response) => {
              commit("READ_NOTIFICATION", payload);
              resolve(response);
            }).catch(error => {
              reject(error)
            })
          });
        },
        enablePlayerNotification({commit}) {
            return new Promise((resolve, reject) => {
              return new appApi.enableNotifications().then((response) => {
                commit("UPDATE_PLAYER_NOTIFICATION_STATUS", true);
                resolve(response);
              }).catch(error => {
                reject(error)
              })
            });
        },
        disablePlayerNotification({commit}) {
            return new Promise((resolve, reject) => {
              return new appApi.disableNotifications().then((response) => {
                commit("UPDATE_PLAYER_NOTIFICATION_STATUS", false);
                resolve(response);
              }).catch(error => {
                reject(error)
              })
            });
        },
        enableSoundEffects({commit}) {
            return new Promise((resolve, reject) => {
                return new appApi.enableEffects().then((response) => {
                    commit("UPDATE_PLAYER_SOUNDEFFECT_STATUS", true);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            });
        },
        disableSoundEffects({commit}) {
            return new Promise((resolve, reject) => {
                return new appApi.disableEffects().then((response) => {
                    commit("UPDATE_PLAYER_SOUNDEFFECT_STATUS", false);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            });
        },
        playerFileUpload({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.fileUpload(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        createNewTicket({commit}, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.newTicket(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        async rewardCheck({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.rewardCallback(payload).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setRewardType({commit}, payload) {
          commit("SET_REWARD_TYPE", payload);
        },
        // eslint-disable-next-line no-unused-vars
        async getStripeKey({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.stripeKey(payload).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // eslint-disable-next-line no-unused-vars
        stripeValidation({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.stripeValid(payload).then((response) => {
                  commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
                  resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        gpayComplete({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.gpayComplete(payload).then((response) => {
                  commit("UPDATE_PLAYER_INFO", response.data.result.set.player)
                  resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        async feedHourlyStat({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.feedHourlyStat(payload).then(() => {
                    resolve(true)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        checkOpenGame({commit}){
            return new Promise((resolve,reject) => {
                return new appApi.checkGame().then((response) => {
                    commit('SET_IS_GAME', response.data.result.set.value);
                    resolve(response);
                }).catch(error => {
                    commit('SET_IS_GAME', false);
                    reject(error);
                })
            })
        },
        updateOSUserId({commit}, payload) {
            commit('SET_OS_USER_ID', payload.userId)
        },
        retrieveAdPlacement({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.getAdPlacement(payload).then((response) => {
                    commit('SET_REWARD_TYPE', response.data.result.set.placementCode)
                    commit('SET_AD_REWARD', response.data.result.set.rewardInstructions)

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        sendRewardForAd({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.rewardForRewardedAd(payload).then((response) => {
                    let responseObj = response.data.result.set
                    let gameInfo = responseObj.gameplay
                    if (typeof gameInfo !== 'undefined' && gameInfo) {
                        commit('SET_GAMEPLAY', gameInfo)
                    }
                    commit('UPDATE_PLAYER_INFO', responseObj.log.player)

                    resolve(true)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        sendRewardForSocialMedia({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.rewardForSocialMedia(payload).then((response) => {
                    let responseObj = response.data.result.set
                    commit("ADD_COINS", parseInt(responseObj.rewardLog.instructions.coins.value))
                    resolve(true)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setInGame({commit}, status) {
            commit('SET_IN_GAME', status)
        },
        uploadTicketImage({commit}, payload) {
          commit("UPLOAD_TICKET_IMAGE");
          return new Promise((resolve, reject) => {
            return new appApi.uploadTicketImage(payload).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
          })
        },

        // eslint-disable-next-line no-unused-vars
        createConfirmationRequest({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.createConfirmation(payload).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            });
        },
        // eslint-disable-next-line no-unused-vars
        validateConfirmationRequest({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.validateConfirmation(payload).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            });
        },

        // eslint-disable-next-line no-unused-vars
        createPasswordReset({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new authApi.passwordReset(payload).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            });
        },

        // eslint-disable-next-line no-unused-vars
        confirmPasswordReset({commit}, payload) {
            return new Promise((resolve, reject) => {
                return new authApi.passwordResetConfirmation(payload).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            });
        },
        getRewardPowerups({ commit }) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getRewardPowerups().then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getRewardCoupons({ commit }) {
          commit("SET_IS_LOADING", true)
          return new Promise((resolve, reject) => {
              return new appApi.getRewardCoupons().then((response) => {
                  commit("SET_IS_LOADING", false)
                  resolve(response.data.result)
              }).catch(error => {
                  commit("SET_IS_LOADING", false)
                  reject(error)
              })
          })
        },
        getInternetRewards({ commit }) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getInternetRewards().then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response.data.result)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getPackageStatus({ commit }, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.getPackageStatus(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        getPaymentMethods() {
          return new Promise((resolve, reject) => {
              return new appApi.getPaymentMethods().then((response) => {
                  resolve(response.data.result.set)
              }).catch(error => {
                  reject(error)
              })
          })
        },
        removePaymentMethod({commit}, id) {
          return new Promise((resolve, reject) => {
            return new appApi.removePaymentMethod(id).then((response) => {
              commit("SET_IS_LOADING", false)
              resolve(response);
            }).catch(error => {
              commit("SET_IS_LOADING", false)
              reject(error)
            })
        })
        },
        createVerificationRequest({ commit }, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.createVerificationRequest(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response);
                }).catch(error => {
                    reject(error)
                    commit("SET_IS_LOADING", false)
                })
            });
        },
        validateVerificationRequest({ commit }, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.validateVerificationRequest(payload).then((response) => {
                    commit("SET_IS_LOADING", false)
                    resolve(response);
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            });
        },
        addPhoneNumber({ commit }, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.addPhoneNumber(payload).then((response) => {
                    commit('SET_IS_LOADING', false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        updatePhoneNumber({ commit }, payload) {
            return new Promise((resolve, reject) => {
                return new appApi.updatePhoneNumber(payload).then((response) => {
                    commit('SET_IS_LOADING', false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
        deletePhoneNumber({ commit }, payload) {
            commit("SET_IS_LOADING", true)
            return new Promise((resolve, reject) => {
                return new appApi.deletePhoneNumber(payload).then((response) => {
                    commit("UPDATE_PLAYER_INFO", response.data.result.set)
                    commit("SET_IS_LOADING", false)
                    resolve(response)
                }).catch(error => {
                    commit("SET_IS_LOADING", false)
                    reject(error)
                })
            })
        },
    }
})
