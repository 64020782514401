<template>
  <div>
    <div class="flex justify-end relative" v-if="!this.$store.state.isLoading">
      <button @click="this.hide" class="modal-close absolute right-0"></button>
    </div>
    <div class="flex flex-col items-center">
      <iframe title="Payment Iframe" v-if="this.url" @load="onloadedIframe" id="urlIframe" style="height: 100%; width: 100%; min-height: 500px" :src="'https://pay.fortumo.com/mobile_payments/'+this.url"></iframe>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    url: {
      type: String,
      default: null
    }
  },
  created() {
    this.$store.state.isLoading = true;
  },
  methods: {
    hide(){
      this.$modal.hide('payment-method-modal')
    },
    onloadedIframe() {
      this.$store.state.isLoading = false;
    }
  }
}
</script>