<template>
  <div class="gpay-container flex flex-col items-center">
    <loading :active.sync="isLoading" :is-full-page="true" :backgroundColor="`#000000`" :color="`#FFB74F`" :blur="`7px`"
      :opacity="0.5" :width="64" :height="64">
    </loading>
    <h2 class="text-danger text-center mt-4" v-html="this.message" v-if="!this.isLoading && this.message"></h2>
    <google-pay-button
      v-if="isGooglePayAvailable"
      :environment="gpayEnvironment" 
      button-color="black"
      button-type="buy"
      button-radius="5"
      button-locale="tr"
      button-size-mode="fill"
      style="width: 280px; height: 50px;"
      v-bind:paymentRequest.prop="{
        apiVersion: paymentDataRequest.apiVersion,
        apiVersionMinor: paymentDataRequest.apiVersionMinor,
        allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods,
        merchantInfo: paymentDataRequest.merchantInfo,
        transactionInfo: paymentDataRequest.transactionInfo,
      }"
      v-on:loadpaymentdata="onLoadPaymentData"
      v-on:error="onError"
    ></google-pay-button>
  </div>
</template>

<script>
import '@google-pay/button-element'
import Feed from "@/mixins/Feed"
import { mapActions } from 'vuex';

export default {
  name: 'GPayComponent',

  mixins: [Feed],

  props: {
      product: { type: Object, required: true },
      transactionId: { type: String, required: false },
      // onCallback: { type: Function, required: true }
  },
  
  data() {
    return {
      isLoading: false,
      message: null,
      paymentClient: null,
      isGooglePayAvailable: false
    };
  },

  computed: {
    gpayEnvironment() {
      return process.env.NODE_ENV === 'development' ? 'TEST' : 'PRODUCTION';
    },
    paymentDataRequest() {
      return {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [{
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA']
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'stripe',
              'stripe:version': '2020-08-27',
              'stripe:publishableKey': process.env.VUE_APP_STRIPE_KEY
            }
          }
        }],
        merchantInfo: {
          merchantId: process.env.VUE_APP_GPAY_MERCHANT_ID,
          merchantName: process.env.VUE_APP_GPAY_MERCHANT_NAME
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Toplam',
          totalPrice: this.$props.product.price.toString(),
          currencyCode: this.$props.product.currency.toString(),
          countryCode: 'TR',
        }
      };
    }
  },

  methods: {
    ...mapActions(['gpayComplete']),
    async initGooglePay() {
      try {
        
        this.paymentClient = new window.google.payments.api.PaymentsClient({
            environment: process.env.NODE_ENV == 'development' ? 'TEST' : 'PRODUCTION'
        });

        const result = await this.paymentClient.isReadyToPay(this.paymentDataRequest);
        this.isGooglePayAvailable = result.result;
      } catch (error) {
        console.error('Google Pay initialization error:', error);
      }
    },
    onLoadPaymentData(event) {
      console.log('load payment data', event.detail);
      console.log('available props ', JSON.stringify(this.$props))
      const paymentData = {
        transactionId: this.$props.transactionId,
        data: event.detail
      }

      console.log('PaymentData::', JSON.stringify(paymentData))

      var returnObj = {
        "success": false,
        "message": null,
        "product": this.$props.product
      }

      this.isLoading = true;
      this.gpayComplete(paymentData).then(() => {
        returnObj.success = true
        this.$emit('onCallback', returnObj)
        this.feedAllEvent('sales_generated', this.product.price, 'ecommerce', this.product.currency, this.product.currency)
        this.feedAllEvent('purchase_product', this.product.price, 'ecommerce', this.product.sku, this.product.sku)
        this.isLoading = false;
      }).catch(error => {
        returnObj.message = error.message
        this.$emit('onCallback', returnObj)
        this.isLoading = false;
      })
    },
    onError(event) {
      console.error('error', event.error);
    },
  },

  mounted() {
    this.initGooglePay();
  },
};
</script>

<style scoped>
.gpay-container {
  width: 'fit-content';
  margin: 5 auto;
}
</style>