import Vue from 'vue';
import Axios from 'axios';
import store from '../store'

Axios.defaults.baseURL = process.env.VUE_APP_API_URL

const request = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "content-type": "application/json"
    }
});

request.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (null != store.state.sessionToken) {
        config.headers['X-Session-Token'] = store.state.sessionToken
    } else if ("null" != localStorage.getItem('sessionToken') && null !== localStorage.getItem('sessionToken')) {
        config.headers['X-Session-Token'] = localStorage.getItem('sessionToken');
        store.commit('SET_AUTHENTICATED',true);
    }else {
        delete config.headers['X-Session-Token']
        store.commit('SET_AUTHENTICATED',false);
    }
    config.headers['X-Api-Key'] = process.env.VUE_APP_API_KEY;

    if(null != store.state.osUserId) {
        config.headers['X-OneSignal-Id'] = store.state.osUserId
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

request.interceptors.response.use(
    function (response) {
        if (response.data.sessionToken !== null) {
            store.dispatch("setSessionToken", response.data.sessionToken);
            localStorage.setItem('sessionToken',response.data.sessionToken)
        }
        return response;
    },
    function (error) {
      
        //handle error
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    if(error.response.data.code == '401.0000' || error.response.data.code == '401.9000') {
                        localStorage.removeItem('sessionToken');
                        localStorage.removeItem('playerId');
                        setTimeout("window.location = '/'", 3000)
                    } else {
                        return Promise.reject(error);
                    }
                    break;
                case 500:
                  store._vm.$modal.show("error-modal", {content: error.response.data && error.response.data.message ? error.response.data.message : "Bilinmeyen bir hata oluştu. Hata tekrar ederse destek ekibimizle iletişime geçebilirsiniz."});
                  break;
                default:
                    return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    }
);

Vue.$http = request

Object.defineProperty(Vue.prototype, '$http', {
    get() {
        return Axios
    },
})

export default request;