import request from './request'

const app = {
    getPlayer(playerId) {
        return request.get(`/player/${playerId}`)
    },

    patchPlayerAlias(payload) {
        return request.patch(`/player/${payload.id}/alias/${payload.alias}`)
    },
    
    patchPlayerLastPan(payload) {
        return request.patch(`/player/${payload.id}/lastPan`)
    },

    patchPlayerAvatar(payload) {
        return request.patch(`/player/${payload.id}/avatar/${payload.avatar.fileName}`)
    },

    retrieveGame(gameId) {
        return request.get(`/gameplay/${gameId}`)
    },

    getFreeGame() {
        return request.get(`/gameplay/freeGame`);
    },

    createGame(type) {
        return request.get(`/gameplay/load/${type}?${new Date().getTime()}`)
    },

    updateGame(payload) {
        return request.put(`/gameplay/${payload.id}`, payload.data)
    },

    finalizeGame(payload) {
        return request.post(`/gameplay/finalize`, payload)
    },

    usePowerup(payload) {
        return request.delete(`/player/${payload.id}/powerup/${payload.key}`, {params: payload.params})
    },

    getProductsByGroup() {
        return request.get(`/products/by-group`)
    },

    getProducts(payload) {
      return request.get('/products', {params: payload.params})
    },

    getGlobalStanding(date) {
        return request.get(`/standing/of/${date}`)
    },

    getLeaderboardDetail(payload) {
        return request.get(`/standing/of/${payload.player}/${payload.date}`)
    },

    getPlayerStanding(payload) {
        return request.get(`/standing/of/${payload.date}`)
    },

    getTopTen(date) {
        return request.get(`/leaderboard/top-ten/${date}`)
    },

    getLeaderboard(payload) {
      return request.get(`/leaderboard/all/${payload.period}/${payload.week}`, { params: payload.params })
    },

    buyProduct(productId) {
        return request.post(`purchase/product/${productId}`)
    },

    startPurchase(payload) {
        return request.post(`/purchase/start`, payload)
    },

    getAvatars() {
        return request.get('/avatars')
    },
    getRewardPowerups() {
        return request.get("/player/reward-history/power");
    },

    getRewardCoupons() {
        return request.get("/player/reward-history/gift");
    },

    getInternetRewards() {
        return request.get("/player/reward-history/internet");
    },

    getPackageStatus(payload) {
        return request.get(`player/package-status/${payload.id}`);
    },

    getContent(contentId) {
        return request.get(`/static-content/${contentId}`)
    },

    checkTransaction(transactionId) {
        return request.get(`/transaction/${transactionId}`)
    },
    rewardCallback(payload) {
        return request.post(`/reward/check`, payload)
    },
    rewardForSocialMedia(payload) {
        return request.post(`/reward/for/social-action/${payload.action}/${payload.network}/${payload.refId}`)
    },
    fileUpload(payload) {
        return request.post(`/player/${payload.id}/avatar`, payload.data);
    },
    newTicket(payload) {
        return request.post(`/ticket/newTicket`, payload);
    },
    stripeKey(payload) {
        return request.get(`purchase/stripe/${payload}`);
    },
    stripeValid(payload) {
        return request.post(`purchase/stripe/${payload.transactionId}`, payload.data);
    },
    gpayComplete(payload) {
        return request.post(`purchase/gpay/${payload.transactionId}`, payload.data);
    },
    feedHourlyStat(payload) {
        return request.post(`feed/hourly-stat`, payload);
    },
    checkGame() {
        return request.get(`gameplay/checkOpenGame?${new Date().getTime()}`);
    },
    getAdPlacement(payload) {
        return request.get(`ad-placement/${payload.code}`);
    },
    rewardForRewardedAd(payload) {
        return request.post(`reward/for/rewarded-ad/${payload.adPlacementCode}/${payload.networkCode}`, payload.data);
    },
    changeAdTimer(){
        return request.put(`reward/ad/update`);
    },
    uploadTicketImage(payload) {
      return request.post('ticket/upload', payload, {headers: { "Content-Type": "multipart/form-data" }},
      );
    },
    createConfirmation(payload) {
        return request.get(`/player/${payload.id}/confirm/${payload.type}`)
    },
    validateConfirmation(payload) {
        return request.post(`/player/${payload.id}/confirm/${payload.type}`, payload.data)
    },
    getNotifications() {
      return request.get(`/player/notifications`);
    },
    patchNotification(payload) {
      return request.patch(`/player/notification/${payload.id}/read`)
    },
    enableNotifications() {
        return request.patch(`/player/notification/enabled`)
    },
    disableNotifications() {
        return request.patch(`/player/notification/disabled`)
    },
    getAdRewardsForLocation(location) {
        return request.get(`/ad-rewards/by-location/${location}`)
    },
    enableEffects() {
        return request.patch(`/player/soundeffects/enabled`)
    },
    disableEffects() {
        return request.patch(`/player/soundeffects/disabled`)
    },
    getPaymentMethods() {
      return request.get(`/paymentMethods`);
    },
    removePaymentMethod(id) {
      return request.delete(`/paymentMethod/${id}`)
    },
    createVerificationRequest(payload) {
        return request.post(`/player/${payload.id}/send/verificationCode`, payload.data)
    },
    validateVerificationRequest(payload) {
        return request.patch(`/player/${payload.id}/verify/phone`, payload.data)
    },
    addPhoneNumber(payload) {
        return request.post(`/player/${payload.id}/add/phoneNumber`, payload.data)
    },
    updatePhoneNumber(payload) {
        return request.patch(`/player/${payload.id}/update/phoneNumber`, payload.data)
    },
    deletePhoneNumber(payload) {
        return request.delete(`/player/${payload.id}/delete/phoneNumber`)
    },
    getPlayerInternet(payload) {
        return request.get(`/player/${payload.player}/internet/${payload.transactionId}`)
    },
    sendPlayerInternet(payload) {
        return request.patch(`/player/${payload.player}/internet/${payload.internetGB}`)
    }
}
export default app