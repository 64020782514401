<template>
  <div>
    <modal name="phone-addition-two-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480"
      :scrollable="true" :reset="true" :height="'auto'" :adaptive="true" width="90%" @closed="onCloseModal"
      @before-open="onBeforeOpen">
      <div class="flex justify-end relative">
        <button @click="$modal.hide('phone-addition-two-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <h2 class="text-center my-4" style="font-size: 24px">{{ modalTitle }}</h2>
      <div class="mx-auto flex flex-col items-center px-4">
        <p class="mb-4 text-center text-danger" v-html="this.errorMsg"></p>
        <form class="flex flex-col items-center w-full" @submit.prevent="onClickSubmit">
          <validation-observer class="flex flex-col" style="width:80%" ref="form">
            <validation-provider class="mb-4" tag="div" name="Cep Telefonu"
              rules="required|validPhone|validatePhoneNumberLength" v-slot="{ errors }">
              <vue-tel-input type="tel" v-model="loginData.username" mode="international" :invalidMsg="errors[0]"
                :preferredCountries="['TR']" :validCharactersOnly="true" :disabled="loading" :autoFormat="true"
                :dropdownOptions="{
                  showDialCodeInList: true,
                  showFlags: true,
                  showSearchBox: true,
                }" :inputOptions="{
                  type: 'tel',
                  required: true,
                  name: 'username',
                  maxlength: 17,
                  placeholder: 'Cep Telefonu',
                }">
              </vue-tel-input>
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <div class="mb-5 center-content">
              <RecaptchaComponent :sitekey="siteKey" :callback="onCaptchaSuccess" />
            </div>
            <button type="submit" class="btn btn--primary block mb-5 mx-auto" @click.prevent="onClickSubmit">
              {{ buttonText }}
            </button>
          </validation-observer>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { isValidPhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import RecaptchaComponent from './RecaptchaComponent.vue';
import axios from 'axios';

extend('validPhone', {
  validate: (value) => isValidPhoneNumber(value, 'TR'),
  message: 'Lütfen geçerli bir telefon numarası giriniz.',
});
extend('validatePhoneNumberLength', {
  validate: (value) => {
    const phoneNumber = parsePhoneNumberFromString(value, 'TR');
    return phoneNumber && phoneNumber.nationalNumber.length === 10;
  },
  message: 'Telefon numarası 10 haneli olmalıdır.',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    RecaptchaComponent,
  },
  name: "phone-addition-two-modal",
  data() {
    return {
      fnType: '',
      type: 'verify',
      loginData: {
        username: null,
      },
      errorMsg: "",
      loading: false,
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      secretKey: process.env.VUE_APP_RECAPTCHA_SECRET_KEY,
      isCaptchaValidated: false
    }
  },
  computed: {
    ...mapState(["player"]),
    modalTitle() {
      if (this.fnType === 'add') {
        return 'Cep Telefonu Ekle';
      } else if (this.fnType === 'verify') {
        return 'Cep Telefonu Doğrula';
      } else {
        return 'Cep Telefonu Güncelle';
      }
    },
    buttonText() {
      if (this.fnType === 'add') {
        return 'Ekle';
      } else if (this.fnType === 'verify') {
        return 'Doğrula';
      } else {
        return 'Güncelle';
      }
    },
  },
  methods: {
    ...mapActions([
      'addPhoneNumber',
      'updatePhoneNumber'
    ]),
    async onCaptchaSuccess(token) {
      try {
        const response = await axios.post('https://www.google.com/recaptcha/api/siteverify', null, {
          params: {
            secret: this.secretKey,
            response: token,
          }
        });

        if (response.data.success) {
          this.isCaptchaValidated = true;
        } else {
          console.error('Token verification failed:', response);
        }
      } catch (error) {
        console.error('Error verifying reCAPTCHA token:', error);
      }
      this.isCaptchaValidated = true;
    },
    onClickSubmit() {
      this.errorMsg = "";
      this.$refs.form.validate().then((isValid) => {
        if (!isValid) {
          return;
        } else if (!this.isCaptchaValidated) {
          this.$toast.error('Lütfen reCAPTCHA doğrulamasını tamamlayın.');
          return;
        }
        this.loading = true;
        if (this.fnType === 'add') {
          this.addPhoneNumber({ id: this.player.id, data: this.loginData }).then((response) => {
            if (response.data.code === "200.0401") {
              this.errorMsg = response.data.message;
              this.loading = false;
              return;
            }
            this.loading = false;
            this.openVerificationModal()
            this.$toast.success('Telefon numaranız başarıyla eklendi');
          }).catch(error => {
            this.errorMsg = error.response.data.message
            this.$toast.error(error.response.data.message);
            this.loading = false;
          });
        } else {
          this.updatePhoneNumber({ id: this.player.id, data: this.loginData }).then((response) => {
            if (response.data.code === "200.0401") {
              this.errorMsg = response.data.message;
              this.loading = false;
              return;
            }
            this.loading = false;
            this.openVerificationModal()
            this.$toast.success('Telefon numaranız başarıyla güncellendi');
          }).catch(error => {
            this.errorMsg = error.response.data.message
            this.$toast.error(error.response.data.message);
            this.loading = false;
          });
        }
      })
    },
    openVerificationModal() {
      this.$modal.hide("phone-addition-two-modal");
      this.$modal.show("phone-verification-two-modal");
    },
    changeScreen(screen) {
      this.currentScreen = screen
    },
    onCloseModal() {
      this.loginData = {
        username: null,
      };
      this.isCaptchaValidated = false;
    },
    onBeforeOpen(event) {
      const payload = event.params;
      this.fnType = payload
    }
  }
}
</script>
<style scoped>
.active {
  font-weight: bold;
}

button.active img {
  border: 2px solid #ffb74f;
  border-radius: 50%;
}

.vue-tel-input {
  border-radius: 8px;
  display: flex;
  border: 4px solid black;
  text-align: left;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
