var facebookAuthConf = (function () {

    function installClient() {
        var apiUrl = 'https://connect.facebook.net/en_US/sdk.js'
        return new Promise((resolve) => {
            var script = document.createElement('script')
            script.src = apiUrl
            script.onreadystatechange = script.onload = function () {
                if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                    setTimeout(function () {
                        resolve(true)
                    }, 500)
                }
            }
            document.getElementsByTagName('head')[0].appendChild(script)
        })
    }

    function initFacebook(config) {
        return new Promise((resolve) => {
            window.FB.init({
                appId: config.appId, //You will need to change this
                cookie: config.cookie, // This is important, it's not enabled by default
                version: config.version
            });
            resolve(window.FB);
        })
    }


    function AuthFb() {
        if (!(this instanceof AuthFb))
            return new AuthFb()

        this.logInWithFacebook = async () => {
            return new Promise((resolve) => {
                window.FB.login(function (response) {
                    resolve(response);
                }, {scope: 'gaming_profile,gaming_user_picture,email'});
            });
        }
        this.load = (config) => {
            installClient()
                .then(() => {
                    return initFacebook(config)
                })
                .then()
        }
    }

    return new AuthFb()
})

function facebookAuth(Vue, options) {
//Install Vue plugin
    Vue.fAuth = facebookAuthConf()
    Object.defineProperties(Vue.prototype, {
        $fAuth: {
            get: function () {
                return Vue.fAuth
            }
        }
    })
    Vue.fAuth.load(options, prompt)
}

export default facebookAuth