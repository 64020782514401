<template>
  <div id="app">
    <div class="layout-main vld-parent">
      <the-header></the-header>
      <div class="main mx-auto">
        <div class="flex container px-8 mt-8 flex-col items-center justify-center">
          <h1 class="mt-2 mb-2">REHBER</h1>
          <h2 class="mb-6">Nasıl Oynanır?</h2>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="icon-circle text-center mr-4 w-4"><img src="@/assets/icon-live.svg" alt=""></div>
              <p><b>Can:</b> Kelimeyi doğru tahmin etmek için kalan hamle sayısı</p>
            </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="icon-circle text-center mr-4"><img src="@/assets/icon-coin.svg" alt=""></div>
            <p><b>Koin:</b> Oyun içi mağazada kullanılabilecek para birimi.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="icon-circle text-center mr-4"><img src="@/assets/icon-ticket.svg" alt=""></div>
            <p><b>Bilet:</b> Kullanıcının oyuna giriş bileti.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="btn btn--xs btn--primary self-center mr-5 ml-1">
              <img src="@/assets/icon-relive.svg" alt="">
            </div>
            <p><b>Geri Sarma:</b> En son seçilen yanlış harfi geri alır.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="btn btn--xs btn--primary self-center mr-5 ml-1">
              <img src="@/assets/block-button.svg" alt="">
            </div>
            <p><b>Harf Aç:</b> Kelime içerisinden bir harf açar.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="btn btn--xs btn--primary self-center mr-5 ml-1">
              <img src="@/assets/skip-button.svg" alt="">
            </div>
            <p><b>Pas:</b> Kelimeyi pas geçmenizi sağlar.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="btn btn--xs btn--primary self-center mr-5 ml-1" style="min-width: 48px">
              <img src="@/assets/clue-button.svg" alt="">
            </div>
            <p><b>İpucu:</b> Kelime ile ilgili ipuçları gösterir. (Eğer kelimeye ait ipucu var ise aktif olarak gözükür.)</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="icon-circle text-center mr-4"><img src="@/assets/icon-score.svg" alt=""></div>
            <p><b>Skor:</b> Oyun içinde harf açtıkça toplanan puan.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <button class="btn btn--primary btn--sm button-login mb-5 mr-5" style="min-width: 220px">
              <div class="bText">
                <div class="big">GİRİŞ YAP</div>
              </div>
              <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 200 *</div>
            </button>
            <p>Oyuna email ile giriş yapılırsa 200 koin kazanılır. Koin kazanımları tek seferliktir.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <button href="#" class="btn button-google mr-5" style="min-width: 220px"><img src="@/assets/google.svg" alt="Login with google" class="img-svg"><div class="gText"><div class="big">GOOGLE</div> ile GİRİŞ YAP </div><div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div></button>
            <p>Oyuna Google hesap ile giriş yapılırsa 500 koin kazanılır. Koin kazanımları tek seferliktir.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <button class="btn button-facebook mr-5" style="min-width: 220px"><img src="@/assets/facebook.svg" alt="Login with facebook" class="img-svg"><div class="fbText"><div class="big">FACEBOOK</div> ile GİRİŞ YAP </div><div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div></button>
            <p>Oyuna Facebook hesap ile giriş yapılırsa 500 koin kazanılır. Koin kazanımları tek seferliktir.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <button class="btn btn--primary btn--sm button-login mb-5 mr-5" style="min-width: 220px">
              <div class="bText">
                ARKADAŞ DAVET ET
              </div>
              <div class="social-login-reward">+ <img src="@/assets/coin-small.svg">50 *</div>
            </button>
            <p>Oyuna bir arkadaş davet edilirse 50 koin kazanılır. Davet edilen ise 100 koin kazanır. Koinler davet edilen kişi mail ile üye olursa veya sosyal hesaplardan birini bağlarsa kazanılır.</p>
          </div>
          <div class="flex items-center justify-start w-full mb-4">
            <div class="btn-circle mr-4">
              <img src="@/assets/fullscreen.svg">
            </div>
            <p>Oyunu tam ekran modunda açar.</p>
          </div>
        </div>
      </div>
      <network-status-modal ref="networkStatusModal"></network-status-modal>
    </div>
  </div>
</template>
<script>

import NetworkStatusModal from "@/views/components/NetworkStatusModal";
import TheHeader from "@/guide/components/TheHeader";
import {mapActions} from "vuex";
import UID from "uniquebrowserid";
import Feed from "@/mixins/Feed"

export default {
  components: {NetworkStatusModal, TheHeader},
  mixins: [Feed],
  data(){
    return {
      networkStatus : navigator.onLine
    }
  },
  created() {
    let queries = this.$route.query;
    if(queries.gclid){
      this.$cookies.set('origin-campaign-id', queries.gclid);
    }
    if(queries.fbclid){
      this.$cookies.set('origin-campaign-id', queries.fbclid);
    }
    if(queries.ref_id){
      this.$cookies.set('fb-share-return-id', queries.ref_id);
    }

    if(this.$store.state.authenticated)
    {
      this.$gtag.config({userId: localStorage.getItem('playerId'), user_id: localStorage.getItem('playerId')});
      this.$cookies.set('aa_userId', localStorage.getItem('playerId'));
    }else{
      if(!localStorage.getItem('aa_deviceId')){
        let deviceIdTmp = new UID().completeID();
        localStorage.setItem('aa_deviceId',deviceIdTmp);
      }
      
      var deviceId = localStorage.getItem('aa_deviceId');

      let cookie = this.$cookies.get("origin-campaign-id")
      let payload = {deviceId: deviceId}
      if(cookie){ payload.originCampaignId = cookie }
      this.guestLogin(payload)
    }

    window.ononline = (event) => {
      console.log(event,"You are now connected to the network.");
    };

    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);

    if (typeof (window.FBInstant) != 'undefined') {
      window.FBInstant.setLoadingProgress(100);
      window.FBInstant.startGameAsync()
          .then(function () {
          })
    }
  },
  methods: {
    ...mapActions(["guestLogin", "getPlayerNotifications"]),
    updateConnectionStatus() {
     this.networkStatus = navigator.onLine
    }
  },
  watch:{
    networkStatus: function (val) {
      if(val === false) {
        this.$refs.networkStatusModal.show();
      }else if(val === true){
        this.$refs.networkStatusModal.hide()
      }
    }
  }
}
</script>
