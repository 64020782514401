<template>
  <div>
    <modal 
      name="help-modal" 
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      width="100%"
      :clickToClose="false"
      height="auto">
      
      <div class="flex justify-end relative">
        <button @click="$modal.hide('help-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <help-content :mode="'modal'" ref="helpContent"></help-content>
    </modal>
  </div>
</template>

<script>
import HelpContent from "@/views/components/HelpContent"
export default {
  components: {
    HelpContent
  },
  methods: {
    show() {
      this.$modal.show("help-modal");
    },
    hide() {
      this.$modal.hide("help-modal");
    }
  }
}
</script>