<template>
    <div></div>
</template>

<script>
/**
 * base: https://github.com/finpo/vue2-recaptcha-invisible
 * global window, document
 */
if (typeof window !== 'undefined') {
    window.recaptchaLoaded = new Promise((resolve) => {
        window.vueRecaptchaInit = resolve;
    });

    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaInit&render=explicit';
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    document.body.appendChild(recaptchaScript);
}

export default {
    props: {
        sitekey: { type: String, required: true },
        callback: { type: Function, required: true },
        validate: { type: Function, default: () => true },
        badge: { type: String, default: '' },
        type: { type: String, default: 'image' },
        errorCallback: { type: Function, default: () => { } },
        tabindex: { type: String, default: '0' },
        size: { type: String, default: 'checkbox' }
    },
    data() {
        return {
            recaptchaId: null,
        };
    },
    created() {
        if (typeof window === 'undefined') return;

        window.recaptchaLoaded.then(() => {
            this.initRecaptcha();
        });
    },
    methods: {
        initRecaptcha() {
            try {
                const options = {
                    sitekey: this.sitekey,
                    badge: this.badge,
                    type: this.type,
                    tabindex: this.tabindex,
                    size: this.size,
                    callback: this.getToken
                };

                const recaptchaDiv = document.createElement('div');
                recaptchaDiv.className = 'g-recaptcha';
                this.$el.insertBefore(recaptchaDiv, this.$el.firstChild);

                this.recaptchaId = window.grecaptcha.render(recaptchaDiv, options);
            } catch (e) {
                console.error('Recaptcha initialization failed:', e);
                this.errorCallback(e);
            }
        },
        submitData(event) {
            event.preventDefault();
            if (typeof window === 'undefined') return;

            if (this.validate()) {
                if (this.size === 'invisible') {
                    window.grecaptcha.execute(this.recaptchaId);
                } else {
                    this.getToken(window.grecaptcha.getResponse(this.recaptchaId));
                }
            }
        },
        getToken(token) {
            this.callback(token);
        }
    }
};
</script>